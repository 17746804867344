import { IState } from "../..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../../app/store";
import { ActivePicklistsState } from "./picklistsState";
import PicklistsService from "../../../app/data/picklists/PicklistsService";
import { resetPicklistDetailsState } from "./picklistDetailsSlice";

const initialState: ActivePicklistsState = {
  loading: false,
  loadingFailed: false,
  loaded: false,
  activePicklists: [],
  selectedPicklistNumber: 0
};

const picklistService = PicklistsService.getInstance();

export const activePicklistsSlice = createSlice({
  name: "activePicklists",
  initialState,
  reducers: {
    resetState: (state) => initialState,
    loadingStarted: (state) => {
      state.loading = true;
      state.loadingFailed = false;
      state.loaded = false;
      state.activePicklists = [];
      state.selectedPicklistNumber = 0;
    },
    loadingSucceed: (state, { payload }) => {
      state.loaded = true;
      state.loading = false;
      state.loadingFailed = false;
      state.activePicklists = payload;
    },
    loadingFailed: (state) => {
      state.loading = false;
      state.loadingFailed = true;
      state.activePicklists = [];
    },
    setSelectedPicklistNumber: (state, { payload }) => {
      state.loaded = true;
      state.loading = false;
      state.loadingFailed = false;
      state.selectedPicklistNumber = payload;
    },
  },
});

export const {
  resetState,
  loadingStarted,
  loadingSucceed,
  loadingFailed,
  setSelectedPicklistNumber
} = activePicklistsSlice.actions;

export const activePicklistsSelector = (state: IState) => state.activePicklists;

export const getActivePicklists = (
  routeRequest: any
): AppThunk => async (dispatch) => {
  dispatch(loadingStarted());
  const response = await picklistService.getActivePicklists({ ...routeRequest });
  dispatch(resetPicklistDetailsState());
  if (response.ok() && response.data && response.data.result) {
    dispatch(loadingSucceed(response.data.results));
  } else {
    dispatch(loadingFailed());
  }
};

const activePicklistsReducer = activePicklistsSlice.reducer;
export default activePicklistsReducer;
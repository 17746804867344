import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Formik, FormikProps, Field } from "formik";
import moment from "moment";
import Modal from "react-modal";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";
import XGSRegularIcons from "../../../../ui-components/icon/xgsRegularIcons";
import XGSIcon from "../../../../ui-components/icon/xgsIcon";
import XGSFormInput from "../../../../ui-components/form/input/xgsFormInput";
import XGSFormPhoneInput from "../../../../ui-components/form/phoneInput/xgsFormPhoneInput";
import XGSFormSelect from "../../../../ui-components/form/select/xgsFormSelect";
import XGSFormTextarea from "../../../../ui-components/form/textarea/xgsFormTextarea";
import XGSFormDate from "../../../../ui-components/form/date/xgsFormDate";
import { weekDays } from "../../../../ui-components/xgs-date/xgs-date/xgsDate";
import AddressLookup from "../../../../ui-components/address-lookup/addressLookup";
import { XGSSelectOption } from "../../../../ui-components/xgs-select/xgsSelect";
import { LabelModes } from "../../../../ui-components/molecules/labeled-inputs/labeledInput";
import LabeledSelectInput from "../../../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import Button, { ButtonThemes } from "../../../../ui-components/button/button";
import ConfirmationModal from "../../../../ui-components/confirmation-modal/confirmationModal";
import {
  ReturnShipperModel,
  ReturnShipperSchema
} from "../../../../app/data/return/models";
import { StepProps } from "../../../../app/data/common/models";
import ReturnState from "../../../../slices/return/ReturnState";
import {
  checkShipperZip,
  clearItems,
  requestManualReturn,
  resetErrors,
  resetReturnState,
  returnSelector,
  setFreightLabeled,
  setReturnShipper
} from "../../../../slices/return/returnSlice";
import CommonState from "../../../../slices/common/CommonState";
import {
  commonSelector,
  getFreightClasses,
  getPickupDays
} from "../../../../slices/common/commonSlice";
import { nextBusinessDay } from "../../../../hooks/utils";
import { usaStates } from "../../../../app/data/common/usaStates";
import { modalStyle } from "../../../../app/data/common/modalStyle";
import { fromTimeOptions, toTimeOptions } from "../../../../services/common/time";
import { RETURN_SERVICES } from "../../constants";
import "../../bol.scss";

const ReturnShipperStep: React.FC<StepProps> = (props) => {
  const { previous, next, push } = props;
  const dispatch = useDispatch();
  const returnState: ReturnState = useSelector(returnSelector);
  const commonState: CommonState = useSelector(commonSelector);
  const shipperFormRef = useRef<any>(null);
  const [addressLookupValue, setAddressLookupValue] = useState<any>("");
  const [availabilityFromValue, setAvailabilityFromValue] = useState<XGSSelectOption | null>(null);
  const [availabilityToValue, setAvailabilityToValue] = useState<XGSSelectOption | null>(null);
  const [labeledConfirmed, setLabeledConfirmed] = useState<boolean>(false);
  const [phoneFieldValue, setPhoneFieldValue] = useState<string>("");
  const [pickupDateFormValue, setPickupDateFormValue] = useState<string | undefined>();
  const [postalCodeValue, setPostalCodeValue] = useState<string>("");
  const [postalCodeServiceable, setPostalCodeServiceable] = useState<boolean>(false);
  const [showZipCheckError, setShowZipCheckError] = useState<boolean>(false);
  const [showManualRequest, setShowManualRequest] = useState<boolean>(false);
  const [showManualRequestResult, setShowManualRequestResult] = useState<boolean>(false);
  const [showServicesHelp, setShowServicesHelp] = useState<boolean>(false);
  const [stateValue, setStateValue] = useState<XGSSelectOption | null>();

  let initialValues: ReturnShipperModel = {
    name: "",
    address: {
      address1: "",
      city: "",
      postalCode: "",
      state: ""
    },
    phone: "",
    email: "",
    shipperAvailabilityHours: {
      from: "",
      to: ""
    },
    specialInstructions: "",
    specialServices: [],
    pickupDate: ""
  };

  let checkZip = (zip?: string) => {
    setPostalCodeServiceable(false);
    setShowZipCheckError(false);
    let postalCode = zip || shipperFormRef.current?.values.address.postalCode;
    const field = document.getElementById("xgs-bol__return__shipper-zip--js") as HTMLInputElement;
    if (!/^\d{5}$/.test(postalCode)) return;
    dispatch(checkShipperZip(postalCode, () => {
      setPostalCodeServiceable(true);
      setShowZipCheckError(false);
    }, () => {
      field.focus();
      setPostalCodeServiceable(false);
      setShowZipCheckError(true);
      setShowManualRequest(true)
    }, (error: string) => {
      setPostalCodeServiceable(false);
      setShowZipCheckError(true);
      toast.error(error || "Error", { autoClose: 5000 });
    }));
  };
  checkZip = debounce(checkZip, 1000);

  const startManualRequest = () => {
    let request = {
      referenceNumber: returnState.returnCommon.referenceNumber,
      fileId: returnState.referenceDocumentId,
      shipper: {
        name: shipperFormRef.current?.values.name,
        address: {
          address1: shipperFormRef.current?.values.address.address1,
          city: shipperFormRef.current?.values.address.city,
          postalCode: shipperFormRef.current?.values.address.postalCode,
          state: shipperFormRef.current?.values.address.state
        },
        phone: shipperFormRef.current?.values.phone
      }
    };
    dispatch(requestManualReturn(request, () => {
      setShowManualRequest(false);
      setShowManualRequestResult(true);
    }));
  };

  const onClickBack = (data: ReturnShipperModel) => {
    dispatch(setReturnShipper(data));
    dispatch(setFreightLabeled(labeledConfirmed));
    previous && previous();
  };

  const onClickNext = (data: ReturnShipperModel) => {
    dispatch(setReturnShipper(data));
    dispatch(setFreightLabeled(labeledConfirmed));
    dispatch(getFreightClasses(data.address.postalCode));
    next && next();
  };

  const nextServiceCenterWorkDay = () => {
    if (!commonState.pickupDays || Object.keys(commonState.pickupDays).length === 0) return nextBusinessDay();
    let day = moment().add(1, "day"); // default value to avoid TS error (possible undefined)
    for (let i = 1; i < 8; i++) {
      day = moment().add(i, "day");
      let dayOfWeek = day.format("dd").toLowerCase();
      if (commonState.pickupDays[dayOfWeek as keyof weekDays] === true) break;
    }
    return day.format("MM/DD/YYYY");
  };

  useEffect(() => {
    shipperFormRef.current?.setFieldValue("name", returnState.returnShipper.name);
    shipperFormRef.current?.setFieldValue("address.address1", returnState.returnShipper.address.address1);
    shipperFormRef.current?.setFieldValue("address.city", returnState.returnShipper.address.city);
    shipperFormRef.current?.setFieldValue("address.postalCode", returnState.returnShipper.address.postalCode);
    setPostalCodeValue(returnState.returnShipper.address.postalCode);
    shipperFormRef.current?.setFieldValue("address.state", returnState.returnShipper.address.state);
    if (returnState.returnShipper.address.address1) {
      setAddressLookupValue({
        label: `${returnState.returnShipper.address.address1}, ${returnState.returnShipper.address.city}, ${returnState.returnShipper.address.state}, ${returnState.returnShipper.address.postalCode}`,
        value: {
          address: returnState.returnShipper.address.address1,
          city: returnState.returnShipper.address.city,
          state: returnState.returnShipper.address.state,
          zip: returnState.returnShipper.address.postalCode
        }
      });
    }
    const stateOption = usaStates.find(state => state.value === returnState.returnShipper.address.state);
    stateOption && setStateValue(stateOption);
    shipperFormRef.current?.setFieldValue("phone", returnState.returnShipper.phone);
    shipperFormRef.current?.setFieldValue("email", returnState.returnShipper.email);
    setPhoneFieldValue(returnState.returnShipper.phone);
    shipperFormRef.current?.setFieldValue("shipperAvailabilityHours.from", returnState.returnShipper.shipperAvailabilityHours.from);
    shipperFormRef.current?.setFieldValue("shipperAvailabilityHours.to", returnState.returnShipper.shipperAvailabilityHours.to);
    returnState.returnShipper.shipperAvailabilityHours.from
      ? setAvailabilityFromValue({
        value: returnState.returnShipper.shipperAvailabilityHours.from,
        label: returnState.returnShipper.shipperAvailabilityHours.from
      })
      : setAvailabilityFromValue(null);
    returnState.returnShipper.shipperAvailabilityHours.to
      ? setAvailabilityToValue({
        value: returnState.returnShipper.shipperAvailabilityHours.to,
        label: returnState.returnShipper.shipperAvailabilityHours.to
      })
      : setAvailabilityToValue(null);
    shipperFormRef.current?.setFieldValue("specialInstructions", returnState.returnShipper.specialInstructions);
    shipperFormRef.current?.setFieldValue("specialServices", returnState.returnShipper.specialServices);
    shipperFormRef.current?.setFieldValue("pickupDate", returnState.returnShipper.pickupDate);
    setPickupDateFormValue(returnState.returnShipper.pickupDate);
    setLabeledConfirmed(returnState.freightLabeled);
    setTimeout(() => shipperFormRef.current?.validateForm(), 50);
  }, [returnState.returnShipper, returnState.freightLabeled]);

  useEffect(() => {
    if (!availabilityToValue) return;
    const options = toTimeOptions(availabilityFromValue?.value);
    const toIndex = options.findIndex((obj: XGSSelectOption) => obj.value === availabilityToValue.value);
    if (toIndex === -1) {
      // if current "To" time is out of allowed time options
      setAvailabilityToValue(options[0]);
      shipperFormRef.current?.setFieldValue("shipperAvailabilityHours.to", options[0].value);
    }
  }, [availabilityFromValue, availabilityToValue]);

  useEffect(() => {
    if (!commonState.pickupDays || !pickupDateFormValue) return;
    if (!pickupDateFormValue) return;
    const dayOfWeek = moment(pickupDateFormValue).format("dd").toLowerCase();
    if (commonState.pickupDays[dayOfWeek as keyof weekDays] === false) {
      setPickupDateFormValue(undefined);
      shipperFormRef.current?.setFieldValue("pickupDate", undefined);
    }
  }, [commonState.pickupDays, pickupDateFormValue]);

  useEffect(() => {
    setPostalCodeServiceable(returnState.shipperZipServiceable);
    if (!returnState.shipperZipServiceable && returnState.returnShipper.address.postalCode && returnState.shipperZipChecked === returnState.returnShipper.address.postalCode) setShowZipCheckError(true);
  }, [returnState.shipperZipServiceable, returnState.returnShipper.address.postalCode, returnState.shipperZipChecked]);

  return (
    <div className="xgs-bol__return__step">
      {!showManualRequestResult && (
        <Formik
          onSubmit={onClickNext}
          initialValues={initialValues}
          validationSchema={ReturnShipperSchema}
          innerRef={shipperFormRef}
          validateOnMount
          enableReinitialize
        >
          {(props: FormikProps<ReturnShipperModel>) => (
            <Form>
              <div className="xgs-bol__section">Pickup Location</div>
              <XGSFormInput
                type="text"
                name="name"
                label="Shipper:"
                required={true}
                requiredAsteriskDisabled={false}
                labelMode={LabelModes.column}
                className="xgs-bol__field"
              />
              <div className="xgs-bol__address-info">
                <AddressLookup
                  defaultValue={addressLookupValue}
                  onValueChange={(val) => {
                    props.setFieldValue("address.address1", val.address);
                    props.setFieldValue("address.city", val.city);
                    props.setFieldValue("address.state", val.state);
                    const stateOption = usaStates.find(state => state.value === val.state);
                    stateOption && setStateValue(stateOption);
                    setPostalCodeServiceable(false);
                    props.setFieldValue("address.postalCode", val.zip);
                    setPostalCodeValue(val.zip);
                    if (/^\d{5}$/.test(val.zip)) {
                      checkZip(val.zip);
                      dispatch(getPickupDays(val.zip));
                      dispatch(clearItems());
                    }
                    setTimeout(() => {
                      props.setFieldTouched("address.address1", true, true);
                    }, 100);
                  }}
                  onBlur={() => {
                    props.setFieldTouched("address.address1", true, true);
                  }}
                />
                <div className="xgs-form__address-block">
                  <XGSFormInput
                    type="text"
                    name="address.address1"
                    label="Address:"
                    required={true}
                    requiredAsteriskDisabled={false}
                    labelMode={LabelModes.column}
                    className="xgs-bol__field"
                  />
                  <XGSFormInput
                    type="text"
                    name="address.city"
                    label="City:"
                    required={true}
                    requiredAsteriskDisabled={false}
                    labelMode={LabelModes.column}
                    className="xgs-bol__field"
                  />
                  <div className="xgs-bol__fields-row">
                    <XGSFormSelect
                      isSearchable={false}
                      label="State:"
                      labelMode={LabelModes.column}
                      name="address.state"
                      onValueChange={(v: any) => {
                        props.setFieldValue("address.state", v?.value);
                        if (v?.value) {
                          const stateOption = usaStates.find(state => state.value === v.value);
                          stateOption && setStateValue(stateOption);
                        }
                      }}
                      options={usaStates}
                      required={true}
                      requiredAsteriskDisabled={false}
                      formik={true}
                      value={stateValue}
                      className="xgs-bol__field xgs-bol__fields-row__state"
                    />
                    <div className="xgs-bol__field--zip">
                      <XGSFormInput
                        type="text"
                        name="address.postalCode"
                        label="Zip:"
                        required={true}
                        requiredAsteriskDisabled={false}
                        labelMode={LabelModes.column}
                        className="xgs-bol__field xgs-bol__field--zip"
                        onChange={(e) => {
                          setPostalCodeServiceable(false);
                          setShowZipCheckError(false);
                          setPostalCodeValue(e.currentTarget.value);
                          if (/^\d{5}$/.test(e.currentTarget.value)) {
                            checkZip(e.currentTarget.value);
                            dispatch(getPickupDays(e.currentTarget.value));
                            dispatch(clearItems());
                          }
                          props.setFieldValue("pickupDate", undefined);
                          props.handleChange(e);
                        }}
                        value={postalCodeValue}
                        id="xgs-bol__return__shipper-zip--js"
                        hasCustomError={!!(showZipCheckError && props.values.address.postalCode && !props.errors.address?.postalCode)}
                        isLoading={returnState.requestStarted && returnState.requestCreator === "CHECK_ZIP"}
                      />
                      {showZipCheckError && props.values.address.postalCode && !props.errors.address?.postalCode && (
                        <div className="xgs-labeled-input__validation-error" style={{ marginTop: -16 }}>The zip code is out of network coverage</div>
                      )}
                    </div>
                  </div>
                  <div className="xgs-form__field__notes">
                    <strong>Note:</strong> manual address entry could lead to service delays.
                  </div>
                </div>
              </div>
              <div className="xgs-bol__contact-row">
                <XGSFormPhoneInput
                  name="phone"
                  label="Phone:"
                  labelMode={LabelModes.column}
                  onValueChange={(value) => {
                    props.setFieldValue("phone", value);
                    setPhoneFieldValue(value);
                  }}
                  onBlur={props.handleBlur}
                  value={phoneFieldValue}
                  required={true}
                  requiredAsteriskDisabled={false}
                  className="xgs-bol__field xgs-bol__contact-row__phone"
                />
                <div className="xgs-form__field__notes xgs-form__field__notes--contact xgs-form__field__notes--mobile">
                  <strong>Note:</strong> by providing a telephone number and submitting this form you are consenting to be contacted by SMS text message.
                  Message &amp; data rates may apply. You can reply STOP to opt-out of further messaging.
                </div>
                <XGSFormInput
                  type="text"
                  name="email"
                  label="Email:"
                  required={true}
                  requiredAsteriskDisabled={false}
                  labelMode={LabelModes.column}
                  className="xgs-bol__field xgs-bol__contact-row__email"
                />
              </div>
              <div className="xgs-form__field__notes xgs-form__field__notes--contact xgs-form__field__notes--desktop">
                <strong>Note:</strong> by providing a telephone number and submitting this form you are consenting to be contacted by SMS text message.
                Message &amp; data rates may apply. You can reply STOP to opt-out of further messaging.
              </div>
              <div className="xgs-bol__return__pickup-row">
                <XGSFormDate
                  name="pickupDate"
                  label="Freight Available Date:"
                  onDateChange={(v) => {
                    setPickupDateFormValue(v);
                    props.setFieldValue("pickupDate", v)
                  }}
                  onChange={() => null}
                  minDate={new Date(nextServiceCenterWorkDay())}
                  required={true}
                  requiredAsteriskDisabled={false}
                  labelMode={LabelModes.column}
                  className="xgs-bol__return__pickup-row__date"
                  disableWeekends={true}
                  disabledWeekDays={commonState.pickupDays}
                  disabled={!postalCodeValue}
                />
                <div className="xgs-bol__return__field-notes xgs-bol__return__field-notes--mobile">
                  <strong>Note:</strong> available dates depend on the pickup address.
                </div>
                <div className="xgs-bol__return__availability">
                  <div className="xgs-bol__return__availability__label">Hours of Availability: <span>*</span></div>
                  <div className="xgs-bol__return__availability__controls">
                    <LabeledSelectInput
                      name="shipperAvailabilityHours.from"
                      value={availabilityFromValue}
                      label=""
                      isSearchable={false}
                      labelMode={LabelModes.column}
                      onValueChange={(v) => {
                        props.setFieldValue("shipperAvailabilityHours.from", v?.value);
                        setAvailabilityFromValue(v || null);
                      }}
                      options={fromTimeOptions()}
                      required={false}
                      requiredAsteriskDisabled={false}
                      formik={true}
                      placeholder="From..."
                      menuPlacement="top"
                    />
                    <div className="xgs-bol__return__availability__controls__delimiter">-</div>
                    <LabeledSelectInput
                      name="shipperAvailabilityHours.to"
                      value={availabilityToValue}
                      label=""
                      isSearchable={false}
                      labelMode={LabelModes.column}
                      onValueChange={(v) => {
                        props.setFieldValue("shipperAvailabilityHours.to", v?.value);
                        setAvailabilityToValue(v || null);
                      }}
                      options={toTimeOptions(availabilityFromValue?.value)}
                      required={false}
                      requiredAsteriskDisabled={false}
                      formik={true}
                      placeholder="To..."
                      menuPlacement="top"
                    />
                  </div>
                </div>
                <div className="xgs-bol__return__field-notes xgs-bol__return__field-notes--mobile">
                  <strong>Note:</strong> the minimum time interval is 3 hours.
                </div>
              </div>
              <div className="xgs-bol__return__field-notes xgs-bol__return__field-notes--list xgs-bol__return__field-notes--desktop">
                <strong>Notes:</strong>
                <ul>
                  <li>Available dates depend on the pickup address.</li>
                  <li>The minimum time interval is 3 hours.</li>
                </ul>
              </div>
              <div className="xgs-form__checkbox xgs-bol__checkbox xgs-bol__checkbox--field">
                <label>
                  <input
                    type="checkbox"
                    name="labeled-confirm-checkbox"
                    onClick={() => setLabeledConfirmed(!labeledConfirmed)}
                    checked={labeledConfirmed}
                  />
                  <div>
                    The return freight will be <strong>wrapped</strong>, <strong>labeled</strong> and <strong>ready for pick up</strong> by stated date. <span>*</span>
                  </div>
                </label>
              </div>
              <div className="xgs-bol__field">
                <XGSFormTextarea
                  name="specialInstructions"
                  value={props.values.specialInstructions}
                  label="Special Instructions:"
                  placeholder="Entry Instructions such as gate code or dock doors. Hour Information such as lunch hours or special closing times."
                  required={false}
                  rows={4}
                  counter={150}
                />
              </div>
              <div className="xgs-form__label" style={{ marginBottom: 8 }}>Special Services:</div>
              <div className="xgs-bol__return__services">
                <div
                  className="xgs-bol__return__services__help"
                  title="Click to view related help instructions"
                  onClick={() => setShowServicesHelp(true)}
                >
                  <XGSIcon
                    icon={XGSRegularIcons.faQuestionCircle}
                    size="1x"
                  />
                </div>
                {RETURN_SERVICES.map((field) =>
                  <div className="xgs-form__checkbox" key={field.value}>
                    <label>
                      <Field
                        type="checkbox"
                        name="specialServices"
                        value={field.value}
                      />
                      <div className="xgs-bol__return__services__name">{field.label}</div>
                    </label>
                  </div>
                )}
              </div>
              <div className="xgs-bol__buttons">
                <Button
                  type="button"
                  theme={ButtonThemes.gray}
                  className="xgs-bol__nav-button"
                  onClick={() => onClickBack(props.values)}
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  theme={ButtonThemes.blue}
                  disabled={
                    !props.isValid ||
                    !props.values.name ||
                    !postalCodeServiceable ||
                    !labeledConfirmed ||
                    (returnState.requestStarted && returnState.requestCreator === "CHECK_ZIP") ||
                    (commonState.requestStarted && commonState.requestCreator === "GET_SERVICE_CENTER_DAYS")
                  }
                  className="xgs-bol__nav-button"
                >
                  Next
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
      {showManualRequestResult && (
        <div className="xgs-bol__return__shipper__description">
          The request was sent.<br />
          We will contact you as soon as possible.<br /><br />
          <Button
            type="button"
            theme={ButtonThemes.blue}
            className="xgs-bol__nav-button"
            onClick={() => {
              dispatch(resetReturnState());
              dispatch(resetErrors());
              push && push("return-common-step");
              setShowManualRequestResult(false);
            }}
          >
            Start Over
          </Button>
        </div>
      )}
      <Modal
        isOpen={showManualRequest}
        style={modalStyle}
      >
        <>
          <div className="xgs-modal__content">
            <div className="xgs-bol__return__shipper__description">
              Unfortunately, we do not have network coverage at the zip code you provided so your return cannot be processed through our customer portal.<br /><br />
              However, our returns team is happy to reach out to you to process this manually.
            </div>
          </div>
          <div className="xgs-modal__buttons xgs-bol__return__modal__buttons">
            <Button
              type="button"
              theme={ButtonThemes.blue}
              className="xgs-modal__button xgs-bol__return__modal__button--wide"
              onClick={startManualRequest}
              spinner={returnState.requestStarted && returnState.requestCreator === "MANUAL_RETURN"}
            >
              Contact Returns Team
            </Button>
            <Button
              type="button"
              theme={ButtonThemes.blue}
              className="xgs-modal__button"
              onClick={() => {
                setPostalCodeServiceable(false);
                setShowManualRequest(false);
              }}
            >
              Change Address
            </Button>
          </div>
        </>
      </Modal>
      <ConfirmationModal
        opened={showServicesHelp}
        header="Services Help"
        confirmButtonText="Close"
        onConfirm={() => setShowServicesHelp(false)}
      >
        <div style={{ marginBottom: 16 }}>The meaning of the special services is described below to help you understand them.</div>
        {RETURN_SERVICES.map((field) =>
          <div style={{ marginBottom: 8 }}>
            <strong>{field.label}</strong> - {field.description.charAt(0).toLowerCase() + field.description.slice(1)}
          </div>
        )}
      </ConfirmationModal>
    </div>
  );
};

export default ReturnShipperStep;

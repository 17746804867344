import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import EmployeesState from "../../../slices/admin/employees/EmployeesState";
import {
  deleteEmployee,
  employeesSelector,
  getEmployees,
  resetErrors,
  updateEmployee,
  updateEmployeeProfile
} from "../../../slices/admin/employees/employeesSlice";
import InviteManagementState from "../../../slices/invite-management/InviteManagementState";
import {
  cancelInvite,
  inviteManagementSelector,
  resendInvite
} from "../../../slices/invite-management/inviteManagementSlice";
import UserState from "../../../slices/user/UserState";
import { userSelector } from "../../../slices/user/userSlice";
import UserProfile from "../../../app/data/user/UserProfile";
import { UserUtils } from "../../../app/data/user/userUtils";
import SlideOutSidebar from "../../../ui-components/slide-out-sidebar/slideOutSidebar";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import LabeledSelectInput from "../../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import { XGSSelectOption } from "../../../ui-components/xgs-select/xgsSelect";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import XGSInlineSwitch from "../../../ui-components/xgs-inline-switch/xgsInlineSwitch";
import XGSInput from "../../../ui-components/xgs-input/xgsInput";
import XGSPhoneInput from "../../../ui-components/xgs-phone-input/xgsPhoneInput";
import Loading from "../../../ui-components/loading/loading";
import ConfirmationModal from "../../../ui-components/confirmation-modal/confirmationModal";
import { employeeRoles, employeeStatuses } from "./constants";
import { ERROR_MESSAGES } from "../../../app/data/common/errorMessages";

import "./employees.scss";

export interface EmployeesDetailsProps {
  id: string | undefined;
  show: boolean;
  onClose: () => void;
  onDelete: () => void;
}

const EmployeesDetails: React.FC<EmployeesDetailsProps> = (props) => {
  const [userData, setUserData] = useState<UserProfile>();
  const [userRole, setUserRole] = useState<XGSSelectOption | null>();
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const dispatch = useDispatch();
  const employeesState: EmployeesState = useSelector(employeesSelector);
  const inviteManagementState: InviteManagementState = useSelector(inviteManagementSelector);
  const userState: UserState = useSelector(userSelector);

  const updateProfile = (newData: any) => {
    if (!userData) return;
    dispatch(updateEmployeeProfile(newData, userData.id, () => {
      toast.info("The profile was updated!");
    }));
  };

  const saveRole = () => {
    if (!userData?.id || !userRole?.value) return;
    dispatch(updateEmployee(userData.id, "role", userRole.value, () => {
      toast.info("The user role has been changed!");
    }));
  };

  const enableUser = () => {
    if (!userData?.id) return;
    dispatch(updateEmployee(userData.id, "status", "APPROVED", () => {
      toast.info("The user was enabled!");
    }));
  };

  const disableUser = () => {
    if (!userData?.id) return;
    dispatch(updateEmployee(userData.id, "status", "DISABLED", () => {
      toast.info("The user was disabled!");
    }));
  };

  const onDeleteUser = () => {
    if (!userData?.id) return;
    dispatch(deleteEmployee(userData.id, () => {
      setShowRemoveConfirmation(false);
      toast.info("The user was deleted!");
      props.onDelete();
      props.onClose();
    }));
  };

  const onResendInvite = () => {
    if (!userData?.id) return;
    dispatch(resendInvite(userData.email, () => {
      dispatch(updateEmployee(userData.id, "invitation.date", new Date().toISOString(), () => {
        toast.info("The invite was resent!");
      }));
    }));
  };

  const onCancelInvite = () => {
    if (!userData?.id) return;
    dispatch(cancelInvite(userData.email, () => {
      toast.info("The invite was canceled!");
      dispatch(getEmployees(""));
      props.onClose();
    }));
  };

  const resetRole = () => {
    userData?.role && setUserRole(employeeRoles.find(role => role.value === userData.role));
  };

  useEffect(() => {
    if (!props.id) return;
    const user = employeesState.employees?.find(user => user.id === props.id);
    dispatch(resetErrors());
    setUserData(user);
    user?.role && setUserRole(employeeRoles.find(role => role.value === user.role));
  }, [dispatch, employeesState.employees, props.id]);

  useEffect(() => {
    if (!employeesState.update_was_failed) return;
    toast.error(employeesState.update_fail_reason || ERROR_MESSAGES.COMMON);
  }, [employeesState.update_was_failed, employeesState.update_fail_reason]);

  useEffect(() => {
    setFirstName(userData?.firstName || "");
    setLastName(userData?.lastName || "");
    setTitle(userData?.title || "");
    setEmail(userData?.email || "");
    setPhoneNumber(userData?.phoneNumber || "");
  }, [userData]);

  return (
    <SlideOutSidebar
      header="User information"
      onClose={props.onClose}
      show={props.show}
    >      
      {userData && (
        <>
          <div className="xgs-employees__details" key={props.id}>
            <div className="xgs-sidebar__details__name-block">
              <div className="xgs-sidebar__details__avatar">{ userData.firstName?.charAt(0) }</div>
              <div>
                <div className="xgs-sidebar__details__name">
                  <XGSInlineSwitch
                    name="name"
                    staticContent={<>{ userData.firstName } { userData.lastName }</>}
                    formElement={
                      <>
                        <XGSInput
                          type="text"
                          name="firstName"
                          value={firstName}
                          placeholder="First Name"
                          onChange={(e) => setFirstName(e.currentTarget.value)}
                        />
                        &nbsp;
                        <XGSInput
                          type="text"
                          name="lastName"
                          value={lastName}
                          placeholder="Last Name"
                          onChange={(e) => setLastName(e.currentTarget.value)}
                        />                      
                      </>
                    }
                    onCancel={() => {
                      setFirstName(userData.firstName);
                      setLastName(userData.lastName);
                    }}
                    onSubmit={() => updateProfile({firstName, lastName})}
                  />
                </div>
                <div className="xgs-sidebar__details__title">
                  <XGSInlineSwitch
                    name="title"
                    staticContent={userData.title || <>(no title)</>}
                    formElement={
                      <XGSInput
                        type="text"
                        name="title"
                        value={title}
                        placeholder="e.g. Account Manager"
                        onChange={(e) => setTitle(e.currentTarget.value)}
                      />
                    }
                    onCancel={() => setTitle(userData.title)}
                    onSubmit={() => updateProfile({title})}
                  />
                </div>
              </div>
            </div>
            <div className="xgs-sidebar__details__rows">
              <div className="xgs-sidebar__details__row">
                <div className="xgs-sidebar__details__label">Email:</div> 
                <div className="xgs-sidebar__details__value">
                  <XGSInlineSwitch
                    name="email"
                    staticContent={
                      <a href={"mailto:" + userData.email} className="xgs-employees__details__link">{ userData.email }</a>
                    }
                    formElement={
                      <XGSInput
                        type="text"
                        name="email"
                        value={email}
                        placeholder="user@example.com"
                        onChange={(e) => setEmail(e.currentTarget.value)}
                      />
                    }
                    onCancel={() => setEmail(userData.email)}
                    onSubmit={() => updateProfile({email})}
                    disabled={userData.status === "INVITED"}
                  />
                  {userData.pendingEmail && (userData.pendingEmail !== userData.email) && (
                    <div className="xgs-employees__details__additional__info">
                      <div className="gs-employees__details__additional__info__email-block">
                        Change of email address to <strong>{userData.pendingEmail}</strong> has been requested.
                      </div>
                      The user hasn't confirmed this change yet.
                    </div>
                  )}
                </div>
              </div>
              <div className="xgs-sidebar__details__row">
                <div className="xgs-sidebar__details__label">Phone:</div>
                <div className="xgs-sidebar__details__value">
                  <XGSInlineSwitch
                    name="phone"
                    staticContent={userData.phoneNumber || <>(no phone)</>}
                    formElement={
                      <XGSPhoneInput
                        name="phoneNumber"
                        onValueChange={(value) => setPhoneNumber(value)}
                        onBlur={(e) => setPhoneNumber(e.currentTarget.value)}
                        value={phoneNumber}
                      />
                    }
                    onCancel={() => setPhoneNumber(userData.phoneNumber)}
                    onSubmit={() => {
                      updateProfile({phoneNumber});
                    }}
                    notes={
                      <div className="xgs-form__field__notes">
                        <strong>Note:</strong> by providing a telephone number and submitting this form you are consenting to be contacted by SMS text message.
                        Message &amp; data rates may apply. You can reply STOP to opt-out of further messaging.
                      </div>
                    }
                  />
                </div>
              </div>
              <div className="xgs-sidebar__details__row">
                <div className="xgs-sidebar__details__label">Sign-up date:</div>
                <div className="xgs-sidebar__details__value">{ userData.dateCreated.toUiDateFormat() }</div>
              </div>
              <div className="xgs-sidebar__details__row">
                <div className="xgs-employees__details__form">
                  <LabeledSelectInput
                    className="xgs-employees__details__form__dropdown"
                    isSearchable={false}
                    label="Role:"
                    labelMode={LabelModes.row}
                    name="role"
                    onValueChange={(v) => { setUserRole(v) }}
                    options={employeeRoles}
                    required={true}
                    requiredAsteriskDisabled={true}
                    value={userRole}
                  />
                  {(userRole?.value !== userData.role) && !employeesState.update_was_started && (
                    <>
                      <Button
                        className="xgs-employees__details__form__button"
                        theme={ButtonThemes.blue}
                        disabled={userRole?.value === userData.role}
                        spinner={false}
                        onClick={() => { saveRole() }}>
                        <XGSIcon
                          icon={XGSIcons.faCheck}
                          size="sm"
                        />
                      </Button>
                      <Button
                        className="xgs-employees__details__form__button"
                        type="button"
                        theme={ButtonThemes.gray}
                        disabled={userRole?.value === userData.role}
                        spinner={false}
                        onClick={() => { resetRole() }}>
                        <XGSIcon
                          icon={XGSIcons.faTimes}
                          size="sm"
                        />
                      </Button>
                    </>
                  )}
                </div>
              </div>
              <div className="xgs-sidebar__details__row">
                <div className="xgs-sidebar__details__label">Status:</div>
                <div className="xgs-sidebar__details__value flexbox">
                  <div
                    className="xgs-employees__details__status__circle"
                    style={{
                      backgroundColor: employeeStatuses.find((status) => status.value === userData.status)?.color
                    }}></div>
                  <div className="xgs-employees__details__status__name">
                    {employeeStatuses.find((status) => status.value === userData.status)?.name }
                    {userData.status === "INVITED" && (
                      <>
                        &nbsp;<span className="xgs-employees__details__invited-on">on {userData.invitation?.date.toUiDateFormat()}</span>
                      </> 
                    )}
                  </div>
                  <div className="xgs-employees__details__status__actions">
                    {userData.status === "APPROVED" && !(employeesState.update_was_started && employeesState.update_creator === "status") && (
                      <>
                        <span className="xgs-employees__details__link" onClick={() => { disableUser() }}>Disable</span>
                      </>
                    )}
                    {userData.status === "INVITED" && !(employeesState.update_was_started && employeesState.update_creator === "status") && (
                      <>
                        <span className="xgs-employees__details__link" onClick={() => { onResendInvite() }}>Resend&nbsp;Invite</span> <span className="xgs-employees__details__link" onClick={() => { onCancelInvite(); }}>Cancel&nbsp;Invite</span>
                      </>
                    )}
                    {userData.status === "DISABLED" && !(employeesState.update_was_started && employeesState.update_creator === "status") && (
                      <>
                        <span className="xgs-employees__details__link" onClick={() => { enableUser(); }}>Enable</span>
                        {(UserUtils.isXGSAdministrator(userState.profile)) && (
                          <span
                            className="red-link"
                            onClick={() => { setShowRemoveConfirmation(true); }}
                          >
                            Delete&nbsp;permanently
                          </span>
                        )}
                      </>
                    )}
                    {((employeesState.update_was_started && employeesState.update_creator === "status") || inviteManagementState.request_was_started) && (
                      <Loading isLoading={true} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ConfirmationModal
            opened={showRemoveConfirmation}
            header="Delete permanently"
            confirmButtonText="Delete user"
            spinner={employeesState.update_was_started && employeesState.update_creator === "delete"}
            onCancel={() => { setShowRemoveConfirmation(false); }}
            onConfirm={() => { onDeleteUser(); }}
          >
            This operation cannot be undone,<br />
            the user will be removed permanently.
          </ConfirmationModal>
        </>
      )}
    </SlideOutSidebar>
  );
};

export default EmployeesDetails;

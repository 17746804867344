import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import { Form, Formik, FormikProps } from "formik";
import Modal from "react-modal";
import SlideOutSidebar from "../../../ui-components/slide-out-sidebar/slideOutSidebar";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import LabeledSelectInput from "../../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import { XGSSelectOption } from "../../../ui-components/xgs-select/xgsSelect";
import XGSFormTextarea from "../../../ui-components/form/textarea/xgsFormTextarea";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import RegistrationApproveModal from "./registrationApproveModal";
import ShipperApproveModal from "./shipperApproveModal";
import PendingRequestsState from "../../../slices/customers/pending/PendingRequestsState";
import {
  pendingRequestsSelector,
  rejectRequest,
  resetErrors
} from "../../../slices/customers/pending/pendingRequestsSlice";
import { resetAs400Account } from "../../../slices/customers/teams/teamsSlice";
import { usaStates } from "../../../app/data/common/usaStates";
import {
  RequestRejectModel,
  RequestRejectSchema
} from "../../../app/data/user/requestModels";
import {
  REGISTRATION_REJECT_REASONS,
  REQUEST_TYPES,
  SHIPPER_REJECT_REASONS
} from "./constants";
import { ERROR_MESSAGES } from "../../../app/data/common/errorMessages";
import { modalStyle } from "../../../app/data/common/modalStyle";
import "../../../sass/modal.scss";
import "../teams/teams.scss";
import "./pendingRequests.scss";

const rejectInitialValues: RequestRejectModel = {
  request: "",
  reason: "",
  comment: ""
}

export interface RequestDetailsProps {
  id: string | undefined;
  show: boolean;
  onUpdate: () => void;
  onClose: () => void;
}

const RequestDetails: React.FC<RequestDetailsProps> = (props) => {
  const dispatch = useDispatch();
  const requestsState: PendingRequestsState = useSelector(pendingRequestsSelector);
  const [requestData, setRequestData] = useState<any>();
  const [showApprove, setShowApprove] = useState<string>("");
  const [showReject, setShowReject] = useState<boolean>(false);
  const [reasonValue, setReasonValue] = useState<XGSSelectOption | null>();
  const [commentValue, setCommentValue] = useState<string>("");
  const rejectFormRef = useRef<any>(null);

  const onReject = (data: RequestRejectModel) => {
    dispatch(rejectRequest(data, !!requestData.shipperRequest, () => {
      setShowReject(false);
      toast.info("The request was rejected!");
      props.onUpdate();
    }));    
  };

  useEffect(() => {
    if (!props.id) return;
    const request = requestsState.pendingRequests?.find((request: any) => request.id === props.id);
    setRequestData(request);
  }, [requestsState.pendingRequests, props.id]);

  useEffect(() => {
    if (!requestsState.request_was_failed) return;
    toast.error(requestsState.request_fail_reason || ERROR_MESSAGES.COMMON);
    dispatch(resetErrors());
  }, [requestsState.request_was_failed, requestsState.request_fail_reason, dispatch]);

  useEffect(() => {
    dispatch(resetAs400Account());
  }, [props.id, dispatch]);

  return (
    <SlideOutSidebar
      header="Request Details"
      onClose={props.onClose}
      show={props.show}
    >
      {requestData && (
        <div className="xgs-pending-requests__details">
          <div className="xgs-sidebar__details__rows">
            <div className="xgs-pending-requests__details__header">Request Information</div>
            <div className="xgs-sidebar__details__row">
              <div className="xgs-sidebar__details__label">Date:</div>
              <div className="xgs-sidebar__details__value">{requestData.dateCreated.toUiDateFormat()} {moment(requestData.dateCreated).format("h:mm a")}</div>
            </div>
            <div className="xgs-sidebar__details__row">
              <div className="xgs-sidebar__details__label">Type:</div>
              <div className="xgs-sidebar__details__value">{REQUEST_TYPES.find(obj => obj.value === requestData.type)?.label}</div>
            </div>
          </div>
          {requestData.registrationRequest && (
            <>
              <div className="xgs-sidebar__details__rows">
                <div className="xgs-pending-requests__details__header">Personal Information</div>
                <div className="xgs-sidebar__details__row">
                  <div className="xgs-sidebar__details__label">First Name:</div>
                  <div className="xgs-sidebar__details__value">{requestData.registrationRequest.firstName}</div>
                </div>
                <div className="xgs-sidebar__details__row">
                  <div className="xgs-sidebar__details__label">Last Name:</div>
                  <div className="xgs-sidebar__details__value">{requestData.registrationRequest.lastName}</div>
                </div>
                <div className="xgs-sidebar__details__row">
                  <div className="xgs-sidebar__details__label">Title:</div>
                  <div className="xgs-sidebar__details__value">{requestData.registrationRequest.title}</div>
                </div>
                <div className="xgs-sidebar__details__row">
                  <div className="xgs-sidebar__details__label">Email:</div>
                  <div className="xgs-sidebar__details__value"><a href={`mailto:${requestData.registrationRequest.email}`} className="blue-link">{requestData.registrationRequest.email}</a></div>
                </div>
              </div>
              <div className="xgs-sidebar__details__rows">
                <div className="xgs-pending-requests__details__header">Company Information</div>
                <div className="xgs-sidebar__details__row">
                  <div className="xgs-sidebar__details__label">Account Number:</div>
                  <div className="xgs-sidebar__details__value">{requestData.registrationRequest.accountNumber || "-"}</div>
                </div>
                <div className="xgs-sidebar__details__row">
                  <div className="xgs-sidebar__details__label">Company Name:</div>
                  <div className="xgs-sidebar__details__value">{requestData.registrationRequest.companyName}</div>
                </div>
                <div className="xgs-sidebar__details__row">
                  <div className="xgs-sidebar__details__label">Street Address:</div>
                  <div className="xgs-sidebar__details__value">{requestData.registrationRequest.address1}</div>
                </div>
                <div className="xgs-sidebar__details__row">
                  <div className="xgs-sidebar__details__label">Suite:</div>
                  <div className="xgs-sidebar__details__value">{requestData.registrationRequest.address2 || "-"}</div>
                </div>
                <div className="xgs-sidebar__details__row">
                  <div className="xgs-sidebar__details__label">City:</div>
                  <div className="xgs-sidebar__details__value">{requestData.registrationRequest.city}</div>
                </div>
                <div className="xgs-sidebar__details__row">
                  <div className="xgs-sidebar__details__label">State:</div>
                  <div className="xgs-sidebar__details__value">{usaStates.find(state => state.value === requestData.registrationRequest.state)?.label || requestData.registrationRequest.state || "-"}</div>
                </div>
                <div className="xgs-sidebar__details__row">
                  <div className="xgs-sidebar__details__label">Zip:</div>
                  <div className="xgs-sidebar__details__value">{requestData.registrationRequest.zip}</div>
                </div>
              </div>
              <div className="xgs-sidebar__details__rows">
                <div className="xgs-pending-requests__details__header">Contact Information</div>
                <div className="xgs-sidebar__details__row">
                  <div className="xgs-sidebar__details__label">Office Phone:</div>
                  <div className="xgs-sidebar__details__value">{requestData.registrationRequest.officePhone}</div>
                </div>
                <div className="xgs-sidebar__details__row">
                  <div className="xgs-sidebar__details__label">Mobile Phone:</div>
                  <div className="xgs-sidebar__details__value">{requestData.registrationRequest.mobilePhone}</div>
                </div>
              </div>
            </>
          )}
          {requestData.shipperRequest && (
            <>
              <div className="xgs-sidebar__details__rows">
                <div className="xgs-pending-requests__details__header">Applicant Information</div>
                <div className="xgs-sidebar__details__row">
                  <div className="xgs-sidebar__details__label">Team:</div>
                  <div className="xgs-sidebar__details__value">{requestData.shipperRequest.team.name}</div>
                </div>
                <div className="xgs-sidebar__details__row">
                  <div className="xgs-sidebar__details__label">Name:</div>
                  <div className="xgs-sidebar__details__value">{requestData.shipperRequest.user.name}</div>
                </div>
                <div className="xgs-sidebar__details__row">
                  <div className="xgs-sidebar__details__label">Email:</div>
                  <div className="xgs-sidebar__details__value"><a href={`mailto:${requestData.shipperRequest.user.email}`} className="blue-link">{requestData.shipperRequest.user.email}</a></div>
                </div>
              </div>
              <div className="xgs-sidebar__details__rows">
                <div className="xgs-pending-requests__details__header">Shipper Information</div>
                <div className="xgs-sidebar__details__row">
                  <div className="xgs-sidebar__details__label">Account:</div>
                  <div className="xgs-sidebar__details__value">{requestData.shipperRequest.name}</div>
                </div>
                <div className="xgs-sidebar__details__row">
                  <div className="xgs-sidebar__details__label">Address:</div>
                  <div className="xgs-sidebar__details__value">{requestData.shipperRequest.address.address1}</div>
                </div>
                <div className="xgs-sidebar__details__row">
                  <div className="xgs-sidebar__details__label">City:</div>
                  <div className="xgs-sidebar__details__value">{requestData.shipperRequest.address.city}</div>
                </div>
                <div className="xgs-sidebar__details__row">
                  <div className="xgs-sidebar__details__label">State:</div>
                  <div className="xgs-sidebar__details__value">{usaStates.find(state => state.value === requestData.shipperRequest.address.state)?.label || requestData.shipperRequest.address.state || "-"}</div>
                </div>
                <div className="xgs-sidebar__details__row">
                  <div className="xgs-sidebar__details__label">Zip:</div>
                  <div className="xgs-sidebar__details__value">{requestData.shipperRequest.address.postalCode}</div>
                </div>
              </div>
            </>
          )}
          <div className="xgs-pending-requests__details__controls">
            <Button
              theme={ButtonThemes.green}
              onClick={() => setShowApprove(requestData.shipperRequest ? "shipper" : "registration")}
            >
              <XGSIcon
                icon={XGSIcons.faCheck}
                size="sm"
              /> Approve
            </Button>
            <Button
              type="button"
              theme={ButtonThemes.red}
              onClick={() => {
                setReasonValue(null);
                setCommentValue("");
                setTimeout(() => {
                  rejectFormRef.current?.setFieldValue("request", props.id);
                  rejectFormRef.current?.setFieldValue("reason", "");
                  rejectFormRef.current?.setFieldValue("comment", "");
                }, 100);
                setShowReject(true);
              }}
            >
              <XGSIcon
                icon={XGSIcons.faTimes}
                size="sm"
              /> Reject
            </Button>
          </div>
        </div>
      )}
      <RegistrationApproveModal
        show={showApprove === "registration"}
        onClose={() => setShowApprove("")}
        onUpdate={props.onUpdate}
        requestId={props.id}
        requestData={requestData}
      />
      <ShipperApproveModal
        show={showApprove === "shipper"}
        onClose={() => setShowApprove("")}
        onUpdate={props.onUpdate}
        requestId={props.id}
        requestData={requestData}
      />
      <Modal
        isOpen={showReject}
        style={modalStyle}
      >
        <div className="xgs-pending-requests__details__modal">
          <div className="xgs-modal__header">
            Reject Request
          </div>
          <Formik
            onSubmit={onReject}
            initialValues={rejectInitialValues}
            validationSchema={RequestRejectSchema}
            innerRef={rejectFormRef}
            validateOnMount
            enableReinitialize
          >
            {(props: FormikProps<RequestRejectModel>) => (
              <Form>
                <div className="xgs-pending-requests__details__modal">
                  <div className="xgs-modal__content" style={{ marginBottom: 48 }}>
                    <input type="hidden" name="request" />
                    <LabeledSelectInput
                      isSearchable={false}
                      label="Reason:"
                      labelMode={LabelModes.column}
                      name="reason"
                      onValueChange={(v) => {
                        if (!v) return;
                        props.setFieldValue("reason", v.value);
                        setReasonValue(v);
                      }}
                      options={requestData.shipperRequest ? SHIPPER_REJECT_REASONS : REGISTRATION_REJECT_REASONS}
                      required={true}
                      value={reasonValue}
                    />
                    <div className="xgs-pending-requests__details__modal__textarea">
                      <XGSFormTextarea
                        name="comment"
                        value={commentValue}
                        label="Comments:"
                        required={reasonValue?.value === "CUSTOM" || reasonValue?.value === "OTHER"}
                        rows={4}
                        counter={250}
                        onChange={(v) => {
                          props.setFieldValue("comment", v.currentTarget.value);
                          setCommentValue(v.currentTarget.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="xgs-modal__buttons" style={{ textAlign: "center" }}>
                    <Button
                      theme={ButtonThemes.blue}
                      onClick={() => {
                        setShowReject(false);
                      }}
                      className="xgs-modal__button"
                      type="button"
                    >
                      Cancel
                    </Button>
                    <Button
                      theme={ButtonThemes.red}
                      spinner={requestsState.request_was_started && requestsState.request_creator === "REJECT"}
                      className="xgs-modal__button"
                      type="submit"
                      disabled={!props.isValid || !props.dirty}
                    >
                      Reject
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </SlideOutSidebar>
  );
};

export default RequestDetails;

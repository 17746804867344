import api, { ApiResponse } from "./../api";
import { EmployeeFeedbackModel, FeedbackModel } from "./models";

class FeedbackService {
  private static instance: FeedbackService;

  private constructor() {}

  static getInstance(): FeedbackService {
    if (!FeedbackService.instance) {
      FeedbackService.instance = new FeedbackService();
    }

    return FeedbackService.instance;
  }

  public submitFeedback = async (
    data: FeedbackModel
  ): Promise<ApiResponse<any>> => {
    return await api.post("/feedback", data);
  };

  public submitJiraFeedback = async (
    data: EmployeeFeedbackModel
  ): Promise<ApiResponse<any>> => {
    return await api.post("/jira/feedback", {
      ...data,
      rating: Number(data.rating),
    });
  };

  public getSubjects = async (): Promise<ApiResponse<any>> => {
    return await api.get("/feedback/subjects");
  };  
}

export default FeedbackService;

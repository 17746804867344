import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import { toast } from "react-toastify";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Modal from "react-modal";
import ConfirmationModal from "../../../ui-components/confirmation-modal/confirmationModal";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import LabeledDateRangeInput from "../../../ui-components/molecules/labeled-inputs/labeled-date-range-input/labeledDateRangeInput";
import LabeledInput from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import Help from "../../../ui-components/help/help";
import Tag from "../../../ui-components/molecules/tag/tag";
import XGSToggle from "../../../ui-components/xgs-toggle/xgs-toggle";
import { TagColor } from "../../../app/data/common/tagColor";
import {
  AnnouncementModel,
  AnnouncementResponseModel,
  AnnouncementSchema,
  TestEmailModel,
  TestEmailSchema
} from "../../../app/data/announcement/models";
import { UserUtils } from "../../../app/data/user/userUtils";
import AnnouncementState from "../../../slices/announcement/AnnouncementState";
import {
  announcementSelector,
  createAnnouncement,
  getAnnouncements,
  notifyUsers,
  resetAnnouncement,
  resetErrors,
  sendTestEmail,
  updateAnnouncement
} from "../../../slices/announcement/announcementSlice";
import UserState from "../../../slices/user/UserState";
import { userSelector } from "../../../slices/user/userSlice";
import { modalStyle } from "../../../app/data/common/modalStyle"
import "./announcementForm.scss";

let initialValues: AnnouncementModel = {
  id: "",
  header: "",
  body: "",
  needUserAcceptance: false,
  needUserNotification: false,
  from: "",
  to: "",
  status: ""
};

const testEmailInitialValues: TestEmailModel = {
  email: ""
}

const quillModules = {
  clipboard: {
    matchVisual: false
  },
  toolbar: [
    ["bold", "italic", "underline"],
    [{"list": "bullet"}],
    ["link"],
    ["clean"]
  ]
};

interface AnnouncementFormProps {
  type: "CUSTOMER" | "EMPLOYEE";
};

const AnnouncementForm: React.FC<AnnouncementFormProps> = (props) => {
  const dispatch = useDispatch();
  const announcementState: AnnouncementState = useSelector(announcementSelector);
  const userState: UserState = useSelector(userSelector);
  const [body, setBody] = useState("");
  const [from, setFromDate] = useState<string>("");
  const [to, setToDate] = useState<string>("");
  const [turnOffConfirmOpen, setTurnOffConfirmOpen] = useState<boolean>(false);
  const [showSendTestEmail, setShowSendTestEmail] = useState<boolean>(false);
  const [announcement, setAnnouncement] = useState<AnnouncementResponseModel>();
  const announcementFormRef = useRef<any>(null);

  const onSubmit = (request: AnnouncementModel) => {
    if (request.from) request.from = request.from.toApiDateFormat();
    if (request.to) request.to = request.to.toApiDateFormat();
    if (!request.recipientType) request.recipientType = props.type;
    if (announcement?.id) {
      dispatch(updateAnnouncement(request, () => {
        toast.info("The announcement has been updated!");
      }));
    } else {
      dispatch(createAnnouncement(request, () => {
        toast.info("The announcement has been created and published!");
      }));
    }
  };

  const onCompleteAnnouncement = () => {
    if (!announcement?.id) return;
    let request = {
      id: announcement.id,
      header: "",
      body: "",
      needUserAcceptance: false,
      needUserNotification: false,
      from: "",
      to: "",
      status: "COMPLETED",
      recipientType: props.type
    }
    dispatch(updateAnnouncement(request, () => {
      toast.info("The announcement has been turned off!");
      dispatch(resetAnnouncement(props.type));
      setTurnOffConfirmOpen(false);
      announcementFormRef.current.resetForm();
    }));
  };

  const valuesWereChanged = (formValues: AnnouncementModel) => {
    let storedValues = {
      header: announcement?.header,
      body: announcement?.body,
      needUserAcceptance: announcement?.needUserAcceptance,
      needUserNotification: announcement?.needUserNotification,
      from: announcement?.from ? announcement.from.toUiDateFormat() : "",
      to: announcement?.to ? announcement.to.toUiDateFormat() : ""
    };
    return storedValues.header !== formValues.header ||
      storedValues.body !== formValues.body ||
      storedValues.needUserAcceptance !== formValues.needUserAcceptance ||
      storedValues.needUserNotification !== formValues.needUserNotification ||
      storedValues.from !== (formValues.from || "") ||
      storedValues.to !== (formValues.to || "");
  };

  const prepopulateFields = useCallback(() => {
    announcementFormRef.current.setFieldValue("id", announcement?.id || "");
    announcementFormRef.current.setFieldValue("header", announcement?.header || "");
    announcementFormRef.current.setFieldValue("body", announcement?.body || "", false);
    setBody(announcement?.body || "");
    announcementFormRef.current.setFieldValue("needUserAcceptance", announcement?.needUserAcceptance || false);
    announcementFormRef.current.setFieldValue("needUserNotification", announcement?.needUserNotification || false);
    announcementFormRef.current.setFieldValue("from", announcement?.from ? announcement.from.toUiDateFormat() : "");
    setFromDate(announcement?.from ? announcement.from.toUiDateFormat() : "");
    announcementFormRef.current.setFieldValue("to", announcement?.to ? announcement.to.toUiDateFormat() : "");
    setToDate(announcement?.to ? announcement.to.toUiDateFormat() : "");
    announcementFormRef.current.setFieldValue("status", announcement?.status || "");
    announcementFormRef.current.setFieldValue("recipientType", props.type);
  }, [announcement, props.type]);

  const showSendNotification = () => {
    if (!announcement || announcement.status !== "ACTIVE") return false;
    if (!announcement.from) return true;
    const currentDate = moment.utc(new Date().toUTCString());
    return currentDate.isAfter(moment.utc(announcement.from));
  };

  const sendNotification = () => {
    if (!announcement?.id) return;
    dispatch(notifyUsers(announcement.id, () => {
      toast.info(`Email notification to ${props.type === "CUSTOMER" ? "customers" : "employees"} has been scheduled!`);
    }));
  };

  const onSendTestEmail = (data: TestEmailModel) => {
    if (!data.email || !announcement?.id) return;
    dispatch(sendTestEmail(announcement.id, data.email, () => {
      toast.info("Test email notification sent!");
      setShowSendTestEmail(false);
    }));
  };

  useEffect(() => {
    dispatch(getAnnouncements((announcements: AnnouncementResponseModel[]) => {
      setAnnouncement(announcements.find(announcement => announcement.recipientType === props.type));
    }));

    return () => {
      dispatch(resetErrors());
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!announcementState.announcements || announcementState.announcements.length === 0) return;
    setAnnouncement(announcementState.announcements.find(announcement => announcement.recipientType === props.type));
  }, [announcementState.announcements, props.type]);

  useEffect(() => {
    prepopulateFields();
  }, [prepopulateFields]);

  const previewBlock = (formValues: AnnouncementModel) => (
    <div className="xgs-announcement__form__preview__area" key={props.type + "-form"}>
      <div className="xgs-announcement__form__preview__header">
        {formValues.header}
      </div>
      <div
        className="xgs-announcement__form__preview__body ql-editor"
        dangerouslySetInnerHTML={{__html: body}}>
      </div>
      <div className="xgs-announcement__form__preview__buttons">
        <Button
          theme={ButtonThemes.blue}
          onClick={() => {}}
          className="xgs-modal__button"
          type="button"
        >
          {formValues.needUserAcceptance ? "Accept" : "Ok"}
        </Button>
        {formValues.needUserAcceptance && (
          <Button
            theme={ButtonThemes.gray}
            onClick={() => {}}
            className="xgs-modal__button"
            type="button"
          >
            Log Out
          </Button>
        )}
      </div>
    </div>
  );

  return (
    <>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={AnnouncementSchema}
        innerRef={announcementFormRef}
      >
        {(props: FormikProps<AnnouncementModel>) => (
          <Form className="xgs-announcement__form">
            <div className="xgs-announcement__form__block xgs-announcement__form__controls">
              <div className="xgs-announcement__form__block__help">
                <Help header="Settings Help">
                  <div className="help__description">The meaning of the settings is described below to help you understand them.</div>
                  <div className="help__item"><strong>Display period</strong> - date range when you want to display the announcement to users. If left blank, it will be displayed immediately and until it is turned off or a date range is set.</div>
                  <div className="help__item"><strong>User acceptance required</strong> - set if you want the users to additionally confirm the acceptance of the announcement by clicking on the corresponding button.</div>
                  <div className="help__item"><strong>Notify by email</strong> - set if you want users to receive an email with the text of the announcement when it starts to be displayed to users.</div>
                  <div className="help__item"><strong>Resend Emails</strong> - the button that will appear after the announcement starts showing. It allows you to send the announcement by email, regardless of the "Notify by email" setting.</div>
                  <div className="help__item"><strong>Test Email</strong> - allows you to send a test email with a notification to a given address.</div>
                  <div className="help__item"><strong>Scheduled notification</strong> and <strong>Last notification</strong> - display the scheduled email notification and the last made email notification dates respectively.</div>
                </Help>
              </div>
              {!UserUtils.isXGSAdministrator(userState.profile) && !(announcementState.requestStarted && announcementState.requestCreator === "GET_DATA") && (
                <>
                  {!announcement?.id && (
                    <div className="xgs-announcement__form__notes">
                      <strong>There is no active announcement at the moment.</strong>
                    </div>
                  )}
                  <div className="xgs-announcement__form__notes">
                    If you want to publish {announcement?.id ? " new or edit the current" : " the"} announcement, you need to contact your administrator.
                  </div>
                </>
              )}
              {(UserUtils.isXGSAdministrator(userState.profile) || announcement?.id) && (
                <>
                  <div className="xgs-announcement__form__section">
                    <XGSFormInput
                      type="text"
                      name="header"
                      label="Title:"
                      required={true}
                      requiredAsteriskDisabled={false}
                      labelMode={LabelModes.column}
                      className="xgs-announcement__form__field"
                      disabled={!UserUtils.isXGSAdministrator(userState.profile)}
                    />
                    <div className="xgs-announcement__form__field">
                      <LabeledInput
                        label="Message:"
                        labelMode={LabelModes.column}
                        required={true}
                        requiredAsteriskDisabled={false}
                        isFailed={() => !!(props.touched.body && props.errors.body)}
                        error={props.errors.body || ""}
                        className="xgs-announcement__form__field__editor"
                      >
                        {UserUtils.isXGSAdministrator(userState.profile) && (
                          <ReactQuill
                            theme="snow"
                            value={body}
                            modules={quillModules}
                            onChange={(value, delta, source, editor) => {
                              const text = editor.getText().replace(/(\r\n|\n|\r)/gm, "");
                              if (text) {
                                setBody(value);
                                props.setFieldValue("body", value);
                              } else {
                                setBody("");
                                props.setFieldValue("body", "");
                              }
                              props.setFieldTouched("body", true, false);
                            }}
                            onBlur={() => {
                              props.setFieldTouched("body", true, false);
                            }}
                          />
                        )}
                        {!UserUtils.isXGSAdministrator(userState.profile) && (
                          <div
                            className="xgs-announcement__form__field__text"
                            dangerouslySetInnerHTML={{__html: body}}>
                          </div>
                        )}
                      </LabeledInput>
                    </div>
                    {announcement?.id && UserUtils.isXGSAdministrator(userState.profile) && (
                      <div className="xgs-announcement__form__notes xgs-announcement__form__notes--red">
                        Note: making changes to the content will reset user acceptance statuses!
                      </div>
                    )}
                  </div>
                  <div className="xgs-line"></div>
                  <div className="xgs-announcement__form__section">
                    <LabeledDateRangeInput
                      label="Display period:"
                      labelMode={LabelModes.column}
                      className="xgs-announcement__form__field"
                      start={from}
                      end={to}
                      minDate={new Date(moment().format("MM/DD/YYYY"))}
                      onStartChange={(value) => {
                        setFromDate(value);
                        props.setFieldValue("from", value);
                      }}
                      onEndChange={(value) => {
                        setToDate(value);
                        props.setFieldValue("to", value);
                      }}
                      disabled={!UserUtils.isXGSAdministrator(userState.profile)}
                    />
                    <div className="xgs-announcement__form__field xgs-announcement__form__field--toggle">
                      <XGSToggle
                        onChange={(checked) => props.setFieldValue("needUserAcceptance", checked)}
                        checked={props.values.needUserAcceptance}
                        disabled={
                          !UserUtils.isXGSAdministrator(userState.profile) ||
                          (announcementState.requestStarted && (
                            announcementState.requestCreator === "SAVE" ||
                            announcementState.requestCreator === "GET_DATA" ||
                            announcementState.requestCreator === "TURN_OFF"
                          ))
                        }
                        label="User acceptance required"
                      />
                    </div>
                    <div className="xgs-announcement__form__field xgs-announcement__form__field--toggle">
                      <XGSToggle
                        onChange={(checked) => props.setFieldValue("needUserNotification", checked)}
                        checked={props.values.needUserNotification}
                        disabled={
                          !UserUtils.isXGSAdministrator(userState.profile) ||
                          showSendNotification() ||
                          (announcementState.requestStarted && (
                            announcementState.requestCreator === "SAVE" ||
                            announcementState.requestCreator === "GET_DATA" ||
                            announcementState.requestCreator === "TURN_OFF"
                          ))
                        }
                        label="Notify by email"
                      />
                    </div>
                    <div className="xgs-announcement__form__buttons">
                      {UserUtils.isXGSAdministrator(userState.profile) && showSendNotification() && (
                        <Button
                          theme={ButtonThemes.blueInverted}
                          className="xgs-announcement__form__button xgs-announcement__form__button--50"
                          spinner={announcementState.requestStarted && announcementState.requestCreator === "NOTIFY"}
                          onClick={sendNotification}
                          type="button"
                        >
                          Resend Emails
                        </Button>
                      )}
                      {UserUtils.isXGSAdministrator(userState.profile) && announcement?.id && (
                        <Button
                          theme={ButtonThemes.blueInverted}
                          className="xgs-announcement__form__button xgs-announcement__form__button--50"
                          onClick={() => setShowSendTestEmail(true)}
                          type="button"
                        >
                          Test Email
                        </Button>
                      )}
                    </div>
                    {announcement && (
                      <div>
                        {announcement.lastNotificationDate && (
                          <div className="xgs-announcement__form__value-pair">
                            <div className="xgs-announcement__form__value-pair__label">Last notification:</div>
                            <div className="xgs-announcement__form__value-pair__value">{announcement.lastNotificationDate.toUiDateFormat()}</div>
                          </div>
                        )}
                        {announcement.nextNotificationDate && (
                          <div className="xgs-announcement__form__value-pair">
                            <div className="xgs-announcement__form__value-pair__label">Scheduled notification:</div>
                            <div className="xgs-announcement__form__value-pair__value">{announcement.nextNotificationDate.toUiDateFormat()}</div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </>
              )}
              {announcementState.requestFailed && (
                <XGSErrorMessage className="xgs-announcement__form__error">{announcementState.requestError}</XGSErrorMessage>
              )}
              {UserUtils.isXGSAdministrator(userState.profile) && (
                <div className="xgs-announcement__form__buttons xgs-announcement__form__buttons--main">
                  <Button
                    theme={ButtonThemes.blue}
                    className="xgs-announcement__form__button xgs-announcement__form__button--save"
                    disabled={!valuesWereChanged(props.values) || !props.isValid || !props.dirty || (announcementState.requestStarted && announcementState.requestCreator === "TURN_OFF")}
                    spinner={announcementState.requestStarted && announcementState.requestCreator === "SAVE" && !turnOffConfirmOpen}
                  >
                    {announcement?.id ? "Save changes" : "Publish"}
                  </Button>
                  <Button
                    theme={ButtonThemes.gray}
                    className="xgs-announcement__form__button"
                    onClick={prepopulateFields}
                    disabled={
                      !valuesWereChanged(props.values) ||
                      (announcementState.requestStarted &&
                        (announcementState.requestCreator === "SAVE" ||
                          announcementState.requestCreator === "GET_DATA" ||
                          announcementState.requestCreator === "TURN_OFF"
                        )
                      )
                    }
                    type="button"
                  >
                    Discard changes
                  </Button>
                  {announcement?.id && (
                    <Button
                      theme={ButtonThemes.redInverted}
                      className="xgs-announcement__form__button xgs-announcement__form__button--delete"
                      onClick={() => setTurnOffConfirmOpen(true)}
                      type="button"
                      disabled={announcementState.requestStarted && (announcementState.requestCreator === "SAVE" || announcementState.requestCreator === "GET_DATA")}
                    >
                      Turn off
                    </Button>
                  )}
                </div>
              )}
            </div>
            <div className="xgs-announcement__form__block xgs-announcement__form__preview">
              {(props.values.header || body) && UserUtils.isXGSAdministrator(userState.profile) && (
                <>
                  {announcement?.status && (
                    <Tag
                      mods={{color: announcement.status === "ACTIVE" ? TagColor.GREEN : (announcement.status === "PENDING" ? TagColor.BLUE : TagColor.GREY)}}
                      mix="xgs-announcement__form__block__status"
                    >
                      {announcement.status}
                    </Tag>
                  )}
                  {previewBlock(props.values)}
                </>
              )}
            </div>
          </Form>
        )}
      </Formik>
      <Modal
        isOpen={showSendTestEmail}
        style={modalStyle}
      >
        <div className="xgs-pending-requests__details__modal">
          <div className="xgs-modal__header">
            Send test email
          </div>
          <Formik
            onSubmit={onSendTestEmail}
            initialValues={testEmailInitialValues}
            validationSchema={TestEmailSchema}
            validateOnMount
            enableReinitialize
          >
            {(props: FormikProps<TestEmailModel>) => (
              <Form>
                <div>
                  <div className="xgs-modal__content" style={{ marginBottom: 48 }}>
                    <XGSFormInput
                      type="text"
                      name="email"
                      label="Email:"
                      required={true}
                      requiredAsteriskDisabled={false}
                      labelMode={LabelModes.column}
                    />
                  </div>
                  <div className="xgs-modal__buttons" style={{ textAlign: "center" }}>
                    <Button
                      theme={ButtonThemes.gray}
                      onClick={() => {
                        setShowSendTestEmail(false);
                      }}
                      className="xgs-modal__button"
                      type="button"
                    >
                      Cancel
                    </Button>
                    <Button
                      theme={ButtonThemes.blue}
                      spinner={announcementState.requestStarted && announcementState.requestCreator === "TEST_EMAIL"}
                      className="xgs-modal__button"
                      type="submit"
                      disabled={!props.isValid || !props.dirty}
                    >
                      Send
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
      <ConfirmationModal
        opened={turnOffConfirmOpen}
        header="Turn off announcement"
        confirmButtonText="Turn Off"
        spinner={announcementState.requestStarted && announcementState.requestCreator === "SAVE"}
        onCancel={() => setTurnOffConfirmOpen(false)}
        onConfirm={() => onCompleteAnnouncement()}
      >
        This announcement will be turned off as completed.<br />
        It cannot be restored after this action.
      </ConfirmationModal>
    </>
  );
};

export default AnnouncementForm;

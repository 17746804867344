import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import SubAccount from "../../app/data/account/SubAccount";
import { Routes } from "../../app/route/RoutesConfig";
import DropdownPopup from "../../ui-components/dropdown-popup/dropdownPopup";
import XGSIcon from "../../ui-components/icon/xgsIcon";
import XGSInput from "../../ui-components/xgs-input/xgsInput";
import UserState from "../../slices/user/UserState";
import {
  closeMobileSideMenu,
  resetAllStatesExceptUser,
  setActiveSubAccount,
  userSelector
} from "../../slices/user/userSlice";
import "./subAccountSelector.scss";

const SubAccountSelector: React.FC = () => {
  const userState: UserState = useSelector(userSelector);
  const [opened, setOpened] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>("");
  const dispatch = useDispatch();
  const history = useHistory();

  const accountItemClicked = (account: SubAccount) => {
    if (account.id !== userState.activeSubAccount?.id) {
      closeDropdown();
      dispatch(closeMobileSideMenu());
      dispatch(setActiveSubAccount(account));
      history.push(`/${account.accountNumber}${Routes.home}`);
      dispatch(resetAllStatesExceptUser());
    }
  };

  const getAccountsList = () => {
    if (userState.profile?.subAccounts) {
      return userState.profile.subAccounts
        .filter(account => filter === ""
          || account.name.toLowerCase().includes(filter.toLowerCase())
          || account.address.city.toLowerCase().includes(filter.toLowerCase())
          || account.address.state.toLowerCase().includes(filter.toLowerCase())
          || `${account.accountNumber}`.includes(filter))
        .map((account, i) =>
        account.id !== userState.activeSubAccount?.id && (
          <div
            className="xgs-header-subaccounts__item"
            key={`sub-account-selector-${i}`}
            onClick={() => accountItemClicked(account)}
          >
            <div className="xgs-header-subaccounts__item__block">
              <div className="xgs-header-subaccounts__item__icons">
                {account.warehouseSupport && ( 
                  <XGSIcon
                    icon={Icons.faBox}
                    className="xgs-header-subaccounts__item__icon"
                  />
                )}
                {account.paypoint && (
                  <XGSIcon
                    icon={Icons.faDollarSign}
                    className="xgs-header-subaccounts__item__icon"
                  />
                )}
              </div>
              <span className="xgs-header-subaccounts__item__name">
                {account.name}&nbsp;
                {!(account.address.city === "-" && account.address.state === "-") && (
                  <>
                    -&nbsp;{account.address.city && (account.address.city !== "-") && (
                        <span className="xgs-header-subaccounts__item__city">
                          {account.address.city.toLowerCase()},
                        </span>
                      )}&#32;{account.address.state && (account.address.state !== "-") ? account.address.state : ""}
                  </>
                )}
              </span>
            </div>
            <span className="xgs-header-subaccounts__item__number">
              {account.accountNumber}
            </span>
          </div>
        )
      );
    }
  };

  const showDropDown = (): boolean => {
    if (userState?.profile) {
      return userState.profile.subAccounts?.length > 1;
    }
    return false;
  }

  const openDropdown = () => {
    if (!showDropDown()) return;
    setFilter("");
    setOpened(true);
    showDropDown();
    setTimeout(() => {
      document.getElementById("xgs-header-subaccounts-filter--js")?.focus();
    }, 200);
  };

  const closeDropdown = () => {
    setOpened(false);
  };

  const needFilter = () => {
    return userState.profile?.subAccounts && (userState.profile.subAccounts.length > 15);
  }

  return (
    <>
      {userState.loggedIn && (
        <div className="xgs-header-subaccounts">
          <div
            className={`xgs-header-subaccounts__control ${showDropDown() ? "clickable" : ""}`}
            onClick={openDropdown}
          >
            <div className="xgs-header-subaccounts__control__title">
              {userState.activeSubAccount?.name} 
              <div className="xgs-header-subaccounts__control__location">
                {userState.activeSubAccount?.address.city && (userState.activeSubAccount?.address.city !== "-") && (
                  <span className="xgs-header-subaccounts__item__city">
                    {userState.activeSubAccount.address.city.toLowerCase()},
                  </span>
                )}&#32;{userState.activeSubAccount?.address.state && (userState.activeSubAccount?.address.state !== "-") ? userState.activeSubAccount.address.state : ""}
              </div>
            </div>
            {showDropDown() && (
              <div className="xgs-header-subaccounts__control__arrow">
                <XGSIcon
                  icon={opened ? Icons.faChevronUp : Icons.faChevronDown}
                />
              </div>
            )}
          </div>
          <DropdownPopup opened={opened} onClose={closeDropdown}>
            <span className="title">Switch account</span>
            {needFilter() && (
              <div className="xgs-header-subaccounts__filter">
                <XGSInput
                  type="text"
                  name="filter"
                  value={filter}
                  placeholder="Search by name, location or number..."
                  onChange={(e) => setFilter(e.currentTarget.value)}
                  clearable
                  onClear={() => setFilter("")}
                  id="xgs-header-subaccounts-filter--js"
                />
              </div>
            )}
            <div
              className="xgs-header-subaccounts__items"
              style={{ maxHeight: needFilter() ? "calc(50vh - 126px)" : "calc(50vh - 70px)" }}>
              {getAccountsList()}
            </div>
          </DropdownPopup>
        </div>
      )}
    </>
  );
};

export default SubAccountSelector;

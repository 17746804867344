import React, { memo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ContentContainer from "../../../templates/content-container/contentContainer";
import ContentContainerToolbar from "../../../ui-components/molecules/content-container-toolbar/contentContainerToolbar";
import "./picklists.scss";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import Loading from "../../../ui-components/loading/loading";
import { ActivePicklistsState } from "../../../slices/dispatch-planning/picklists/picklistsState";
import { activePicklistsSelector, getActivePicklists } from "../../../slices/dispatch-planning/picklists/activePicklistsSlice";
import { getPicklistDetails } from "../../../slices/dispatch-planning/picklists/picklistDetailsSlice";
import PicklistDetails from "./picklist-details/picklistDetails";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import UserState from "../../../slices/user/UserState";
import { userSelector } from "../../../slices/user/userSlice";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";

const Picklists: React.FC<{}> = memo((props) => {
    const dispatch = useDispatch();
    const [terminal, setTerminal] = useState<number | null | undefined>(0);
    const [selectedPicklistId, setSelectedPicklistId] = useState<number>();

    const activePicklistsState: ActivePicklistsState = useSelector(
        activePicklistsSelector
    );
    const userState: UserState = useSelector(
        userSelector
    );
    useEffect(() => {
        const userTerminal = userState.activeTerminal?.id;
        onTerminalChanged(userTerminal);
        // eslint-disable-next-line
    }, [userState.activeTerminal]);
    const onTerminalChanged = (userTerminal: number | null | undefined) => {
        setTerminal(userTerminal);
        setSelectedPicklistId(0);
        if (!userTerminal) return; // can not get the Probills without knowing the terminal
        dispatch(getActivePicklists({ terminalNumber: userTerminal }));
    };
    const getFormattedDate = (date: number) => {
        let dateStr = `${date}`;
        return `${dateStr.slice(4, 6)}/${dateStr.slice(6)}/${dateStr.slice(0, 4)}`
    }
    const getFormattedTime = (time: number) => {
        let timeStr = `000000${time}`.slice(-6);
        let hour = +timeStr.slice(0, 2);
        if (hour < 12) return `${timeStr.slice(0, 2)}:${timeStr.slice(2, 4)} am`;
        else return `${timeStr.slice(0, 2)}:${timeStr.slice(2, 4)} pm`;
    }
    const loadPicklistDetails = (picklistNumber: any) => {
        setSelectedPicklistId(picklistNumber);
        dispatch(getPicklistDetails({ picklistNumber }));
    }
    const refreshPicklists = () => {
        if (!terminal) return;
        setSelectedPicklistId(0);
        dispatch(getActivePicklists({ terminalNumber: terminal }));
    }
   
    return (
        <ContentContainer
        titleComponent={
            <ContentContainerToolbar
                title="Picklists"
            >
            </ContentContainerToolbar>
        }
        >
            <div
                className="picklists-header"
            >
                <Button
                    theme={ButtonThemes.blue}
                    className="picklists-refresh-button"
                    disabled={(!terminal)}
                    onClick={refreshPicklists}>
                    Refresh Picklists
                </Button>
            </div>
            {(!terminal) &&
                <XGSErrorMessage>
                    <div>
                        You do not have assigned terminals.
                        Email <a className="white-link" href="mailto:helpdesk@xgsi.com">helpdesk@xgsi.com</a> to request terminal assignment for your account.
                    </div>
                </XGSErrorMessage>
            }
            {(terminal) &&
                <>
                    {activePicklistsState.loading && <div><Loading isLoading={true} /> Loading Picklists...</div>}
                    {activePicklistsState.loadingFailed && <div>Error in getting data</div>}
                    {(activePicklistsState.loaded && activePicklistsState.activePicklists && activePicklistsState.activePicklists.length) ?
                        <div className="picklists-container">
                            <div className="picklists-container-sub picklists-list-container">
                                {activePicklistsState.activePicklists.map((picklistObj, picklistIdx) => <div
                                    key={`${picklistObj.picklistNumber}_${picklistIdx}`}
                                    className={`picklist-box${(picklistObj.picklistNumber === selectedPicklistId) ? " selected-picklist" : ""}`}
                                    onClick={() => { loadPicklistDetails(picklistObj.picklistNumber) }}
                                >
                                    <div className="picklist-box-header">
                                        <div className="picklist-box-id">{picklistObj.picklistNumber}</div>
                                        <div>
                                            <span
                                                className="picklist-box-icon"
                                                title="Driver Name"
                                            >
                                                <XGSIcon
                                                    icon={XGSIcons.faUser} />
                                            </span>
                                            {(picklistObj.driverName) ? picklistObj.driverName : <span className="picklist-box-driver-not-assigned">Not Assigned</span>}
                                        </div>
                                    </div>
                                    <div className="picklist-box-header">
                                        <div
                                            className="picklist-box-trailer"
                                            title="Trailer Number"
                                        >
                                            <span className="picklist-box-icon">
                                                <XGSIcon
                                                    icon={XGSIcons.faTruck} />
                                            </span>
                                            {picklistObj.trailerNumber}</div>
                                        <div
                                            className="picklist-box-stops-count"
                                            title="Number of Stops"
                                        >
                                            <span className="picklist-box-icon">
                                                <XGSIcon
                                                    icon={XGSIcons.faMapPin} />
                                            </span>{picklistObj.stopCount}</div>
                                    </div>
                                    <div
                                        className="picklist-box-header"
                                    >
                                        <div
                                            title="Total Weight"
                                        >
                                            <span className="picklist-box-icon">
                                                <XGSIcon
                                                    icon={XGSIcons.faBalanceScale} />
                                            </span>
                                            {picklistObj.totalWeight.toLocaleString('en', { useGrouping: true })}<span className="picklist-box-weight-unit">{`(lbs)`}</span></div>
                                        <div
                                            title="Total Items"

                                        >
                                            <span className="picklist-box-icon">
                                                <XGSIcon
                                                    icon={XGSIcons.faCartArrowDown} />
                                            </span>{picklistObj.totalPieces}</div>
                                    </div>
                                    <div className="picklist-box-picklist-window">
                                        <div
                                            title="Last Action Time"
                                        >
                                            <span className="picklist-box-icon">
                                                <XGSIcon
                                                    icon={XGSIcons.faClock} />
                                            </span>
                                        </div>
                                        <div className="picklist-box-ts">
                                            <div className="picklist-box-date">{getFormattedDate(+picklistObj.createdAt.slice(0, 8))}</div>
                                            <div className="picklist-box-time">{getFormattedTime(+picklistObj.createdAt.slice(8))}</div>
                                        </div>
                                    </div>
                                </div>)}

                            </div>
                            <div
                                className="picklists-details-container"
                            >
                                {(!selectedPicklistId) ?
                                    <div>Please select a Picklist to view Details</div>
                                    :
                                    <PicklistDetails />
                                }
                            </div>

                        </div>
                        : ""}
                    {(activePicklistsState.loaded && activePicklistsState.activePicklists && !activePicklistsState.activePicklists.length) ? 
                    "No Picklist Available": ""    
                }
                </>
            }
        </ContentContainer>
    );
});

export default Picklists;
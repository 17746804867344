import { IState } from "../..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../../app/store";
import CustomersService from "../../../app/data/customers/customersService";
import { UpdateTeamModel } from "../../../app/data/customers/models";
import { initialTeamAccountState } from "./TeamAccountState";

const customersService = CustomersService.getInstance();

export const teamAccountSlice = createSlice({
  name: "teamAccount",
  initialState: initialTeamAccountState,
  reducers: {
    team_request_was_started: (state) => {
      state.request_was_started = true;
      state.request_was_succeed = false;
      state.request_was_failed = false;
      state.request_fail_reason = null;
    },
    team_request_was_succeed: (state) => {
      state.request_was_succeed = true;
      state.request_was_started = false;
      state.request_was_failed = false;
    },
    team_request_was_failed: (state, { payload }) => {
      state.request_was_failed = true;
      state.request_was_succeed = false;
      state.request_was_started = false;
      state.request_fail_reason = payload;
    },
    resetErrors: (state) => {
      state.request_was_failed = false;
      state.request_fail_reason = null;
    }
  }
});

export const {
  team_request_was_started,
  team_request_was_succeed,
  team_request_was_failed,
  resetErrors
} = teamAccountSlice.actions;

export const teamAccountSelector = (state: IState) => state.teamAccount;

export const updateTeam = (
  updateTeamData: UpdateTeamModel,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(team_request_was_started());
  const response = await customersService.updateTeam(updateTeamData);
  if (response.ok()) {
    dispatch(team_request_was_succeed());
    onSuccess && onSuccess();
  } else {
    response.getError && dispatch(team_request_was_failed(response.getError()));
  }
};

export const removeAccountFromTeam = (
  teamId: string,
  accountId: string,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(team_request_was_started());
  const response = await customersService.removeAccountFromTeam(teamId, accountId);
  if (response.ok()) {
    dispatch(team_request_was_succeed());
    onSuccess && onSuccess();
  } else {
    response.getError && dispatch(team_request_was_failed(response.getError()));
  }
};

export const searchTeamAccounts = (
  query: string,
  teamId: string,
  onSuccess: (accounts: any) => void
): AppThunk => async (dispatch) => {
  dispatch(team_request_was_started());
  const response = teamId ? await customersService.searchTeamAccounts(teamId, query) : await customersService.searchCurrentTeamAccounts(query);
  if (response.ok()) {
    dispatch(team_request_was_succeed());
    onSuccess(response.data);
  } else {
    response.getError && dispatch(team_request_was_failed(response.getError()));
  }
};

const teamAccountReducer = teamAccountSlice.reducer;
export default teamAccountReducer;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import DropdownPopup from "../dropdown-popup/dropdownPopup";
import { cn } from "../../services/common/className";
import threeDots from "../../images/dots-horizontal_blue.svg";
import "./threeDotsMenu.scss";
import "../../sass/common.scss";

export interface DropdownMenuItem {
  text: string;
  to?: string;
  icon?: React.ReactNode;
  onClick?: (params?: any | undefined) => void;
}

export interface ThreeDotsMenuProps {
  menuItems: DropdownMenuItem[];
}

const MenuItemWrapper: React.FC<any> = ({ to, children }) => (
  to
  ? <Link to={to}>{children}</Link>
  : children
);

const ThreeDotsMenu: React.FC<ThreeDotsMenuProps> = (props) => {
  const [menuOpened, setMenuOpened] = useState(false);

  return (
    <>
      <div
        className="xgs-three-dots-menu__activator"
        onClick={() => setMenuOpened(true)}
      >
        <img src={threeDots} alt="menu" />
      </div>
      <DropdownPopup
        opened={menuOpened}
        onClose={() => setMenuOpened(false)}
        className="xgs-three-dots-menu"
        position="bottom right"
      >
        {props.menuItems.map((menuItem) => (
          <React.Fragment key={`xgs-three-dots-menu-${menuItem.text}`}>
            <MenuItemWrapper to={menuItem.to}>
              <div
                className={"no-select " + cn("xgs-three-dots-menu__item")({ inactive: !menuItem.onClick })}
                onClick={() => {
                  if (menuItem.onClick) {
                    menuItem.onClick();
                    setMenuOpened(false);
                  }
                }}
              >
                <div className="xgs-three-dots-menu__icon">
                  {menuItem.icon}
                </div>
                <div className="xgs-three-dots-menu__text">
                  {menuItem.text}
                </div>
              </div>
            </MenuItemWrapper>
          </React.Fragment>
        ))}
      </DropdownPopup>
    </>
  );
};

export default ThreeDotsMenu;

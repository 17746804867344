import uniqueId from "lodash/uniqueId";

import ManifestService from "../../app/data/manifest/manifestsService"
import { AppThunk } from '../../app/store'
import { createSlice } from '@reduxjs/toolkit'
import { IState } from '../index'
import ManifestsState, { initialManifestsState } from './ManifestsState'
import { StopsLocationInfo, ProbillRaw } from "../../app/data/manifest/manifestProfiles"
import { ManifestsRequest } from "../../app/data/manifest/requestModels";
import { PickupStatus } from "../../app/data/common/route";

export const manifestsSlice = createSlice({
  name: 'manifests',
  initialState: initialManifestsState,
  reducers: {
    fetch_was_started: (state: ManifestsState, { payload }) => {
      state.requestCreator = payload.creator

      if (payload.creator === "GET_MANIFESTS") {
        if (payload.infinite) state.fetchPortion_was_started = true
        else {
          state.fetch_was_started = true
          state.manifestsRequest = null
        }
        state.manifestsFetchedAll = false
      } else state.fetch_was_started = true

      state.fetch_was_succeed = false
      state.fetch_was_failed = false
      state.fetch_fail_reason = null
    },
    fetch_was_succeed: (state: ManifestsState) => {
      state.fetch_was_succeed = true
      state.fetch_was_started = false
      state.fetchPortion_was_started = false
      state.fetch_was_failed = false
    },
    set_manifests_status: (state: ManifestsState, { payload }) => {
      state.manifestsStatuses = Object.values(payload.manifestsStatus)
      if (payload.length) state.manifestsFetchedAll = true
    },
    set_manifests_stops: (state: ManifestsState, { payload }) => {
      state.manifestsStops = payload.stopsStatus
    },
    set_manifests_probills: (state: ManifestsState, { payload }) => {
      state.manifestsProbills = payload.manifestProbills
    },
    set_manifests_pickups: (state: ManifestsState, { payload }) => {
      const manifestsPickups = payload.pickupRequest
        ? (Object.entries(payload.pickupRequest) as [string, { pickupStatus: PickupStatus }[]][])
          .reduce((result, [key, value]) => ({ ...result, [key]: value.filter(obj => obj.pickupStatus !== PickupStatus.CANCELED) }), {})
        : {};

      state.manifestsPickups = manifestsPickups;
    },
    set_manifest_map_data: (state: ManifestsState, { payload }) => {
      state.manifestMapData = payload
    },
    reset_manifest_map_data: (state: ManifestsState,) => {
      state.manifestMapData = initialManifestsState.manifestMapData;
      state.map_fetch_was_failed = initialManifestsState.map_fetch_was_failed;
      state.map_fetch_fail_reason = initialManifestsState.map_fetch_fail_reason;
      state.map_fetch_was_succeed = initialManifestsState.map_fetch_was_succeed;
    },
    set_manifest_exceptions_data: (state: ManifestsState, { payload }) => {
      state.manifestExceptionsData = Object.values(payload)
    },
    fetch_was_failed: (state: ManifestsState, { payload }) => {
      state.fetch_was_failed = true;
      state.fetch_was_succeed = false;
      state.fetch_was_started = false;
      state.fetch_fail_reason = payload;
    },
    map_fetch_was_failed: (state: ManifestsState, { payload }) => {
      state.map_fetch_was_failed = true;
      state.map_fetch_was_succeed = false;
      state.map_fetch_was_started = false;
      state.map_fetch_fail_reason = payload;
    },
    resetErrors: (state: ManifestsState) => {
      state.fetch_was_failed = false;
      state.fetch_fail_reason = null;
      state.update_was_failed = false;
      state.update_fail_reason = null;
    },
    resetManifests: (state: ManifestsState) => {
      state.manifestsStatuses = []
      state.manifestsStops = {}
      state.manifestsProbills = {}
      state.manifestsPickups = {}
      state.manifestsRequest = null
    },
    loadingCSVStarted: (state) => {
      state.loadingCSVStarted = true;
    },
    loadingCSVCompleted: (state) => {
      state.loadingCSVStarted = false;
    },
    setActiveProbill: (state, {payload}) => {
      state.activeProbill = payload;
    }
  }
})

export const {
  fetch_was_failed,
  map_fetch_was_failed,
  fetch_was_started,
  fetch_was_succeed,
  set_manifests_status,
  set_manifests_stops,
  set_manifests_probills,
  set_manifests_pickups,
  set_manifest_map_data,
  reset_manifest_map_data,
  set_manifest_exceptions_data,
  resetErrors,
  resetManifests,
  loadingCSVStarted,
  loadingCSVCompleted,
  setActiveProbill,
} = manifestsSlice.actions

export const manifestsSelector = (state: IState) => state.manifests

export const stopsInfoSelector = (state: IState, manifestNumber?: string): StopsLocationInfo | null => {
  if (!manifestNumber) return null;

  const manifestsState = manifestsSelector(state);

  const stops = manifestsState.manifestsStops[manifestNumber];
  const probills = manifestsState.manifestsProbills[+manifestNumber];

  const getConsignees = (key: number) => (
    Object.values(probills)
      .filter(probill => probill.stopNumber === key)
      .reduce((result: ProbillRaw[], probill: ProbillRaw) => {
        return !!result.length && result.find(item => item.conNumber === probill.conNumber)
          ? result : [...result, probill]
      }, [])
      .map(probill => probill.consName)
      .join(' / ')
  );

  const info = Object.entries(stops).reduce((result, [key, value]) => ({
    ...result,
    [key]: { 
      address: value.address,
      consignees: getConsignees(+key),
     },
  }), {});

  return info;
};

const manifestService = ManifestService.getInstance();

export const getManifests = (
  request: ManifestsRequest
): AppThunk => async (dispatch) => {
  dispatch(fetch_was_started({
    creator: "GET_MANIFESTS",
  }));

  let response = await manifestService.getAllManifests(request);

  switch(true) {
    case (response.ok() && !!response.data.result): {
      dispatch(set_manifests_status(response.data));
      dispatch(set_manifests_stops(response.data));
      dispatch(set_manifests_probills(response.data));
      dispatch(set_manifests_pickups(response.data));
      dispatch(fetch_was_succeed());
      return;
    }

    case (response.ok() && !response.data.result): {
      dispatch(resetManifests());
      dispatch(fetch_was_succeed());
      return;
    }    

    case (!!response.getError?.()): { 
      dispatch(resetManifests());
      dispatch(fetch_was_failed(response.getError?.()));
      return;
    }
  }
};

export const getManifestMapData = (
  manifestNumber: number|string,
): AppThunk => async (dispatch) => {
  dispatch(reset_manifest_map_data())

  let response = await manifestService.getManifestMapData(manifestNumber)

  if (response.ok() && response.data.result && response.data.results) {
    const { markers, ...rest } = response.data.results;

    const result = {
      ...rest,
      markers: markers.map(({lat, lng, ...rest}: {lat: string, lng: string}) => ({ id: uniqueId(), lat: parseFloat(lat), lng: parseFloat(lng), ...rest })),
    };

    dispatch(set_manifest_map_data(result))
    dispatch(fetch_was_succeed())
  } else {
    response.getError && dispatch(map_fetch_was_failed(response.getError()))
  }
}

export const getExceptionsData = (
  manifestNumber: number|string,
  probillNumber?: number|string,
): AppThunk => async (dispatch) => {
  dispatch(fetch_was_started({
    creator: "GET_EXCEPTIONS",
  }));

  let response = await ManifestService.getExceptionsInfo(manifestNumber, probillNumber)

  if (response.ok() && response.data.result && response.data.results) {
    dispatch(set_manifest_exceptions_data(response.data.results))
    dispatch(fetch_was_succeed())
  } else {
    response.getError && dispatch(fetch_was_failed(response.getError()))
  }
}

export const exportManifestsCSV = (
  request: ManifestsRequest,
  onSuccess: (response: any) => void,
  onError: () => void,
): AppThunk => async (dispatch) => {
  dispatch(loadingCSVStarted());

  let response = await manifestService.exportManifestsCSV(request)

  if (response.ok() && response.data) {    
    onSuccess(response.data);
    dispatch(loadingCSVCompleted());
  } else {
    onError();
    dispatch(loadingCSVCompleted());
  }
}

export const manifestsReducer = manifestsSlice.reducer

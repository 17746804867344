import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Formik, FormikProps, Field } from "formik";
import moment from "moment";
import debounce from "lodash/debounce";
import ContentContainer from "../../templates/content-container/contentContainer";
import LabeledSelectInput from "../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import XGSFormDate from "../../ui-components/form/date/xgsFormDate";
import { LabelModes } from "../../ui-components/molecules/labeled-inputs/labeledInput";
import Button, { ButtonThemes } from "../../ui-components/button/button";
import XGSRegularIcons from "../../ui-components/icon/xgsRegularIcons";
import XGSIcon from "../../ui-components/icon/xgsIcon";
import ConfirmationModal from "../../ui-components/confirmation-modal/confirmationModal";
import { weekDays } from "../../ui-components/xgs-date/xgs-date/xgsDate";
import { resetCommonState } from "../../slices/common/commonSlice";
import RequestRatesState from "../../slices/rates/RequestRatesState";
import {
  addShippingItem,
  clearShippingItems,
  clearDeletedShippingItems,
  deleteShippingItem,
  resetRequestRatesState,
  requestRatesSelector,
  searchLocations,
  setDestinationLocation,
  setPickupLocation,
  setServices,
  updateShippingItem
} from "../../slices/rates/requestRatesSlice";
import {
  QuickRequestRatesModel,
  QuickRequestRatesSchema
} from "../../app/data/rates/models";
import { services } from "./constants";
import { getFreightClasses } from "../../slices/common/commonSlice";
import CommonState from "../../slices/common/CommonState";
import {
  commonSelector,
  getPickupDays
} from "../../slices/common/commonSlice";
import ShippingItemForm from "./steps/shippingItemForm";
import ResultPreview from "./steps/resultPreview";
import { nextBusinessDay } from "../../hooks/utils";
import "./requestRates.scss";

const initialValues: QuickRequestRatesModel = {
  pickupLocation: null,
  destinationLocation: null,
  tenderDate: "",
  specialServices: []
};

const QuickRequestRates: React.FC<{}> = (props) => {
  const dispatch = useDispatch();
  const requestRatesState: RequestRatesState = useSelector(requestRatesSelector);
  const commonState: CommonState = useSelector(commonSelector);
  const [pickupLocationFormValue, setPickupLocationFormValue] = useState<{ value: string, label: string, subtitle?: string } | null>();
  const [tenderDateFormValue, setTenderDateFormValue] = useState<string | undefined>();
  const [destinationLocationFormValue, setDestinationLocationFormValue] = useState<{ value: string, label: string, subtitle?: string } | null>();
  const [showAddShippingItemForm, setAddShippingItemForm] = useState<boolean>(false);
  const [removeItemIndex, setRemoveItemIndex] = useState<number | undefined>(undefined);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState<boolean>(false);
  const [editShippingItemIndex, setEditShippingItemIndex] = useState<number | undefined>(undefined);
  const [showResults, setShowResults] = useState<boolean>(false);
  const [showServicesHelp, setShowServicesHelp] = useState<boolean>(false);
  const [directPoint, setDirectPoint] = useState<boolean>(true);
  const requestFormRef = useRef<any>(null);

  let locationSearch = (type: string, e: string) => {
    e?.length > 1 && dispatch(searchLocations(type, e));
  }
  locationSearch = debounce(locationSearch, 300);

  const isDisabledService = (fieldValue: string, currentValues: string[]) => {
    return (fieldValue === "INSIDE_DELIVERY" && currentValues.find(service => service === "RESIDENTIAL_AREA"))
      || (fieldValue === "RESIDENTIAL_AREA" && currentValues.find(service => service === "INSIDE_DELIVERY"))
      || fieldValue === "HAZMAT";
  };

  const getClassLabel = (freightClassValue: string) => {
    let freightClass = commonState.freightClasses.find(obj => obj.value === freightClassValue);
    return freightClass ? freightClass.label : freightClassValue;
  };

  const nextServiceCenterWorkDay = () => {
    if (!commonState.pickupDays || Object.keys(commonState.pickupDays).length === 0) return nextBusinessDay();
    let day = moment().add(1, "day"); // default value to avoid TS error (possible undefined)
    for (let i = 1; i < 8; i++) {
      day = moment().add(i, "day");
      let dayOfWeek = day.format("dd").toLowerCase();
      if (commonState.pickupDays[dayOfWeek as keyof weekDays] === true) break;
    }
    return day.format("MM/DD/YYYY");
  };

  const onSubmit = (data: QuickRequestRatesModel) => {
    dispatch(setPickupLocation({
      location: data.pickupLocation,
      date: data.tenderDate
    }));
    dispatch(setDestinationLocation({
      location: data.destinationLocation
    }));
    dispatch(setServices(data.specialServices));
    dispatch(clearDeletedShippingItems());
    setShowResults(true);
  };

  const onBackFromResults = () => {
    setShowResults(false);
    setTimeout(() => {
      requestFormRef.current?.setFieldValue("pickupLocation", requestRatesState.pickupLocation);
      requestFormRef.current?.setFieldValue("tenderDate", requestRatesState.pickupDate);
      requestFormRef.current?.setFieldValue("destinationLocation", requestRatesState.destinationLocation);
      requestFormRef.current?.setFieldValue("specialServices", requestRatesState.services);
    }, 100);
  };

  const onStartOver = () => {
    setShowResults(false);
    setPickupLocationFormValue(null);
    setTenderDateFormValue(undefined);
    setDestinationLocationFormValue(null);
    setDirectPoint(true);
  };

  useEffect(() => {
    dispatch(resetRequestRatesState());
    dispatch(resetCommonState());
  }, [dispatch]);

  return (
    <ContentContainer
      title="Request Rates"
      header=""
    >
      <div className={`xgs-request-rates ${!showResults ? "xgs-request-rates__quick" : ""}`}>
        {!showResults && (
          <>
            <Formik
              onSubmit={onSubmit}
              initialValues={initialValues}
              validationSchema={QuickRequestRatesSchema}
              innerRef={requestFormRef}
              enableReinitialize
            >
              {(props: FormikProps<QuickRequestRatesModel>) => (
                <Form>
                  <div className="xgs-request-rates__quick__section-header">Pickup</div>
                  <div className="xgs-request-rates__quick__section">
                    <div className="xgs-request-rates__quick__row">
                      <div>
                        <LabeledSelectInput
                          className="xgs-request-rates__location-field"
                          isDisabled={false}
                          isLoading={requestRatesState.requestStarted && requestRatesState.requestCreator === "PICKUP_LOOKUP"}
                          label="Location:"
                          labelMode={LabelModes.column}
                          name="pickupLocation"
                          onInputChange={(e) => locationSearch("PICKUP", e)}
                          onValueChange={(v) => {
                            if (pickupLocationFormValue?.value !== v?.value) {
                              dispatch(clearShippingItems());
                              v && dispatch(getFreightClasses(v.value));
                            }
                            props.setFieldValue("pickupLocation", {
                              label: v?.label,
                              value: v?.value
                            });
                            setPickupLocationFormValue(v);
                            if (v?.value && /^\d{5}$/.test(v.value)) {
                              dispatch(getPickupDays(v.value));
                            }
                            props.setFieldValue("tenderDate", "");
                            setTenderDateFormValue(undefined);
                          }}
                          openMenuOnClick={requestRatesState.pickupLocations.length > 0}
                          options={requestRatesState.pickupLocations}
                          placeholder="Enter Zip or City..."
                          value={pickupLocationFormValue}
                          required={true}
                        />
                      </div>
                      <div>
                        <XGSFormDate
                          name="tenderDate"
                          label="Tender Date:"
                          onDateChange={(v) => {
                            props.setFieldValue("tenderDate", v);
                            setTenderDateFormValue(v);
                          }}
                          value={tenderDateFormValue}
                          onChange={() => null}
                          minDate={new Date(nextServiceCenterWorkDay())}
                          required={true}
                          labelMode={LabelModes.column}
                          className="xgs-request-rates__pickup-location__date-field"
                          disableWeekends={true}
                          disabledWeekDays={commonState.pickupDays}
                          disabled={!pickupLocationFormValue}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="xgs-request-rates__quick__section-header">Destination</div>
                  <div className="xgs-request-rates__quick__section">
                    <div className="xgs-request-rates__quick__row">
                      <div>
                        <LabeledSelectInput
                          className="xgs-request-rates__location-field"
                          isDisabled={false}
                          isLoading={requestRatesState.requestStarted && requestRatesState.requestCreator === "DESTINATION_LOOKUP"}
                          label="Location:"
                          labelMode={LabelModes.column}
                          name="destinationLocation"
                          onInputChange={(e) => locationSearch("DESTINATION", e)}
                          onValueChange={(v) => {
                            const obj: any = v;
                            if (!obj) return;
                            props.setFieldValue("destinationLocation", {
                              label: obj.label,
                              value: obj.value.zip
                            });
                            setDestinationLocationFormValue({
                              label: obj.label,
                              value: obj.value.zip
                            });
                            setDirectPoint(obj.value.direct);
                          }}
                          openMenuOnClick={requestRatesState.destinationLocations.length > 0}
                          options={requestRatesState.destinationLocations}
                          placeholder="Enter Zip or City..."
                          value={destinationLocationFormValue}
                          required={true}
                        />
                      </div>
                      <div>
                        {!directPoint && (
                          <div className="xgs-request-rates__indirect xgs-request-rates__indirect--quick">
                            Indirect Point!
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="xgs-request-rates__quick__section-header">Special Services</div>
                  <div className="xgs-request-rates__quick__section">
                    <div
                      className="xgs-request-rates__quick__section__help"
                      title="Click to view related help instructions"
                      onClick={() => { setShowServicesHelp(true); }}
                    >
                      <XGSIcon
                        icon={XGSRegularIcons.faQuestionCircle}
                        size="1x"
                      />
                    </div>
                    <div className="xgs-request-rates__services-options">
                      {services.map((field) =>
                        <div
                          role="group"
                          aria-labelledby="pickup-services-list"
                          className="xgs-request-rates__services-options__row"
                          key={field.value}
                        >
                          <label>
                            <Field
                              type="checkbox"
                              name="specialServices"
                              value={field.value}
                              disabled={isDisabledService(field.value, props.values.specialServices)}
                            />
                            <div>
                              <div className="xgs-request-rates__services-options__name">{field.label}</div>
                            </div>
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="xgs-request-rates__quick__section-header">Shipping Items</div>
                  <div className="xgs-request-rates__quick__section">
                    {requestRatesState.items.map((item, index) => (
                      <div key={index}>
                        {(index === editShippingItemIndex) && (
                          <ShippingItemForm
                            itemIndex={index}
                            onSubmit={(data) => {
                              dispatch(updateShippingItem({ index, data }));
                              setEditShippingItemIndex(undefined);
                            }}
                            onCancel={() => {
                              setEditShippingItemIndex(undefined);
                            }}
                          />
                        )}
                        {(editShippingItemIndex === undefined || (index !== editShippingItemIndex)) && !item.deleted && (
                          <div className="xgs-request-rates__shipping-items__item">
                            <div className="xgs-request-rates__shipping-items__item__columns">
                              <div>
                                {item.rolls && <div className="xgs-request-rates__shipping-items__item__property">Rolls</div> }
                                <div className="xgs-request-rates__shipping-items__item__property">{item.pieces} piece{(item.pieces && item.pieces > 1) ? "s" : ""}, 
                                {item.yards ? " " + item.yards + " yards, " : ""} {item.weight} pounds</div>
                                <div className="xgs-request-rates__shipping-items__item__property">Class: {getClassLabel(item.freightClass)}</div>
                                {item.description && <div className="xgs-request-rates__shipping-items__item__property">Description: {item.description}</div>}
                              </div>
                              <div>
                                <div className="xgs-request-rates__shipping-items__item__action">
                                  <span
                                    className="xgs-request-rates__shipping-items__link"
                                    onClick={() => {
                                      setAddShippingItemForm(false);
                                      setEditShippingItemIndex(index);
                                    }}
                                  >
                                    Edit
                                  </span>
                                </div>
                                <div className="xgs-request-rates__shipping-items__item__action">
                                  <span
                                    className="xgs-request-rates__shipping-items__link"
                                    onClick={() => {
                                      setRemoveItemIndex(index);
                                      setShowRemoveConfirmation(true);
                                    }}
                                  >
                                    Remove
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                    {(showAddShippingItemForm || requestRatesState.items.length === 0) &&
                      <ShippingItemForm
                        onSubmit={(data) => {
                          dispatch(addShippingItem(data));
                          setAddShippingItemForm(false);
                        }}
                        showWarning={!pickupLocationFormValue}
                      />
                    }
                    {!showAddShippingItemForm && (editShippingItemIndex === undefined) && (requestRatesState.items.length > 0) && (requestRatesState.items.filter(item => !item.deleted).length < 5) && (
                      <div className="xgs-request-rates__shipping-items__add">
                        <span
                          className="xgs-request-rates__shipping-items__link"
                          onClick={() => { setAddShippingItemForm(true); }}
                        >
                          + Add new item
                        </span>
                      </div>
                    )}
                  </div>
                  <div style={{ marginTop: 38 }}>
                    <Button
                      type="submit"
                      theme={ButtonThemes.blue}
                      className="xgs-request-rates__step__nav-button"
                      disabled={
                        !props.isValid ||
                        !props.values.pickupLocation ||
                        requestRatesState.items.length === 0 ||
                        requestRatesState.items.filter(item => !item.deleted).length === 0 ||
                        (editShippingItemIndex !== undefined)
                      }
                    >
                      Get rates
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        )}
        {showResults && 
          <ResultPreview
            previous={onBackFromResults}
            push={onStartOver}
            quickMode={true}
          />
        }
        <ConfirmationModal
          opened={showRemoveConfirmation}
          confirmButtonText="Remove"
          onCancel={() => {
            setShowRemoveConfirmation(false);
          }}
          onConfirm={() => {
            dispatch(deleteShippingItem(removeItemIndex));
            setShowRemoveConfirmation(false);
            setRemoveItemIndex(undefined);
          }}>
          The shipping item will be removed from the list.
        </ConfirmationModal>
        <ConfirmationModal
          opened={showServicesHelp}
          header="Services Help"
          confirmButtonText="Close"
          onConfirm={() => setShowServicesHelp(false)}
        >
          <div className="xgs-request-rates__quick__modal-help__description">The meaning of the special services is described below to help you understand them.</div>
          {services.map((field) =>
            <div className="xgs-request-rates__quick__modal-help__item">
              <strong>{field.label}</strong> - {field.description.charAt(0).toLowerCase() + field.description.slice(1)}
            </div>
          )}
        </ConfirmationModal>
      </div>
    </ContentContainer>
  );
};

export default QuickRequestRates;

import api, { ApiResponse } from "../api2";

class PicklistsService {
  private source: any;
  private static instance: PicklistsService;

  private constructor() { }

  static getInstance(): PicklistsService {
    if (!PicklistsService.instance) {
      PicklistsService.instance = new PicklistsService();
    }
    return PicklistsService.instance;
  }

  public getActivePicklists = async (
    rawRequestModel: any
  ): Promise<ApiResponse<any>> => {
    return await api.post('/tms/picklist/header', rawRequestModel, {
    });
  };

  public getPicklistDetails = async (
    rawRequestModel: any
  ): Promise<ApiResponse<any>> => {
    return await api.post('/tms/picklist/details', rawRequestModel, {
    });
  };

  public convertPicklistToRoute = async (
    rawRequestModel: any
  ): Promise<ApiResponse<any>> => {
    return await api.post('/tms/picklist/convertToRoute', rawRequestModel, {
    });
  };

  public getPicklistPdf = async (
    rawRequestModel: any
  ): Promise<ApiResponse<any>> => {
    return await api.post('/tms/picklist/getPDF', rawRequestModel, {
      
        "responseType": "blob"
      
    });
  };
  
}

export default PicklistsService;
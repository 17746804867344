import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Helmet from "react-helmet";
import ContentContainer from "../../../templates/content-container/contentContainer";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import Loading from "../../../ui-components/loading/loading";
import { Routes } from "../../../app/route/RoutesConfig";
import ReportsState from "../../../slices/reports/ReportsState";
import { reportsSelector, getReports } from "../../../slices/reports/reportsSlice";
import { userSelector } from "../../../slices/user/userSlice";
import UserState from "../../../slices/user/UserState";
import { frequencyTypes } from "./constants";
import "./scheduledReports.scss";

const ScheduledReports: React.FC<{}> = (props) => {
  const reportsState: ReportsState = useSelector(reportsSelector);
  const userState: UserState = useSelector(userSelector);
  const history = useHistory();
  const dispatch = useDispatch();

  const onCreateButtonClick = () => {
    history.push(`/${userState.activeSubAccount?.accountNumber}${Routes.reports.scheduled.create}`);
  };

  const openReport = (id?: string) => {
    id && history.push(`/${userState.activeSubAccount?.accountNumber}${Routes.reports.scheduled.list}/${id}`);
  };

  useEffect(() => {
    userState.activeSubAccount?.id && dispatch(getReports(userState.activeSubAccount.id));
  }, [dispatch, userState.activeSubAccount]);

  useEffect(() => {
    if (!userState.activeSubAccount?.id || !userState.profile?.subAccounts) return;
    if (!userState.profile.subAccounts.find(subAccount => subAccount.id === userState.activeSubAccount?.id)?.payor) {
      history.push(Routes.home);
    }
  }, [history, userState.profile, userState.activeSubAccount]);

  return (
    <ContentContainer title="Scheduled Reports">
      <Helmet title="Scheduled Reports" />
      <div className="xgs-reports__scheduled">
        {reportsState.requestStarted && (
          <div className="xgs-reports__scheduled__list-spinner">
            <Loading isLoading={true} />
          </div>
        )}
        {reportsState.requestSucceed && (
          <>
            {(!reportsState.reports || reportsState.reports.length === 0) && (
              <div className="xgs-reports__scheduled__no-reports">
                You do not have any scheduled reports.<br/>
                You can add new daily, weekly and monthly reports on this page.
              </div>
            )}
            {reportsState.reports && reportsState.reports.length > 0 && reportsState.reports.map((report) =>
              <div className="xgs-reports__scheduled__report" key={report.id} onClick={() => { openReport(report.id) }}>
                <div><strong>{report.name}</strong></div>
                <div>Schedule: {frequencyTypes.find((type: { value: string, label: string}) => type.value === report.frequency)?.label}</div>
                <div>Created: on {report.dateCreated?.toUiDateFormat()} by {report.userCreated}</div>
                <div>Recipients: {report.email1}{report.email2 && `, ${report.email2}`}{report.email3 && `, ${report.email3}`}</div>
              </div>
            )}
          </>
        )}
        {(!reportsState.reports || reportsState.reports.length < 11) && (
          <Button
            theme={ButtonThemes.blue}
            onClick={onCreateButtonClick}
            className="xgs-reports__scheduled__add-new-button"
          >
            Add new report
          </Button>
        )}
      </div>
    </ContentContainer>
  );
};

export default ScheduledReports;

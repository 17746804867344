import React from "react";
import { useSelector } from "react-redux";
import { Routes } from "../../app/route/RoutesConfig";
import XGSIcons from "../../ui-components/icon/xgsIcons";
import UserState from "../../slices/user/UserState";
import { userSelector } from "../../slices/user/userSlice";
import { UserUtils } from "../../app/data/user/userUtils";
import SubAccountSelector from "../sub-account-selector/subAccountSelector";
import TerminalSelector from "../terminal-selector/terminalSelector";
import StageBanner from "../stage-banner/stageBanner";
import { MenuItem } from ".";
import MenuItems from "./menuItems";
import "./menu.scss";
import moment from "moment";
import dateFormats from "../../app/data/common/dateFormats";

const Menu: React.FC<{}> = (props) => {
  const userState: UserState = useSelector(userSelector);

  const hasWarehouseSupport = () => {
    return (userState.activeSubAccount?.id && userState.profile?.subAccounts)
      ? userState.profile.subAccounts.find(subAccount => subAccount.id === userState.activeSubAccount?.id)?.warehouseSupport
      : false;
  };

  const isPayor = () => {
    return (userState.activeSubAccount?.id && userState.profile?.subAccounts)
      ? userState.profile.subAccounts.find(subAccount => subAccount.id === userState.activeSubAccount?.id)?.payor
      : false;
  };

  const menuItems: MenuItem[] = [
    {
      name: "Home",
      route: Routes.home,
      icon: XGSIcons.faHome
    }
  ];

  if (UserUtils.isUser(userState.profile) || UserUtils.isAdministrator(userState.profile)) {
    if (UserUtils.hasPermission(userState.profile || null, userState.activeSubAccount?.id || null, "RATE_QUOTE")) {
      menuItems.push(
        {
          name: "Rate Quotes",
          route: Routes.rates.list
        }
      );
    }
    menuItems.push(
      {
        name: "Claims",
        route: Routes.claims.list
      },
      {
        name: "Transit Calculator",
        route: Routes.transitCalculator
      }
    );
  }

  if (!UserUtils.isXGSDriver(userState.profile)) {
    menuItems.push(
      {
        name: "Shipments",
        icon: XGSIcons.faTruckMoving
      },
      {
        name: "Track Shipments",
        route: Routes.shipments.tracking
      }
    );
  }

  if (UserUtils.isEmployee(userState.profile)) {
    menuItems.push(
      {
        name: "Appointments",
        route: `${Routes.shipments.appointments}?ef=${moment().subtract(14, "d").format(dateFormats.apiDate)}`
      },
      {
        name: "Images",
        route: Routes.deliveryRecords.list
      }
    );
  }

  menuItems.push({
    name: "Documents",
    route: Routes.shipments.documents
  });

  if (UserUtils.isEmployee(userState.profile)) {
    menuItems.push(
      {
        name: "Pickups",
        icon: XGSIcons.faBox
      },
      {
        name: "Create Pickup",
        route: Routes.pickup.create
      },
      {
        name: "Pickup Assignment",
        route: Routes.pickup.management
      },
      {
        name: "Pickup Log",
        route: Routes.pickup.log
      }
    );
  }

  if (UserUtils.isEmployee(userState.profile)) {
    menuItems.push(
      {
        name: "Manifests",
        icon: XGSIcons.faCopy
      },
      {
        name: "Track Manifests",
        route: Routes.manifest.manifests,
      },
      {
        name: "Linehaul Mileage",
        route: Routes.linehaulMileage.mileage
      },
      {
        name: "Lane Management",
        route: Routes.linehaulMileage.management
      },
      {
        name: "Documents",
        route: Routes.shipments.manifestDocuments
      }
    )
  }

  if (UserUtils.isUser(userState.profile) || UserUtils.isAdministrator(userState.profile)) {
    menuItems.push(
      {
        name: "Bill of Lading",
        icon: XGSIcons.faBox,
        route: Routes.bolCombined
      },
      {
        name: "BOL",
        route: Routes.bol.list
      }
    );
    if (!UserUtils.hasFeatureDisabled(userState.profile, "RETURNS")) {
      menuItems.push(
        {
          name: "Returns",
          route: Routes.return.list
        }
      );
    }

    menuItems.push(
      {
        name: "Pickups",
        route: Routes.pickups.list,
      },      
    );
  }

  if (UserUtils.isUser(userState.profile) || UserUtils.isAdministrator(userState.profile)) {
    hasWarehouseSupport() && menuItems.push(
      {
        name: "Warehouse",
        icon: XGSIcons.faDatabase
      },
      {
        name: "Inventory",
        route: Routes.warehouse.inventory.list
      },
      {
        name: "Transactions",
        route: Routes.warehouse.transactions.list
      },
      {
        name: "Receipts",
        route: Routes.warehouse.receipts.list
      }
    );

    if (UserUtils.hasPermission(userState.profile || null, userState.activeSubAccount?.id || null, "PAYMENT")) {
      menuItems.push(
        {
          name: "Aging Invoices",
          icon: XGSIcons.faFileInvoice
        },
        {
          name: "Total",
          route: `${Routes.agingInvoices}/_TOTAL`
        },
        {
          name: "1-30",
          route: `${Routes.agingInvoices}/_1_30`
        },
        {
          name: "31-60",
          route: `${Routes.agingInvoices}/_31_60`
        },
        {
          name: "61-90",
          route: `${Routes.agingInvoices}/_61_90`
        },
        {
          name: "91-120",
          route: `${Routes.agingInvoices}/_91_120`
        },
        {
          name: "120+",
          route: `${Routes.agingInvoices}/_120_PLUS`
        }
      );
    }

    if (isPayor()) {
      menuItems.push(
        {
          name: "Reports",
          icon: XGSIcons.faDatabase
        },
        {
          name: "Scheduled",
          route: Routes.reports.scheduled.list
        }
      );
    }

    menuItems.push(
      {
        name: "Feedback",
        icon: XGSIcons.faComment,
        route: Routes.feedback
      }
    );
  }

  if (UserUtils.isEmployee(userState.profile)) {
    menuItems.push(
      {
        name: "Customers",
        icon: XGSIcons.faUsers
      },
      {
        name: "Teams",
        route: Routes.customers.teams
      },
      {
        name: "Users",
        route: Routes.customers.users
      },
      {
        name: "Pending Requests",
        route: Routes.customers.pending,
        id: "pending-requests"
      },
      {
        name: "Dispatch",
        icon: XGSIcons.faWarehouse
      },
      {
        name: "Plan Route",
        route: Routes.dispatch.planRoute
      },
      {
        name: "Active Routes",
        route: Routes.dispatch.activeRoutes
      },
      {
        name: "Picklists",
        route: Routes.dispatch.picklists
      }
    );
  }

  if (UserUtils.isXGSAdministrator(userState.profile)) {
    menuItems.push(
      {
        name: "Notices",
        icon: XGSIcons.faBullhorn
      },
      {
        name: "Announcement",
        route: Routes.customers.announcement
      },
      {
        name: "Feature Banner",
        route: Routes.customers.topBanner
      },
      {
        name: "Administration",
        icon: XGSIcons.faCogs
      },
      {
        name: "Employees",
        route: Routes.admin.employees,
      },
      {
        name: "Holidays",
        route: Routes.holidayCalendar
      },
    );
  }

  if (UserUtils.isXGSAdministrator(userState.profile)) {
    menuItems.push(

    )
  }

  // if (UserUtils.isAdministrator(userState.profile)) {
  //   menuItems.push(
  //     {
  //       name: "API Tokens",
  //       route: Routes.admin.jwtGenerator
  //     }
  //   );
  // }

  return (
    <div className={`xgs-site__menu-wrapper${UserUtils.isXGSDriver(userState.profile) ? " display-none" : ""}`}>
      <div className="xgs-menu">
        <div className="xgs-menu__list">
          <StageBanner />
          <div className="xgs-menu__selector">
            {UserUtils.isCustomer(userState.profile) && <SubAccountSelector />}
            {UserUtils.isEmployee(userState.profile) && <TerminalSelector />}
          </div>
          <MenuItems items={menuItems} />
        </div>
      </div>
    </div>
  );
};

export default Menu;

import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import InvoiceDocumentsState from "../../../slices/invoice-documents/InvoiceDocumentsState";
import {
  downloadGroupDocuments,
  invoiceDocumentsSelector,
  resetInvoiceDocuments
} from "../../../slices/invoice-documents/invoiceDocumentsSlice";
import ContentContainer from "../../../templates/content-container/contentContainer";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import { DocumentRequestModel } from "../../../app/data/invoice/models";
import { userSelector } from "../../../slices/user/userSlice";
import UserState from "../../../slices/user/UserState";
import { UserUtils } from "../../../app/data/user/userUtils";
import "./documents.scss";

const ShipmentDocuments: React.FC<{}> = (props) => {
  const userState: UserState = useSelector(userSelector);
  const invoiceDocumentsState: InvoiceDocumentsState = useSelector(invoiceDocumentsSelector);
  const dispatch = useDispatch();
  const [probills, setProbills] = useState<string>("");
  const [probillsDocuments, setProbillsDocuments] = useState<DocumentRequestModel[]>([]);
  const authSelectRef = useRef<HTMLInputElement>(null);
  const bolSelectRef = useRef<HTMLInputElement>(null);
  const miscSelectRef = useRef<HTMLInputElement>(null);
  const podSelectRef = useRef<HTMLInputElement>(null);
  const invSelectRef = useRef<HTMLInputElement>(null);

  const onProceedProbills = () => {
    const probillsArr = probills.split("\n");
    const probillRegex = /^\d{6,8}$/;
    for (const probill of probillsArr) {
      if (probillRegex.test(probill) && !probillsDocuments.find((obj: DocumentRequestModel) => obj.probill === Number(probill)) && probillsDocuments.length < 50) {
        const origProbillsDocuments = probillsDocuments;
        origProbillsDocuments.push({
          probill: Number(probill),
          types: UserUtils.isEmployee(userState.profile) ? ["ATH", "BOL", "MSC", "POD", "INV"] : ["BOL", "POD"]
        });
        setProbillsDocuments([...origProbillsDocuments]);
      }
    }
    setProbills("");
    adjustSelectAllState("BOL");
    adjustSelectAllState("POD");
    if (UserUtils.isEmployee(userState.profile)) {
      adjustSelectAllState("ATH");
      adjustSelectAllState("MSC");
      adjustSelectAllState("INV");
    }
  };

  const removeProbill = (i: number) => {
    setProbillsDocuments(origArr => [
      ...origArr.filter((item: DocumentRequestModel, origArrIndex: number) => origArrIndex !== i)
    ]);
  };

  const isSubmitDisabled = () => {
    return !probillsDocuments.find((obj: DocumentRequestModel) => obj.types.length > 0);
  };

  const changeProbillDocument = (i: number, type: string) => {
    const origProbillsDocuments = probillsDocuments;
    if (origProbillsDocuments[i].types.find(origType => origType === type)) {
      const index = origProbillsDocuments[i].types.indexOf(type);
      if (index !== -1) origProbillsDocuments[i].types.splice(index, 1);
    } else {
      origProbillsDocuments[i].types.push(type);
    }
    setProbillsDocuments([...origProbillsDocuments]);
    adjustSelectAllState(type);
  };

  const adjustSelectAllState = (type: string) => {
    let typeCheckedExist = false;
    let typeUncheckedExist = false;
    let ref;
    switch(type) {
      case "ATH":
        ref = authSelectRef;
        break;
      case "BOL":
        ref = bolSelectRef;
        break;
      case "MSC":
        ref = miscSelectRef;
        break;
      case "POD":
        ref = podSelectRef;
        break;
      case "INV":
        ref = invSelectRef;
    }
    if (!ref?.current) return;
    for (const probillDocuments of probillsDocuments) {
      typeCheckedExist = typeCheckedExist || !!probillDocuments.types.find((origType: string) => origType === type);
      typeUncheckedExist = typeUncheckedExist || !probillDocuments.types.find((origType: string) => origType === type);
    }
    if (typeCheckedExist && typeUncheckedExist) {
      ref.current.indeterminate = true;
    } else if (typeCheckedExist && !typeUncheckedExist) {
      ref.current.indeterminate = false;
      ref.current.checked = true;
    } else if (!typeCheckedExist && typeUncheckedExist) {
      ref.current.indeterminate = false;
      ref.current.checked = false;
    }
    //
  };

  const submitRequest = () => {
    dispatch(downloadGroupDocuments(probillsDocuments, onExportSuccess, onExportFailed));
  };

  const onExportSuccess = (exportLink: string) => {
    if (!exportLink) return
    const link = document.createElement("a");
    link.href = exportLink;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const onExportFailed = () => {
    toast.error("Failed to export and download PDF");
  }

  const selectAll = (type: string, checked: boolean) => {
    const newProbillsDocuments = [...probillsDocuments];
    for (const [probillDocumentsIndex, probillDocuments] of newProbillsDocuments.entries()) {
      const typeIndex = probillDocuments.types.findIndex((origType: string) => origType === type);
      if (checked && typeIndex === -1) {
        newProbillsDocuments[probillDocumentsIndex].types.push(type);
      } else if (!checked && typeIndex !== -1) {
        newProbillsDocuments[probillDocumentsIndex].types.splice(typeIndex, 1);
      }
    }
    setProbillsDocuments([...newProbillsDocuments]);
  };

  const onStartOver = () => {
    dispatch(resetInvoiceDocuments());
    setProbills("");
    setProbillsDocuments([]);
  };

  const onDownloadAgain = () => {
    if (!invoiceDocumentsState.link) return;
    onExportSuccess(invoiceDocumentsState.link);
  };

  useEffect(() => {
    return () => {
      dispatch(resetInvoiceDocuments());
    }
  }, [dispatch])

  return (
    <ContentContainer title="Documents">
      <div className="xgs-shipments-documents">
      {!invoiceDocumentsState.requestSucceed && (
        <>
          <div className="xgs-shipments-documents__notes">Provide probills one per line to select and download available documents. Maximum 50 probills are allowed.</div>
          <div className="xgs-textarea">
            <div className="xgs-textarea__label">Probill(s):</div>
            <textarea
              name="probills"
              value={probills}
              onChange={(e) => setProbills(e.target.value)}
            />
          </div>
          {probillsDocuments.length === 50 && probills.length > 0 && (
            <div className="xgs-shipments-documents__limit-reached">
              Can't be added because the 50 probill limit has been reached.
            </div>
          )}
          <Button
            className="xgs-shipments-documents__proceed-button"
            theme={ButtonThemes.blue}
            disabled={!probills || probillsDocuments.length > 49}
            onClick={onProceedProbills}
          >
            Proceed
          </Button>
          {probillsDocuments.length > 0 && (
            <>
              <div className="xgs-shipments-documents__list-container">
                <div className="xgs-shipments-documents__list">
                  <div className="xgs-shipments-documents__list__row xgs-shipments-documents__list__header">
                    <div className="xgs-shipments-documents__list__probill xgs-shipments-documents__list__probill--header">Probill</div>
                    <div>
                      <div className="xgs-shipments-documents__list__types__common">
                        Supported Documents
                      </div>
                      <div className="xgs-shipments-documents__list__types__parts">
                        {(UserUtils.isEmployee(userState.profile)) &&
                          <div className="xgs-shipments-documents__list__auth xgs-shipments-documents__list__types__part--desktop">Authorization</div>
                        }
                        <div className={`xgs-shipments-documents__list__bol xgs-shipments-documents__list__types__part--desktop${!UserUtils.isEmployee(userState.profile) ? " xgs-shipments-documents__list__bol--customer" : ""}`}>Bill of Lading</div>
                        {(UserUtils.isEmployee(userState.profile)) &&
                          <div className="xgs-shipments-documents__list__misc xgs-shipments-documents__list__types__part--desktop">Miscellaneous</div>
                        }
                        <div className={`xgs-shipments-documents__list__pod xgs-shipments-documents__list__types__part--desktop${!UserUtils.isEmployee(userState.profile) ? " xgs-shipments-documents__list__pod--customer" : ""}`}>Proof of Delivery</div>
                        {(UserUtils.isEmployee(userState.profile)) && (
                          <>
                            <div className="xgs-shipments-documents__list__inv xgs-shipments-documents__list__types__part--desktop">Invoice</div>
                            <div className="xgs-shipments-documents__list__auth xgs-shipments-documents__list__types__part--mobile">Auth</div>
                          </>
                        )}
                        <div className={`xgs-shipments-documents__list__bol xgs-shipments-documents__list__types__part--mobile${!UserUtils.isEmployee(userState.profile) ? " xgs-shipments-documents__list__bol--customer" : ""}`}>BOL</div>
                        {(UserUtils.isEmployee(userState.profile)) &&
                          <div className="xgs-shipments-documents__list__misc xgs-shipments-documents__list__types__part--mobile">Misc</div>
                        }
                        <div className={`xgs-shipments-documents__list__pod xgs-shipments-documents__list__types__part--mobile${!UserUtils.isEmployee(userState.profile) ? " xgs-shipments-documents__list__pod--customer" : ""}`}>POD</div>
                        {(UserUtils.isEmployee(userState.profile)) &&
                          <div className="xgs-shipments-documents__list__inv xgs-shipments-documents__list__types__part--mobile">INV</div>
                        }
                        <div className="xgs-shipments-documents__list__actions xgs-shipments-documents__list__types__part--mobile"></div>
                      </div>
                    </div>
                  </div>
                  <div className="xgs-shipments-documents__list__row">
                    <div className="xgs-shipments-documents__list__probill xgs-shipments-documents__list__probill--select-all">Select All</div>
                    {(UserUtils.isEmployee(userState.profile)) && (
                      <div className="xgs-shipments-documents__list__auth">
                        <div className="xgs-form__checkbox">
                          <input
                            type="checkbox"
                            name="checkbox-all-auth"
                            onChange={(e) => selectAll("ATH", e.target.checked)}
                            defaultChecked={true}
                            ref={authSelectRef}
                            className="xgs-shipments-documents__select-all__checkbox"
                          />
                        </div>
                      </div>
                    )}
                    <div className={`xgs-shipments-documents__list__bol${!UserUtils.isEmployee(userState.profile) ? " xgs-shipments-documents__list__bol--customer" : ""}`}>
                      <div className="xgs-form__checkbox">
                        <input
                          type="checkbox"
                          name="checkbox-all-bol"
                          onChange={(e) => selectAll("BOL", e.target.checked)}
                          defaultChecked={true}
                          ref={bolSelectRef}
                          className="xgs-shipments-documents__select-all__checkbox"
                        />
                      </div>
                    </div>
                    {(UserUtils.isEmployee(userState.profile)) && (
                      <div className="xgs-shipments-documents__list__misc">
                        <div className="xgs-form__checkbox">
                          <input
                            type="checkbox"
                            name="checkbox-all-misc"
                            onChange={(e) => selectAll("MSC", e.target.checked)}
                            defaultChecked={true}
                            ref={miscSelectRef}
                            className="xgs-shipments-documents__select-all__checkbox"
                          />
                        </div>
                      </div>
                    )}
                    <div className={`xgs-shipments-documents__list__pod${!UserUtils.isEmployee(userState.profile) ? " xgs-shipments-documents__list__pod--customer" : ""}`}>
                      <div className="xgs-form__checkbox">
                        <input
                          type="checkbox"
                          name="checkbox-all-pod"
                          onChange={(e) => selectAll("POD", e.target.checked)}
                          defaultChecked={true}
                          ref={podSelectRef}
                          className="xgs-shipments-documents__select-all__checkbox"
                        />
                      </div>
                    </div>
                    {(UserUtils.isEmployee(userState.profile)) && (
                      <div className="xgs-shipments-documents__list__inv">
                        <div className="xgs-form__checkbox">
                          <input
                            type="checkbox"
                            name="checkbox-all-inv"
                            onChange={(e) => selectAll("INV", e.target.checked)}
                            defaultChecked={true}
                            ref={invSelectRef}
                            className="xgs-shipments-documents__select-all__checkbox"
                          />
                        </div>
                      </div>
                    )}
                    <div className="xgs-shipments-documents__list__actions">
                    </div>
                  </div>
                  <div className="xgs-shipments-documents__list__scrollable-area">
                    {probillsDocuments.map((probillDocuments, i) => (
                      <div className="xgs-shipments-documents__list__row" key={`pro-${probillDocuments.probill}`}>
                        <div className="xgs-shipments-documents__list__probill">{probillDocuments.probill}</div>
                        {(UserUtils.isEmployee(userState.profile)) && (
                          <div className="xgs-shipments-documents__list__auth">
                            <div className="xgs-form__checkbox">
                              <input
                                type="checkbox"
                                name={`checkbox-${i}-auth`}
                                onChange={() => changeProbillDocument(i, "ATH")}
                                checked={probillDocuments.types.findIndex(type => type === "ATH") !== -1}
                              />
                            </div>
                          </div>
                        )}
                        <div className={`xgs-shipments-documents__list__bol${!UserUtils.isEmployee(userState.profile) ? " xgs-shipments-documents__list__bol--customer" : ""}`}>
                          <div className="xgs-form__checkbox">
                            <input
                              type="checkbox"
                              name={`checkbox-${i}-bol`}
                              onChange={() => changeProbillDocument(i, "BOL")}
                              checked={probillDocuments.types.findIndex(type => type === "BOL") !== -1}
                            />
                          </div>
                        </div>
                        {(UserUtils.isEmployee(userState.profile)) && (
                          <div className="xgs-shipments-documents__list__misc">
                            <div className="xgs-form__checkbox">
                              <input
                                type="checkbox"
                                name={`checkbox-${i}-misc`}
                                onChange={() => changeProbillDocument(i, "MSC")}
                                checked={probillDocuments.types.findIndex(type => type === "MSC") !== -1}
                              />
                            </div>
                          </div>
                        )}
                        <div className={`xgs-shipments-documents__list__pod${!UserUtils.isEmployee(userState.profile) ? " xgs-shipments-documents__list__pod--customer" : ""}`}>
                          <div className="xgs-form__checkbox">
                            <input
                              type="checkbox"
                              name={`checkbox-${i}-pod`}
                              onChange={() => changeProbillDocument(i, "POD")}
                              checked={probillDocuments.types.findIndex(type => type === "POD") !== -1}
                            />
                          </div>
                        </div>
                        {(UserUtils.isEmployee(userState.profile)) && (
                          <div className="xgs-shipments-documents__list__inv">
                            <div className="xgs-form__checkbox">
                              <input
                                type="checkbox"
                                name={`checkbox-${i}-inv`}
                                onChange={() => changeProbillDocument(i, "INV")}
                                checked={probillDocuments.types.findIndex(type => type === "INV") !== -1}
                              />
                            </div>
                          </div>
                        )}
                        <div className="xgs-shipments-documents__list__actions">
                          <XGSIcon
                            icon={XGSIcons.faTimes}
                            size="sm"
                            className="xgs-shipments-documents__list__actions__delete"
                            onClick={() => removeProbill(i)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <Button
                className="xgs-shipments-documents__proceed-button"
                theme={ButtonThemes.blue}
                disabled={isSubmitDisabled()}
                onClick={submitRequest}
                spinner={invoiceDocumentsState.requestStarted}
              >
                Submit
              </Button>
              <Button
                className="xgs-shipments-documents__start-over-button"
                theme={ButtonThemes.gray}
                onClick={onStartOver}
              >
                Clear
              </Button>              
            </>
          )}
        </>
      )}
      {invoiceDocumentsState.requestSucceed && (
        <div className="xgs-shipments-documents__success">
          <div>
            Your download will start shortly!<br />
            If not, try this <span className="cursor-pointer blue-link" onClick={onDownloadAgain}>direct download link</span>.
          </div>
          <Button
            className="xgs-shipments-documents__success__start-over-button"
            theme={ButtonThemes.blue}
            onClick={onStartOver}
          >
            Start Over
          </Button>
        </div>
      )}
      </div>
    </ContentContainer>
  );
};

export default ShipmentDocuments;

import React, { useCallback, useEffect, useState } from "react";
import { batch, useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { Routes } from "../../../../app/route/RoutesConfig";
import Loading from "../../../../ui-components/loading/loading";
import XGSIcon from "../../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../../ui-components/icon/xgsIcons";
import Button, { ButtonThemes } from "../../../../ui-components/button/button";
import { ReturnRequestModel } from "../../../../app/data/return/models";
import { StepProps } from "../../../../app/data/common/models";
import ReturnState from "../../../../slices/return/ReturnState";
import {
  createReturn,
  resetErrors,
  resetReturnState,
  returnSelector
} from "../../../../slices/return/returnSlice";
import { userSelector } from "../../../../slices/user/userSlice";
import UserState from "../../../../slices/user/UserState";
import ShareReturn from "../share";
import Exclamation, {
  ExclamationTypes,
} from "../../../../ui-components/molecules/exclamation/exclamation";
import "../../bol.scss";

const ReturnCompletedStep: React.FC<StepProps> = (props) => {
  const { previous, push } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const returnState: ReturnState = useSelector(returnSelector);
  const userState: UserState = useSelector(userSelector);
  const [probill, setProbill] = useState<number | null>(null);

  const makeRequest = useCallback(() => {
    let items = returnState.items.filter(item => !item.deleted);
    let {shipperAvailabilityHours, specialInstructions, specialServices, pickupDate, ...shipperObj} = returnState.returnShipper;
    let request: ReturnRequestModel = {
      requestorRole: returnState.returnCommon.requestorRole,
      referenceNumber: returnState.returnCommon.referenceNumber,
      ...(returnState.returnCommon.poNumber && {poNumber: returnState.returnCommon.poNumber}),
      ...(returnState.returnCommon.originalBolNumber && {originalBolNumber: returnState.returnCommon.originalBolNumber}),
      specialInstructions: specialInstructions || "",
      specialServices,
      pickupDate: pickupDate.toApiDateFormat(),
      shipper: shipperObj,
      shipperAvailabilityHours: shipperAvailabilityHours,
      consignee: returnState.returnConsignee,
      items,
      fileId: returnState.referenceDocumentId
    };
    dispatch(createReturn(request, (v) => {
      setProbill(v);
      mixpanel.track("Return was created");
    }));
  }, [
    dispatch,
    returnState.returnCommon,
    returnState.returnConsignee,
    returnState.returnShipper,
    returnState.items,
    returnState.referenceDocumentId
  ]);

  const onClickGoList = () => {
    batch(() => {
      dispatch(resetReturnState());
      dispatch(resetErrors());
    });
    history.push(`/${userState.activeSubAccount?.accountNumber}${Routes.return.list}`);
  };

  const onStartOver = () => {
    dispatch(resetReturnState());
    dispatch(resetErrors());
    push && push("return-common-step");
  };

  useEffect(() => {
    makeRequest();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="xgs-bol__return__step xgs-bol__return__step--wide">
      {((returnState.requestSucceed && returnState.requestCreator === "CREATE_REQUEST") || returnState.requestCreator === "SHARE_RETURN") && (
        <div className="xgs-bol__return__completed">
          <Exclamation type={ExclamationTypes.success}>
            The request was successfully sent!<br />
            We will contact you as soon as possible.
            <div className="blue-link" onClick={onClickGoList}>
              Go to the returns list.
            </div>
          </Exclamation>
          {probill && (
            <div className="xgs-bol__return__completed__share">
              All information on this request has already been sent to email <strong>{userState.profile?.email}</strong>.
              Also you can share BOL, shipping labels and pickup information using the form below:
              <ShareReturn probill={probill} />
            </div>
          )}
          <div className="xgs-bol__list__feedback">
            <div className="xgs-bol__list__feedback__icon">
              <XGSIcon
                icon={XGSIcons.faComment }
                size="lg"
              />
            </div>
            <div className="xgs-bol__list__feedback__text">
              Were you satisfied with the process? <Link className="blue-link" to={`${userState.activeSubAccount?.accountNumber ? "/" + userState.activeSubAccount?.accountNumber : ""}/feedback?subject=RETURN`} target="_blank">Leave your feedback</Link>, it's important to us.
            </div>
          </div>
        </div>
      )}
      {returnState.requestStarted && returnState.requestCreator === "CREATE_REQUEST" && (
        <Loading isLoading={true} />
      )}
      {returnState.requestFailed && returnState.requestCreator === "CREATE_REQUEST" && (
        <>
          <div style={{ marginBottom: 24 }}>
            {returnState.requestError || "Error"}
          </div>
          <div className="xgs-bol__buttons xgs-bol__return__request-error__buttons">
            <Button
              type="button"
              theme={ButtonThemes.blue}
              className="xgs-bol__nav-button"
              onClick={makeRequest}
            >
              Try Again
            </Button>
            <Button
              type="button"
              theme={ButtonThemes.gray}
              className="xgs-bol__nav-button"
              onClick={() => {
                previous && previous();
              }}
            >
              Back
            </Button>
            <Button
              type="button"
              theme={ButtonThemes.gray}
              className="xgs-bol__nav-button"
              onClick={onStartOver}
            >
              Start Over
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default ReturnCompletedStep;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ConfirmationModal from "../../../ui-components/confirmation-modal/confirmationModal";
import LabeledSelectInput from "../../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import SlideOutSidebar from "../../../ui-components/slide-out-sidebar/slideOutSidebar";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import { XGSSelectOption } from "../../../ui-components/xgs-select/xgsSelect";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import Loading from "../../../ui-components/loading/loading";
import MetricsBlocks from "../../metrics/metricsBlocks";
import 
  SubAccount,
  { SubAccountMinimal }
from "../../../app/data/account/SubAccount";
import { UserUtils } from "../../../app/data/user/userUtils";
import UserState from "../../../slices/user/UserState";
import { userSelector } from "../../../slices/user/userSlice";
import UsersState from "../../../slices/customers/users/TeamUsersState";
import {
  usersSelector,
  getUsers
} from "../../../slices/customers/users/teamUsersSlice";
import CompanySettingsAccountsState from "../../../slices/company-settings/CompanySettingsAccountsState";
import {
  companySettingsAccountsSelector,
  getPermissions,
  updatePermissions,
  resetErrors,
  updatePermissionsLocally,
  setAllUsersPermissions
} from "../../../slices/company-settings/companySettingsAccountsSlice";
import { permissionTypes } from "../constants";
import "./accountDetails.scss";
import { AccountGeneralSettings } from "./accountGeneralSettings";
import { useHistory, useParams } from "react-router-dom";
import { Routes } from "../../../app/route/RoutesConfig";
import { CompanySettingsTabRoutes, CompanySettingsPath, PathParams } from "../../company-settings/route";
import { HolidayCalendar } from "../../holiday-calendar/holidayCalendar";
import styles from "../../../sass/variables.module.scss";
import { AccountContactPage } from "../account-contact/accountContact";
import { StandingAppointmentTabs } from "../standing-appointment/standingAppointmentTabs";

export interface AccountDetailsProps {
  id: string | undefined;
  accountData?: any;
  onClose: () => void;
  readOnly?: boolean;
  show: boolean;
  teamId?: string;
}

const AccountDetails: React.FC<AccountDetailsProps> = (props) => {
  const userState: UserState = useSelector(userSelector);
  const usersState: UsersState = useSelector(usersSelector);
  const companySettingsAccountsState: CompanySettingsAccountsState = useSelector(companySettingsAccountsSelector);
  const [accountData, setAccountData] = useState<SubAccount | SubAccountMinimal>();
  const [addUserShow, setAddUserShow] = useState<boolean>();
  const [addAllUserConfirm, setAddAllUserConfirm] = useState<boolean>(false);
  const history = useHistory();
  const params = useParams() as PathParams;
  const dispatch = useDispatch();

  const onUserSelect = (user: XGSSelectOption | null | undefined) => {
    setAddUserShow(false);
    if (!accountData?.id || !user) return;
    dispatch(updatePermissions(accountData.id, user.value, ["VIEW"]));
    dispatch(updatePermissionsLocally({
      isNew: true,
      userId: user.value,
      userName: user.label,
      permissions: ["VIEW"]
    }));
  };

  const onPermissionChange = (
    userId: string,
    permissions: XGSSelectOption[] | null | undefined
  ) => {
    if (!accountData?.id) return;
    let userPermissions = permissions ? permissions.map((obj: XGSSelectOption) => obj.value) : ["VIEW"];
    dispatch(updatePermissionsLocally({
      userId: userId,
      permissions: userPermissions
    }));
    dispatch(updatePermissions(accountData.id, userId, userPermissions));
  };

  const onPermissionsRemove = (userId: string) => {
    if (!accountData?.id) return;
    dispatch(updatePermissionsLocally({
      userId: userId,
      permissions: []
    }));
    dispatch(updatePermissions(accountData.id, userId, []));
  };

  const getTeamUsersOptions = () => {
    return usersState.users
      .filter(user => user.role !== "ADMINISTRATOR")
      .filter(user => !(companySettingsAccountsState.accountPermissions.find(existedUser => existedUser.user === user.id)))
      .map((user): XGSSelectOption => ({
        label: `${user.firstName || ""} ${user.lastName || ""}`,
        value: user.id,
        subtitle: user.email,
        valueForSearch: `${user.email}|${user.firstName || ""}|${user.lastName || ""}`,
      }));
  };

  const getSelectValue = (permissions: string[]) => {
    let result: XGSSelectOption[] = [];
    for (let permission of permissions) {
      const type = permissionTypes.find(type => type.value === permission);
      type && result.push(type);
    }
    return result;
  };

  useEffect(() => {
    if (!props.id) return;
    const account = userState.profile?.subAccounts
      ? userState.profile?.subAccounts.find(user => user.id === props.id)
      : props.accountData;
    if (account) {
      setAccountData(account);
      account.id && dispatch(getPermissions(account.id));
    }
    setAddUserShow(false);
  }, [userState.profile, props.id, props.accountData, dispatch]);

  useEffect(() => {
    !props.readOnly && dispatch(getUsers(props.teamId || "current", true, undefined));
  }, [dispatch, props.teamId, props.readOnly]);

  useEffect(() => {
    if (companySettingsAccountsState.fetch_was_failed) {
      toast.error(companySettingsAccountsState.fetch_fail_reason || "Error while obtaining a data from API!");
      dispatch(resetErrors());
    }
  }, [dispatch, companySettingsAccountsState.fetch_was_failed, companySettingsAccountsState.fetch_fail_reason]);

  useEffect(() => {
    if (companySettingsAccountsState.update_was_failed) {
      toast.error(companySettingsAccountsState.update_fail_reason || "Error while updating the permissions!");
      dispatch(resetErrors());
    }
  }, [dispatch, companySettingsAccountsState.update_was_failed, companySettingsAccountsState.update_fail_reason]);

  const tabRoutes: any[] = [
    "/permissions",
    "/receiving-hours",
    "/delivery-requirements",
    "/exceptions",
    "/contacts",
    "/metrics"
  ]
  
  const changeRoute = (idx: number) => {
    UserUtils.isCustomer(userState.profile) && history.replace(`${Routes.company.general}${CompanySettingsTabRoutes.accounts}/${props.id}${tabRoutes[idx]}`)
  }

  const getInitialTab = () => {
    return params[CompanySettingsPath.innerTab] ? tabRoutes.findIndex(item => item === `/${params[CompanySettingsPath.innerTab]}`) : 0
  }

  return (
    <SlideOutSidebar
      header="Account information"
      onClose={props.onClose}
      show={props.show}
    >    
      {accountData && (
        <>
          <div className="xgs-company__accounts__details__name-block">
            <div className="xgs-company__accounts__details__name">{ accountData.name }</div>
            {accountData.address && (
              <div className="xgs-company__accounts__details__address">
                <span style={{ textTransform: "capitalize"}}>{ accountData.address.line1.toLowerCase() + ", "}</span>
                <span style={{ textTransform: "capitalize"}}>{ accountData.address.line2 ? accountData.address.line2.toLowerCase() + ", " : "" }</span>
                <span style={{ textTransform: "capitalize" }}>{ accountData.address.city.toLowerCase() }</span>
                { accountData.address.city && accountData.address.state ? ", " : "" }
                { accountData.address.state }&nbsp;{ accountData.address.zip }
              </div>
            )}
            {accountData.accountNumber && (
              <div className="xgs-company__accounts__details__number">{ accountData.accountNumber }</div>
            )}
          </div>
          <Tabs defaultIndex={getInitialTab()} onSelect={(idx) => changeRoute(idx)}>
            {UserUtils.isEmployee(userState.profile) && (
              <TabList>
                <Tab>Permissions</Tab>
                <Tab>Availability</Tab>
                <Tab>Requirements</Tab>
                <Tab>Exceptions&nbsp;<XGSIcon icon={XGSIcons.faCalendar} color={styles.blue1}/></Tab>
                <Tab>Contacts</Tab>
                <Tab>Metrics</Tab>
              </TabList>
            )}
            {UserUtils.isAdministrator(userState.profile) && (
              <TabList>
                <Tab>Permissions</Tab>
                <Tab>Availability</Tab>
                <Tab>Requirements</Tab>
                <Tab>Exceptions&nbsp;<XGSIcon icon={XGSIcons.faCalendar} color={styles.blue1}/></Tab>
                <Tab>Contacts</Tab>
              </TabList>
            )}
            {UserUtils.isUser(userState.profile) && (
              <>
                <div style={{display: "none"}}>
                  <TabList>
                    <Tab>Permissions</Tab>
                  </TabList>
                </div>
                <div className="xgs-company__accounts__details__permissions__header">Permissions</div>
              </>
            )}
            <TabPanel>
              <div className="xgs-company__accounts__details__permissions">
                {!props.readOnly && (
                  <div>
                    <span className="blue-link" onClick={() => setAddUserShow(true)}>Add user</span>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span className="blue-link" onClick={() => setAddAllUserConfirm(true)}>Add all users</span>&nbsp;(View only)
                  </div>
                )}
                <div className="xgs-company__accounts__details__permissions__notes">Note: customers with administrator privileges have full access to an account by default.</div>
                <div className="xgs-company__accounts__details__permissions__spinner">
                  <Loading isLoading={companySettingsAccountsState.update_was_started} />
                </div>
                <div className="xgs-company__accounts__details__add-user__permissions">
                  {addUserShow && !props.readOnly && (
                    <div className="xgs-company__accounts__details__add-user__permissions__add">
                      <LabeledSelectInput
                        label=""
                        isDisabled={false}
                        labelMode={LabelModes.column}
                        options={getTeamUsersOptions()}
                        placeholder="Type name or email..."
                        onValueChange={(v) => onUserSelect(v)}
                        async={true}
                        className="xgs-company__accounts__details__add-user__permissions__add__dropdown"
                      />
                      <Button
                        className="xgs-company__small-button"
                        type="button"
                        theme={ButtonThemes.gray}
                        spinner={false}
                        onClick={() => setAddUserShow(false)}>
                        <XGSIcon
                          icon={XGSIcons.faTimes}
                          size="sm"
                        />
                      </Button>
                    </div>
                  )}
                  <Loading isLoading={companySettingsAccountsState.fetch_was_started} />
                  {!companySettingsAccountsState.fetch_was_started && companySettingsAccountsState.accountPermissions.slice(0).reverse().map((permission) => (
                    <div className="xgs-company__accounts__details__add-user__permissions__row" key={permission.user}>
                      <div className="xgs-company__accounts__details__add-user__permissions__name">
                        {permission.name}
                      </div>
                      <div className="xgs-company__accounts__details__add-user__permissions__select">
                        <LabeledSelectInput
                          onMultiValuesChange={(option) => onPermissionChange(permission.user, option)}
                          options={permissionTypes}
                          label=""
                          labelMode={LabelModes.column}
                          isMulti
                          isClearable
                          disabled={companySettingsAccountsState.update_was_started || props.readOnly}
                          value={getSelectValue(permission.permissions)}
                        />
                      </div>
                      {!props.readOnly && (
                        <div className="xgs-company__accounts__details__add-user__permissions__remove">
                          <Button
                            className="xgs-company__small-button"
                            type="button"
                            theme={ButtonThemes.gray}
                            spinner={false}
                            onClick={() => onPermissionsRemove(permission.user)}>
                            <XGSIcon
                              icon={XGSIcons.faTimes}
                              size="sm"
                            />
                          </Button>
                        </div>
                      )}
                    </div>
                  ))}
                  {!companySettingsAccountsState.fetch_was_started && !addUserShow && (!companySettingsAccountsState.accountPermissions || companySettingsAccountsState.accountPermissions.length === 0) && (
                    <div className="xgs-company__accounts__details__no-permissions">
                      There are no permissions set on this account.
                    </div>
                  )}
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              {props.id && <StandingAppointmentTabs accountId={props.id} />}
            </TabPanel>
            <TabPanel>
              {props.id && accountData.accountNumber && <AccountGeneralSettings accountNumber={accountData.accountNumber} accountId={props.id} />}
            </TabPanel>
            <TabPanel>
              {props.id && <HolidayCalendar isCustomer accountId={props.id} />}
            </TabPanel>
            <TabPanel>
              {props.id && <AccountContactPage accountId={props.id}/>}
            </TabPanel>
            {UserUtils.isEmployee(userState.profile) && <TabPanel>
              <div className="xgs-company__accounts__details__metrics">
                {props.id && accountData.accountNumber && 
                  <MetricsBlocks 
                    accountId={props.id}
                    accountNumber={accountData.accountNumber}
                  />
                }
              </div>
            </TabPanel>}
          </Tabs>
        </>
      )}
      <ConfirmationModal
        opened={addAllUserConfirm}
        confirmButtonText="Yes"
        cancelButtonText="No"
        onCancel={() => {
          setAddAllUserConfirm(false);
        }}
        onConfirm={() => {
          props.id && dispatch(setAllUsersPermissions(props.id));
          setAddAllUserConfirm(false);
        }}>
        <p className="xgs-company__accounts__details__confirm-text">
          This will add &quot;View&quot; permission on the current account for all team users. Do you want to proceed?
        </p>
      </ConfirmationModal>
    </SlideOutSidebar>
  );
};

export default AccountDetails;

export const urlParams = (params?: {[key: string]: any}) => {
  if (!params) return null;

  const urlParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    value !== null && value !== undefined && value !== '' && urlParams.append(key, value);
  });

  return urlParams;
}
import * as Yup from "yup";

export interface TopBannerResponseModel {
  message: string;
  url: string;
  date: string;
};

export interface TopBannerFormModel {
  message?: string;
  url?: string;
};

export const TopBannerSchema: Yup.ObjectSchema<TopBannerFormModel> = Yup.object().shape({
  message: Yup.string().max(150, "The value must be no more than 150 characters"),
  url: Yup.string().max(255, "The value must be no more than 255 characters")
}).defined();


export const employeeRoles = [
  {
    label: "Administrator",
    value: "XGS_ADMINISTRATOR"
  },
  {
    label: "User",
    value: "XGS_USER"
  },
  {
    label: "Driver",
    value: "XGS_DRIVER"
  }
];

export const employeeStatuses = [
  {
    color: "#27AE60",
    name: "Active",
    value: "APPROVED"
  },
  {
    color: "#F2994A",
    name: "Invited",
    value: "INVITED"
  },
  {
    color: "#EB5757",
    name: "Disabled",
    value: "DISABLED"
  }
];

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import XGSFormSelect from "../../../ui-components/form/select/xgsFormSelect";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import {
  ClaimRemitToModel,
  ClaimRemitToSchema
} from "../../../app/data/claims/models";
import { XGSSelectOption } from "../../../ui-components/xgs-select/xgsSelect";
import AddressLookup from "../../../ui-components/address-lookup/addressLookup";
import { usaStates } from "../../../app/data/common/usaStates";
import ClaimsState from "../../../slices/claims/ClaimsState";
import {
  claimsSelector,
  setClaimRemitTo,
  setClaimRemitToSameAsContact
} from "../../../slices/claims/claimsSlice";
import { StepProps } from "../../../app/data/common/models";
import "../claims.scss";

let initialValues: ClaimRemitToModel = {
  company: "",
  address: {
    address1: undefined,
    city: undefined,
    state: undefined,
    postalCode: undefined
  }
};

const ClaimRemitTo: React.FC<StepProps> = (props) => {
  const { previous, next } = props;
  const claimsState: ClaimsState = useSelector(claimsSelector);
  const dispatch = useDispatch();
  const claimsFormRef = useRef<any>(null);
  const [stateValue, setStateValue] = useState<XGSSelectOption | null>();
  const [addressLookupValue, setAddressLookupValue] = useState<any>("");
  const [sameAsContact, setSameAsContact] = useState<boolean>(false);

  const onClickBack = (data: ClaimRemitToModel) => {
    dispatch(setClaimRemitToSameAsContact(sameAsContact));
    dispatch(setClaimRemitTo(data));
    previous && previous();
  };

  const onClickNext = (data: ClaimRemitToModel) => {
    dispatch(setClaimRemitToSameAsContact(sameAsContact));
    dispatch(setClaimRemitTo(data));
    next && next();
  };

  useEffect(() => {
    if (!claimsState.remitTo) return;
    claimsFormRef.current?.setFieldValue("company", claimsState.remitTo.company);
    claimsFormRef.current?.setFieldValue("address.address1", claimsState.remitTo.address.address1);
    claimsFormRef.current?.setFieldValue("address.city", claimsState.remitTo.address.city);
    claimsFormRef.current?.setFieldValue("address.postalCode", claimsState.remitTo.address.postalCode);
    claimsFormRef.current?.setFieldValue("address.state", claimsState.remitTo.address.state);
    const state = usaStates.find(obj => obj.value === claimsState.remitTo?.address.state);
    state && setStateValue({
      value: state.value,
      label: state.label
    });
    if (claimsState.remitTo.address.address1) {
      setAddressLookupValue({
        label: `${claimsState.remitTo.address.address1}, ${claimsState.remitTo.address.city}, ${claimsState.remitTo.address.state}, ${claimsState.remitTo.address.postalCode}`,
        value: {
          address: claimsState.remitTo.address.address1,
          city: claimsState.remitTo.address.city,
          state: claimsState.remitTo.address.state,
          zip: claimsState.remitTo.address.postalCode
        }
      });
    }
    setSameAsContact(claimsState.remitToSameAsContact);
    setTimeout(() => {
      claimsFormRef.current?.validateForm();
    }, 50);
  }, [claimsState.remitTo, claimsState.remitToSameAsContact]);

  useEffect(() => {
    if (!claimsState.contact || !sameAsContact) return;
    claimsFormRef.current?.setFieldValue("company", claimsState.contact.company);
    claimsFormRef.current?.setFieldValue("address.address1", claimsState.contact.address.address1);
    claimsFormRef.current?.setFieldValue("address.city", claimsState.contact.address.city);
    claimsFormRef.current?.setFieldValue("address.postalCode", claimsState.contact.address.postalCode);
    claimsFormRef.current?.setFieldValue("address.state", claimsState.contact.address.state);
    const state = usaStates.find(obj => obj.value === claimsState.contact?.address.state);
    state && setStateValue({
      value: state.value,
      label: state.label
    });
    if (claimsState.contact.address.address1) {
      setAddressLookupValue({
        label: `${claimsState.contact.address.address1}, ${claimsState.contact.address.city}, ${claimsState.contact.address.state}, ${claimsState.contact.address.postalCode}`,
        value: {
          address: claimsState.contact.address.address1,
          city: claimsState.contact.address.city,
          state: claimsState.contact.address.state,
          zip: claimsState.contact.address.postalCode
        }
      });
    }
    setTimeout(() => {
      claimsFormRef.current?.validateForm();
    }, 50);
  }, [claimsState.contact, sameAsContact]);

  return (
    <div className="xgs-claims__form">
      <Formik
        onSubmit={onClickNext}
        initialValues={initialValues}
        validationSchema={ClaimRemitToSchema}
        innerRef={claimsFormRef}
        enableReinitialize
      >
        {(props: FormikProps<ClaimRemitToModel>) => (
          <Form>
            <div className="xgs-wizard__step__header">Remit-To Information</div>
            <div className="xgs-claims__section">
              <div className="xgs-form__checkbox">
                <label>
                  <input
                    type="checkbox"
                    name="same-as-contact-checkbox"
                    onChange={() => setSameAsContact(!sameAsContact)}
                    checked={sameAsContact}
                  />
                  <div>
                    Same as contact information?
                  </div>
                </label>
              </div>
              {!sameAsContact && (
                <>
                  <div className="xgs-claims__row">
                    <div className="xgs-claims__row__column-50">
                      <div className="xgs-form__field">
                        <XGSFormInput
                          type="text"
                          name="company"
                          label="Company:"
                          required={true}
                          requiredAsteriskDisabled={false}
                          labelMode={LabelModes.column}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="xgs-form__address-block__container">
                    <AddressLookup
                      defaultValue={addressLookupValue}
                      onValueChange={(val) => {
                        props.setFieldValue("address.address1", val.address);
                        props.setFieldValue("address.city", val.city);
                        props.setFieldValue("address.state", val.state);
                        const stateOption = usaStates.find(state => state.value === val.state);
                        stateOption && setStateValue(stateOption);
                        props.setFieldValue("address.postalCode", val.zip);
                        setTimeout(() => {
                          props.setFieldTouched("address.address1", true, true);
                        }, 100);
                      }}
                      onBlur={() => {
                        props.setFieldTouched("address.address1", true, true);
                      }}
                    />
                    <div className="xgs-form__address-block">
                      <XGSFormInput
                        type="text"
                        name="address.address1"
                        label="Address:"
                        required={true}
                        requiredAsteriskDisabled={false}
                        labelMode={LabelModes.column}
                        className="xgs-bol__field"
                      />
                      <XGSFormInput
                        type="text"
                        name="address.city"
                        label="City:"
                        required={true}
                        requiredAsteriskDisabled={false}
                        labelMode={LabelModes.column}
                        className="xgs-bol__field"
                      />
                      <div className="xgs-bol__fields-row">
                        <XGSFormSelect
                          isSearchable={false}
                          label="State:"
                          labelMode={LabelModes.column}
                          name="address.state"
                          onValueChange={(v: any) => {
                            props.setFieldValue("address.state", v?.value);
                            if (v?.value) {
                              const stateOption = usaStates.find(state => state.value === v.value);
                              stateOption && setStateValue(stateOption);
                            }
                          }}
                          options={usaStates}
                          required={true}
                          requiredAsteriskDisabled={false}
                          formik={true}
                          value={stateValue}
                          className="xgs-bol__field xgs-bol__fields-row__state"
                        />
                        <div className="xgs-claims__form__field__zip">
                          <XGSFormInput
                            type="text"
                            name="address.postalCode"
                            label="Zip:"
                            required={true}
                            requiredAsteriskDisabled={false}
                            labelMode={LabelModes.column}
                            className="xgs-bol__field xgs-bol__field--zip"
                            id="xgs-bol__return__shipper-zip--js"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="xgs-wizard__step__buttons">
              <Button
                type="button"
                theme={ButtonThemes.gray}
                onClick={() => onClickBack(props.values)}
              >
                Back
              </Button>
              <Button
                type="submit"
                theme={ButtonThemes.blue}
                disabled={(!props.isValid || !props.values.company) && !sameAsContact}
              >
                Next
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ClaimRemitTo;

import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import ShipmentDetailsState from "../../../../slices/shipment-details/ShipmentDetailsState";
import { shipmentDetailsSelector } from "../../../../slices/shipment-details/shipmentDetailsSlice";
import { ShipmentDetailsPath, PathParams } from "../route";
import { useParams } from "react-router-dom";
import ContentContainerHeader from "../../../../ui-components/molecules/content-container-header/contentContainerHeader";
import HeaderStatus from "../../../../ui-components/molecules/header-status/headerStatus";
import XGSIcons from "../../../../ui-components/icon/xgsIcons";
import styles from "./../../../../sass/variables.module.scss";
import { SHIPMENT_STATUSES } from "../../../../app/data/common/ShipmentStatuses";
import Button, {
  ButtonThemes,
} from "../../../../ui-components/button/button";
import { getPaymentInfo } from "../../../../slices/payment/paymentSlice";
import Amount from "../../../../ui-components/molecules/amount/amount";
import DownloadPDF from "../../../download-invoice-pdf/downloadPDF";
import ShipmentStatusSubscribe from "../shipmentStatusSubscribe";
import ShipmentShare from "../shipmentShare";
import { userSelector } from "../../../../slices/user/userSlice";
import UserState from "../../../../slices/user/UserState";
import { UserUtils } from "../../../../app/data/user/userUtils";

const ShipmentDetailsHeader: React.FC<{}> = (props) => {
  const shipmentDetailsState: ShipmentDetailsState = useSelector(shipmentDetailsSelector);
  const userState: UserState = useSelector(userSelector);
  const urlParams = useParams() as PathParams;
  const dispatch = useDispatch();

  const urlInvoiceNumber = useMemo(() => {
    return parseInt(urlParams[ShipmentDetailsPath.invoiceNumber], 10);
  }, [urlParams]);

  const pay = async () => {
    if (shipmentDetailsState?.shipment?.invoice) {
      dispatch(
        getPaymentInfo(shipmentDetailsState.shipment.invoice.invoiceNumber)
      );
    }
  };

  const isPaid = () =>
    shipmentDetailsState?.shipment?.invoice &&
    shipmentDetailsState.shipment.invoice.paid;

  const getStatus = () => {
    return (
      shipmentDetailsState?.shipment?.status?.description || "Unknown Status"
    );
  };

  const getIcon = () => {
    switch (shipmentDetailsState?.shipment?.status?.description) {
      case SHIPMENT_STATUSES.delivered:
        return { icon: XGSIcons.faCheckCircle, iconColor: styles.green1 };
      default:
        return { icon: XGSIcons.faTruck, iconColor: styles.blue2 };
    }
  };

  const isTerminalStatus = () => {
    return shipmentDetailsState?.shipment?.status?.description === SHIPMENT_STATUSES.delivered
      || shipmentDetailsState?.shipment?.status?.description === SHIPMENT_STATUSES.warehoused;
  };

  const isInvoiceDateCurrent = shipmentDetailsState?.shipment?.invoice?.dateInvoice ? moment(shipmentDetailsState.shipment.invoice.dateInvoice).isSame(moment(), "day") : false;

  const showPayButton = () => !!(
    shipmentDetailsState?.shipment?.invoice?.openAmount &&
    shipmentDetailsState.shipment.invoice.openAmount > 0 &&
    !isInvoiceDateCurrent
  );

  return (
    <ContentContainerHeader
      title={`Shipment Details: ${urlInvoiceNumber}`}
      isLoading={shipmentDetailsState.loading}
      className="xgs-shipment-details__header"
    >
      {shipmentDetailsState.loaded && shipmentDetailsState?.shipment && (
        <>
          <div className="xgs-site__content-container-header__bar__column xgs-site__content-container-header__column xgs-shipment-details__header__status">
            <HeaderStatus label={getStatus()} tooltip={shipmentDetailsState?.shipment?.status?.translate} showIcon={true} {...getIcon()}>
              <>
                {(shipmentDetailsState?.shipment?.eta?.expectedEta && !shipmentDetailsState?.shipment?.pod?.date) && <>ETA: </>}
                {shipmentDetailsState?.shipment?.pod?.date
                  ? shipmentDetailsState.shipment.pod.date.toUiDateWeekFormat()
                  : shipmentDetailsState?.shipment?.eta?.expectedEta?.toUiDateWeekFormat()}
                {shipmentDetailsState?.shipment?.pod?.time && (
                  <>
                    &nbsp;{shipmentDetailsState.shipment.pod.time}
                  </>
                )}
              </>
            </HeaderStatus>
          </div>
          {shipmentDetailsState?.shipment?.fullAccess &&
            (shipmentDetailsState.shipment?.status?.title !== "BOOKED") &&
            UserUtils.hasPermission(userState.profile || null, userState.activeSubAccount?.id || null, "PAYMENT") && (
            <>
              {!isPaid() && (
                <div className="xgs-shipment-details__pay-invoice xgs-site__content-container-header__bar__column xgs-site__content-container-header__column">
                  {shipmentDetailsState.shipment?.status?.title !== "CANCELLED" && (
                    <>
                      <HeaderStatus label="Total">
                        <Amount amount={shipmentDetailsState?.shipment?.invoice?.openAmount} />
                      </HeaderStatus>
                      {showPayButton() && (
                        <Button
                          theme={ButtonThemes.blue}
                          onClick={pay}
                        >
                          Pay Invoice
                        </Button>
                      )}
                    </>
                  )}
                </div>
              )}
              {isPaid() && (
                <div
                  className="xgs-site__content-container-header__bar__column xgs-site__content-container-header__column"
                  style={{ marginRight: 0, width: 304, paddingRight: 64 }}
                >
                  <HeaderStatus label="Invoice Paid" showIcon={true} icon={XGSIcons.faCheckCircle} iconColor={styles.green1}>
                    <Amount amount={shipmentDetailsState?.shipment?.invoice?.invoiceAmount} />
                  </HeaderStatus>
                </div>
              )}
            </>
          )}
          <div className="xgs-site__content-container-header__bar__float-buttons">
            {(shipmentDetailsState?.shipment?.invoice?.invoiceNumber || urlInvoiceNumber) &&
             userState.profile?.id &&
             !isTerminalStatus() &&
             (UserUtils.isAdministrator(userState.profile) || UserUtils.isCustomer(userState.profile)) &&
              <ShipmentStatusSubscribe
                invoiceNumber={shipmentDetailsState?.shipment?.invoice?.invoiceNumber || urlInvoiceNumber}
                subscribed={shipmentDetailsState.shipment.userSubscribed}
                userTimezone={userState.profile.timezone}
              />
            }
            <ShipmentShare
              invoiceNumber={shipmentDetailsState?.shipment?.invoice?.invoiceNumber || urlInvoiceNumber}
            />
            {shipmentDetailsState?.shipment?.fullAccess && UserUtils.hasPermission(userState.profile || null, userState.activeSubAccount?.id || null, "PAYMENT") && (
              <DownloadPDF invoiceNumbers={urlInvoiceNumber} type="single" />
            )}
          </div>
        </>
      )}
    </ContentContainerHeader>
  );
};

export default ShipmentDetailsHeader;

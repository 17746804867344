import React, { useState } from "react";
import {
  useDispatch,
  useSelector
} from "react-redux";
import { NavLink } from "react-router-dom";
import { MenuItem } from ".";
import XGSIcon from "../../ui-components/icon/xgsIcon";
import { userSelector } from "../../slices/user/userSlice";
import { closeMobileSideMenu } from "../../slices/user/userSlice";
import UserState from "../../slices/user/UserState";
import { UserUtils } from "../../app/data/user/userUtils";
import XGSIcons from "../../ui-components/icon/xgsIcons";
import ContactUs from "../contact-us/contactUs";
import NotificationDot from "./notificationDot";

export interface MenuItemsProps {
  items: MenuItem[];
}

const MenuItems: React.FC<MenuItemsProps> = (props) => {
  const [contactUsPopup, setContactUsPopup] = useState<boolean>(false);
  const userState: UserState = useSelector(userSelector);
  const dispatch = useDispatch();

  const itemRouteMatchTheCurrentPath = (
    route: string,
    match: any,
    location: any
  ): boolean => {
    if (!route) return false;
    if (match?.isExact) return true;
    route = route?.toLowerCase();
    let path = location.pathname.toLowerCase() + (location.search || "");
    if (UserUtils.isCustomer(userState.profile)) {
      path = path.replace(`/${userState.activeSubAccount?.accountNumber}`, "")
    } else if (UserUtils.isEmployee(userState.profile)) {
      path = path.replace(`/${userState.activeTerminal?.id}`, "");
    }
    if (path === route) return true;
    if (route !== "/") {
      path = path.substring(0, route.length);
      return path === route;
    } else {
      if ((path.indexOf("/aging-invoices") !== -1) || (path.indexOf("/invoices/") !== -1)) {
        // custom logic for the route that isn't part of the parent route
        return true;
      }
      return false;
    }
  };

  return (
    <>
      {props.items.map((item, idx) => (
        <React.Fragment key={`menu-item-${item.name}-${idx}`}>
          {item.route && (
            <>
              { UserUtils.isXGSUser(userState.profile)
                || UserUtils.isXGSAdministrator(userState.profile)
                || UserUtils.isAdministrator(userState.profile)
                || item.route === "/"
                || item.route === "/feedback"
                || (userState.profile?.subAccounts && userState.profile.subAccounts.length > 0) ? (
                <NavLink
                  to={item.route}
                  className="xgs-menu__item xgs-menu__item--link"
                  activeClassName="xgs-menu__item__selected"
                  isActive={(match, location) => {
                    return item.route ? itemRouteMatchTheCurrentPath(item.route, match, location) : false;
                  }}
                  onClick={() => {dispatch(closeMobileSideMenu())}}
                >
                  <div className="xgs-menu__icon-container">{item.icon && <XGSIcon className="xgs-menu__icon" icon={item.icon} />}</div>
                  <div className={!item.icon ? "xgs-menu__item__sub" : ""}>{item.name}<NotificationDot id={item.id} /></div>
                </NavLink>
              ) : (
                <span className="xgs-menu__item xgs-menu__item--link">
                  <div className="xgs-menu__icon-container">{item.icon && <XGSIcon className="xgs-menu__icon" icon={item.icon} />}</div>
                  <div className={!item.icon ? "xgs-menu__item__sub" : ""}>{item.name}</div>
                </span>
              )}
            </>
          )}
          {!item.route && (
            <div className="xgs-menu__item">
              <div className="xgs-menu__icon-container">{item.icon && <XGSIcon className="xgs-menu__icon" icon={item.icon} />}</div>
              <div>{item.name}</div>
            </div>
          )}
        </React.Fragment>
      ))}
      {(UserUtils.isUser(userState.profile) || UserUtils.isAdministrator(userState.profile)) && (
        <>
          <a
            className="xgs-menu__item xgs-menu__item--link xgs-menu__item--desktop-hide clickable"
            href="https://support.xgsi.com/knowledge"
            target="_blank"
            rel="noopener noreferrer">
            <div className="xgs-menu__icon-container"><XGSIcon className="xgs-menu__icon" icon={XGSIcons.faLifeRing} /></div>
            <div>Help Center</div>
          </a>
          <span className="xgs-menu__item xgs-menu__item--link clickable" onClick={() => {setContactUsPopup(true)}}>
            <div className="xgs-menu__icon-container"><XGSIcon className="xgs-menu__icon" icon={XGSIcons.faPhoneAlt} /></div>
            <div>Contact Us</div>
          </span>
          <ContactUs
            onClose={() => {setContactUsPopup(false)}}
            showPopup={contactUsPopup}
          />
        </>
      )}
    </>
  );
};

export default MenuItems;

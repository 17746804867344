import { IState } from "../..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../../app/store";
import { TopBannerFormModel } from "../../../app/data/top-banner/models";
import TopBannerService from "../../../app/data/top-banner/topBannerService";
import { initialTopBannerState } from "./TopBannerState";

const topBannerService = TopBannerService.getInstance();

export const topBannerSlice = createSlice({
  name: "topBanner",
  initialState: initialTopBannerState,
  reducers: {
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestSucceed = false;
      state.requestFailed = false;
      state.requestError = "";
      state.requestCreator = payload;
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestSucceed = true;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestSucceed = false;
      state.requestFailed = true;
      state.requestError = payload;
    },
    resetErrors: (state) => {
      state.requestFailed = false;
      state.requestError = "";
    },
    storeBanner: (state, { payload }) => {
      state.banner = payload;
    }
  }
});

export const {
  requestStarted,
  requestSucceed,
  requestFailed,
  resetErrors,
  storeBanner
} = topBannerSlice.actions;

export const topBannerSelector = (state: IState) => state.topBanner;

export const getTopBanner = (
  onSuccess?: (response: any) => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("GET"));
  const response = await topBannerService.getTopBanner();
  if (response.ok()) {
    dispatch(storeBanner(response.data));
    dispatch(requestSucceed());
    onSuccess && onSuccess(response.data);
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const saveTopBanner = (
  request: TopBannerFormModel,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("SAVE"));
  const response = await topBannerService.saveTopBanner(request);
  if (response.ok()) {
    dispatch(storeBanner(response.data));
    dispatch(requestSucceed());
    onSuccess && onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const clearTopBanner = (
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("CLEAR"));
  const response = await topBannerService.saveTopBanner({
    message: "",
    url: ""
  });
  if (response.ok()) {
    dispatch(storeBanner(response.data));
    dispatch(requestSucceed());
    onSuccess && onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

const topBannerReducer = topBannerSlice.reducer;
export default topBannerReducer;

import { TopBannerResponseModel } from "../../../app/data/top-banner/models";

export default interface TopBannerState {
  requestStarted: boolean;
  requestSucceed: boolean;
  requestFailed: boolean;
  requestError: string;
  requestCreator: string;
  banner: TopBannerResponseModel | null;
}

export const initialTopBannerState: TopBannerState = {
  requestStarted: false,
  requestSucceed: false,
  requestFailed: false,
  requestError: "",
  requestCreator: "",
  banner: null
};

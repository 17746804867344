import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Form, Formik, FormikProps } from "formik";
import { toast } from "react-toastify";
import ContentContainer from "../../../templates/content-container/contentContainer";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import XGSFormTextarea from "../../../ui-components/form/textarea/xgsFormTextarea";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import {
  TopBannerFormModel,
  TopBannerSchema
} from "../../../app/data/top-banner/models";
import { UserUtils } from "../../../app/data/user/userUtils";
import { Routes } from "../../../app/route/RoutesConfig";
import TopBannerState from "../../../slices/customers/top-banner/TopBannerState";
import {
  clearTopBanner,
  getTopBanner,
  saveTopBanner,
  topBannerSelector
} from "../../../slices/customers/top-banner/topBannerSlice";
import UserState from "../../../slices/user/UserState";
import { userSelector } from "../../../slices/user/userSlice";
import "./topBanner.scss";

let initialValues: TopBannerFormModel = {
  message: "",
  url: ""
};

const TopBannerManagement: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const topBannerFormRef = useRef<any>(null);
  const topBannerState: TopBannerState = useSelector(topBannerSelector);
  const userState: UserState = useSelector(userSelector);

  const onSubmit = (data: TopBannerFormModel) => {
    dispatch(saveTopBanner(data, () => {
      toast.info("Banner was updated!", { autoClose: 3000 });
    }));
  };

  const clearForm = () => {
    dispatch(clearTopBanner(() => {
      toast.info("Banner was cleared!", { autoClose: 3000 });
      topBannerFormRef.current.resetForm();
    }));
  };

  useEffect(() => {
    if (UserUtils.isXGSAdministrator(userState.profile)) {
      dispatch(getTopBanner());
    } else {
      history.push(Routes.home);
    }
  }, [dispatch, history, userState.profile]);

  useEffect(() => {
    if (!topBannerState.banner) return;
    topBannerFormRef.current.setFieldValue("message", topBannerState.banner.message || "");
    topBannerFormRef.current.setFieldValue("url", topBannerState.banner.url || "");
  }, [topBannerState.banner]);

  return (
    <ContentContainer
      title="New Feature Banner"
      isLoading={topBannerState.requestStarted && topBannerState.requestCreator === "GET"}
    >
      <>
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={TopBannerSchema}
          innerRef={topBannerFormRef}
        >
          {(props: FormikProps<TopBannerFormModel>) => (
            <Form className="form-items xgs-form__container">
              <XGSFormTextarea
                name="message"
                label="Message:"
                required={false}
                className="xgs-top-banner__management__message"
                counter={150}
                placeholder="Plain text"
              />
              <XGSFormInput
                type="text"
                name="url"
                label="URL:"
                required={false}
                labelMode={LabelModes.column}
                className=""
                placeholder="https://somedomain.com/example"
              />
              <div className="xgs-form__field__notes" style={{ marginTop: 24 }}>
                <strong>Note:</strong> the banner will be displayed to customers if both &quot;Message&quot; and &quot;URL&quot; fields are filled.
              </div>
              {topBannerState.requestFailed && (
                <div style={{ marginTop: 24 }}>
                  <XGSErrorMessage>{topBannerState.requestError}</XGSErrorMessage>
                </div>
              )}
              <div className="xgs-form__buttons">
                <Button
                  theme={ButtonThemes.blue}
                  className=""
                  disabled={!props.isValid || !props.dirty}
                  spinner={topBannerState.requestStarted && topBannerState.requestCreator === "SAVE"}
                >
                  Save
                </Button>
                <Button
                  theme={ButtonThemes.gray}
                  className=""
                  onClick={clearForm}
                  disabled={false}
                  spinner={topBannerState.requestStarted && topBannerState.requestCreator === "CLEAR"}
                  type="button"
                >
                  Clear
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </>
    </ContentContainer>
  );
};

export default TopBannerManagement;

import React from "react";
import { Link } from "react-router-dom";

const getFormattedDate = (date: number) => {
    if (!date || !(+date)) return "";
    let dateStr = date.toString();
    const abrevations = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", " Sep", "Oct", "Nov", "Dec"];
    return `${abrevations[+dateStr.slice(4, 6) - 1]} ${dateStr.slice(6)}, ${dateStr.slice(0, 4)}`;
}

const getFormattedTime = (time: number) => {
    let timeStr = `000000${time}`.slice(-6);
    let hour = +timeStr.slice(0, 2);
    if (hour < 12) return `${timeStr.slice(0, 2)}:${timeStr.slice(2, 4)} am`;
    else return `${timeStr.slice(0, 2)}:${timeStr.slice(2, 4)} pm`;
}


const getPicklistDetailsColumns = (
) => [
        {
            Header: "Probill",
            accessor: "probillNumber",
            width: 80,
            Cell: (cellProps: any) => (
                <Link
                    className="xgs-table-link-cell"
                    to={`/shipments/${cellProps.value}`}
                >
                    {cellProps.value}
                </Link>
            )
        },
        {
            Header: "Load Sequence",
            accessor: "loadSequence",
            width: 120,

        },
        {
            Header: "Weight",
            accessor: "weight",
            width: 60,
            Cell: (cellProps: any) => (
                <>
                    {cellProps.value.toLocaleString('en', { useGrouping: true })}
                </>
            )

        },
        {
            Header: "Pieces",
            accessor: "pieces",
            width: 60,

        },
        {
            Header: "Zip Code",
            accessor: "zipCode",
            width: 80,

        },
        {
            Header: "Door",
            accessor: "door",
            width: 100,

        },
        {
            Header: "Created At",
            accessor: "createdAt",
            width: 150,
            Cell: (cellProps: any) => (
                <>
                    {`${getFormattedDate(+cellProps.value.slice(0, 8))} ${getFormattedTime(+cellProps.value.slice(8))}`}
                </>
            )

        },
        {
            Header: "Created By",
            accessor: "createdBy",
            width: 100,

        },

    ];

export default getPicklistDetailsColumns;

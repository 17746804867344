import api, { ApiResponse } from "./../api";
import {
  TopBannerFormModel,
  TopBannerResponseModel
} from "./models";

class TopBannerService {
  private static instance: TopBannerService;
  private constructor() {}
  static getInstance(): TopBannerService {
    if (!TopBannerService.instance) {
      TopBannerService.instance = new TopBannerService();
    }
    return TopBannerService.instance;
  }

  public getTopBanner = async (): Promise<ApiResponse<any>> => {
    return await api.get("/notifications/banner");
  };

  public saveTopBanner = async (
    request: TopBannerFormModel
  ): Promise<ApiResponse<TopBannerResponseModel>> => {
    return await api.post("/notifications/banner", request);
  };
}

export default TopBannerService;

import { IState } from "../..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../../app/store";
import UserService from "../../../app/data/user/userService";
import CustomerService from "../../../app/data/customers/customersService";
import { initialPendingRequestsState } from "./PendingRequestsState";
import { 
  RegistrationApproveModel,
  RequestRejectModel,
  ShipperApproveModel
} from "../../../app/data/user/requestModels";

const userService = UserService.getInstance();
const customerService = CustomerService.getInstance();

export const pendingRequestsSlice = createSlice({
  name: "pendingRequests",
  initialState: initialPendingRequestsState,
  reducers: {
    fetch_was_started: (state, { payload }) => {
      if (payload) {
        state.fetchPortion_was_started = true;
      } else {
        state.fetch_was_started = true;
      }
      state.fetch_was_succeed = false;
      state.fetch_was_failed = false;
      state.fetch_fail_reason = null;      
    },
    fetch_was_succeed: (state) => {
      state.fetch_was_succeed = true;
      state.fetch_was_started = false;
      state.fetchPortion_was_started = false;
      state.fetch_was_failed = false;
    },
    set_requests: (state, { payload }) => {
      state.pendingRequests = payload.responseData.content;
      state.pendingRequestsScrollRequest = (payload.responseData.content.length < 50) ? null : payload.responseData.scroll;
      state.pendingRequestsFetchedAll = !!(payload.responseData.content.length < 50);
    },
    add_requests: (state, { payload }) => {
      state.pendingRequests = [...state.pendingRequests, ...payload.content];
      state.pendingRequestsScrollRequest = (payload.content.length < 50) ? null : payload.scroll;
      state.pendingRequestsFetchedAll = !!(payload.content.length < 50);
    },
    fetch_was_failed: (state, { payload }) => {
      state.fetch_was_failed = true;
      state.fetch_was_succeed = false;
      state.fetch_was_started = false;
      state.fetch_fail_reason = payload;
    },
    request_was_started: (state, { payload }) => {
      state.request_was_started = true;
      state.request_was_succeed = false;
      state.request_was_failed = false;
      state.request_fail_reason = null;
      state.request_creator = payload;
    },
    request_was_succeed: (state) => {
      state.request_was_succeed = true;
      state.request_was_started = false;
      state.request_was_failed = false;
      state.request_creator = undefined;
    },
    request_was_failed: (state, { payload }) => {
      state.request_was_failed = true;
      state.request_was_succeed = false;
      state.request_was_started = false;
      state.request_fail_reason = payload;
    },
    resetErrors: (state) => {
      state.fetch_was_failed = false;
      state.fetch_fail_reason = null;
      state.request_was_failed = false;
      state.request_fail_reason = null;
    },
    storeTeamAccounts: (state, { payload }) => {
      state.teamAccounts = [];
      state.teamAccounts.push(...payload.map((account: any) => {
        return {
          label: `${account.name || ""} (${account.accountNumber || ""})`,
          value: account.id,
          valueForSearch: `${account.name || ""}|${account.accountNumber || ""}`,
        }
      }));
    },
    setAccountsFieldIndex: (state, { payload }) => {
      state.teamAccountIndex = payload;
    },
    storeAccounts: (state, { payload }) => {
      state.accounts = payload;
    }
  }
});

export const {
  fetch_was_started,
  fetch_was_succeed,
  fetch_was_failed,
  set_requests,
  add_requests,
  request_was_started,
  request_was_succeed,
  request_was_failed,
  resetErrors,
  storeTeamAccounts,
  setAccountsFieldIndex,
  storeAccounts
} = pendingRequestsSlice.actions;

export const pendingRequestsSelector = (state: IState) => state.pendingRequests;

export const getPendingRequests = (
  lastIds?: string
): AppThunk => async (dispatch) => {
  dispatch(fetch_was_started(!!lastIds));
  let response = await userService.getPendingRequests(lastIds);
  if (response.ok()) {
    dispatch(fetch_was_succeed());
    dispatch(lastIds
      ? add_requests(response.data)
      : set_requests({ responseData: response.data })
    );
  } else {
    response.getError && dispatch(fetch_was_failed(response.getError()));
  }
};

export const approveRegistrationRequest = (
  data: RegistrationApproveModel,
  onSuccess: () => void
): AppThunk => async (dispatch) => {
  dispatch(request_was_started("APPROVE"));
  let response = await userService.approveRegistrationRequest(data);
  if (response.ok()) {
    dispatch(request_was_succeed());
    onSuccess();
  } else {
    response.getError && dispatch(request_was_failed(response.getError()));
  }
};

export const approveShipperRequest = (
  data: ShipperApproveModel,
  onSuccess: () => void
): AppThunk => async (dispatch) => {
  dispatch(request_was_started("APPROVE"));
  let response = await userService.approveShipperRequest(data);
  if (response.ok()) {
    dispatch(request_was_succeed());
    onSuccess();
  } else {
    response.getError && dispatch(request_was_failed(response.getError()));
  }
};

export const rejectRequest = (
  data: RequestRejectModel,
  shipperRequest: boolean,
  onSuccess: () => void
): AppThunk => async (dispatch) => {
  dispatch(request_was_started("REJECT"));
  let response = shipperRequest ? await userService.rejectShipperRequest(data) : await userService.rejectRegistrationRequest(data);
  if (response.ok()) {
    dispatch(request_was_succeed());
    onSuccess();
  } else {
    response.getError && dispatch(request_was_failed(response.getError()));
  }
};

export const searchTeamAccounts = (
  teamId: string,
  query: string,
  fieldIndex: number
): AppThunk => async (dispatch) => {
  dispatch(request_was_started("ACCOUNT_SEARCH"));
  dispatch(setAccountsFieldIndex(fieldIndex));
  const response = await customerService.searchTeamAccounts(teamId, query);
  if (response.ok()) {
    dispatch(storeTeamAccounts(response.data));
    dispatch(request_was_succeed());
  } else {
    response.getError && dispatch(request_was_failed(response.getError()));
  }
};

export const searchAccounts = (
  name: string
): AppThunk => async (dispatch) => {
  dispatch(request_was_started("ACCOUNT_SEARCH"));
  const response = await customerService.searchAccounts(name);
  if (response.ok()) {
    dispatch(storeAccounts(response.data));
    dispatch(request_was_succeed());
  } else {
    response.getError && dispatch(request_was_failed(response.getError()));
  }
};

const pendingRequestsReducer = pendingRequestsSlice.reducer;
export default pendingRequestsReducer;

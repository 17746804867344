import { UserRoles, UserStatuses } from "../../app/data/user/enums";

export const userStatuses = [
  {
    color: "#27AE60",
    name: "Active",
    value: "APPROVED"
  },
  {
    color: "#F2994A",
    name: "Invited",
    value: "INVITED"
  },
  {
    color: "#EB5757",
    name: "Disabled",
    value: "DISABLED"
  },
  {
    color: "#F2994A",
    name: "Pending confirmation",
    value: "TRANSFERRED"
  }
];

export const permissionTypes = [
  {
    label: "View Account",
    value: "VIEW",
    isFixed: true
  },
  {
    label: "Pay Invoices",
    value: "PAYMENT"
  },
  {
    label: "Quote Rates",
    value: "RATE_QUOTE"
  }
];

export const ACCESS_LEVELS = [
  {
    value: "TEAM",
    label: "Team"
  },
  {
    value: "PERSONAL",
    label: "Personal"
  }
];

export const manifestsStatuses = [
  {
    color: "#27AE60",
    name: "Active",
    value: "APPROVED"
  },
  {
    color: "#F2994A",
    name: "Invited",
    value: "INVITED"
  },
  {
    color: "#EB5757",
    name: "Disabled",
    value: "DISABLED"
  },
  {
    color: "#F2994A",
    name: "Pending confirmation",
    value: "TRANSFERRED"
  }
];

export const userRoleOptions = [  
  { value: UserRoles.USER, label: "User"},
  { value: UserRoles.ADMINISTRATOR, label: "Administrator"},
  { value: UserRoles.AGENT, label: "Agent"},
];

export const userStatusOptions = [
  { value: UserStatuses.APPROVED, label: "Approved"},
  { value: UserStatuses.INVITED, label: "Invited"},
  { value: UserStatuses.DISABLED, label: "Disabled"},
  { value: UserStatuses.TRANSFERRED, label: "Transferred"},
];


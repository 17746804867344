import React from "react";
import binIcon from "../../images/bin_gray.svg";
import { useSelector } from "react-redux";
import { linehaulMileageSelector } from "../../slices/linehaul-mileage/linehaulMileageSlice";

export const getAccessorialColumns = (onDelete: any) => [
  {
    Header: "Type",
    accessor: "type",
    width: 180,
    Cell: (cellProps: any) => (
      <>{cellProps.value.toLowerCase().capitalize()}</>
    )
  },
  {
    Header: "Duration/Amount",
    accessor: "duration",
    width: 160,
    Cell: (cellProps: any) => (
      <>
        {!!cellProps.value ?
          (cellProps.value + "  hrs") :
          ("$ " + cellProps.row.original.amount)}
      </>
    ) 
  },
  {
    Header: "Reason",
    accessor: "reason",
    width: 175,
  },
  {
    Header: "Notes",
    accessor: "notes",
    width: 200
  },
  {
    Header: "",
    accessor: "delete",
    width: 40,
    Cell: (cellProps: any) => {
      const linehaulMileageState = useSelector(linehaulMileageSelector);

      return (
        <>
          <img onClick={() => !linehaulMileageState.requestStarted && onDelete(cellProps.row.original.tripId, cellProps.row.original.exceptionId)} style={linehaulMileageState.requestStarted ? {} : { cursor: "pointer" }} src={binIcon} alt="bin"/>
        </>
      )
    }
  }
]
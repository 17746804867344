import React from "react";
import ShipmentCommentModel from "../../../app/data/tracking/ShipmentCommentModel";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import styles from "./../../../sass/variables.module.scss";

enum CommentTypes {
  General = "G",
}

export interface ShipmentCommentProps {
  comment: ShipmentCommentModel;
}

const ShipmentComment: React.FC<ShipmentCommentProps> = (props) => {
  return (
    <div style={{ display: "flex", flexDirection: "row", marginBottom: 8 }}>
      {props.comment.type === CommentTypes.General && (
        <XGSIcon
          icon={XGSIcons.faCheckCircle}
          style={{ color: styles.blue2 }}
        />
      )}

      <div style={{ marginLeft: 8 }}>
        {props.comment.dateAdded?.toUiDateFormat()} {props.comment.timeAdded}
      </div>
      <div style={{ marginLeft: 16 }}>{props.comment.text}</div>
    </div>
  );
};

export default ShipmentComment;

import { IState } from "../..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../../app/store";
import CustomersService from "../../../app/data/customers/customersService";
import { CreateTeamRequestModel } from "../../../app/data/customers/models";
import { initialCreateTeamState } from "./CreateTeamState";

const customersService = CustomersService.getInstance();

export const createTeamSlice = createSlice({
  name: "createTeam",
  initialState: initialCreateTeamState,
  reducers: {
    request_was_started: (state) => {
      state.request_was_started = true;
      state.request_was_succeed = false;
      state.request_was_failed = false;
      state.request_fail_reason = null;
    },
    request_was_succeed: (state) => {
      state.request_was_succeed = true;
      state.request_was_started = false;
      state.request_was_failed = false;
    },
    request_was_failed: (state, { payload }) => {
      state.request_was_failed = true;
      state.request_was_succeed = false;
      state.request_was_started = false;
      state.request_fail_reason = payload;
    },
    loadingAccountsInfo_started: (state) => {
      state.accountsInfo = null;
      state.accountsInfo_fail_reason = null;
      state.accountsInfo_was_failed = false;
      state.accountsInfo_was_succeed = false;
      state.accountsInfo_was_started = true;
    },
    loadingAccountsInfo_completed: (state, { payload }) => {
      state.accountsInfo = payload;
      state.accountsInfo_fail_reason = null;
      state.accountsInfo_was_failed = false;
      state.accountsInfo_was_succeed = true;
      state.accountsInfo_was_started = false;
    },
    loadingAccountsInfo_failed: (state, { payload }) => {
      state.accountsInfo = null;
      state.accountsInfo_fail_reason = payload;
      state.accountsInfo_was_failed = true;
      state.accountsInfo_was_succeed = false;
      state.accountsInfo_was_started = false;
    },
    clearAccountsInfo: (state) => {
      state.accountsInfo = null;
      state.accountsInfo_fail_reason = null;
      state.accountsInfo_was_failed = false;
      state.accountsInfo_was_succeed = false;
      state.accountsInfo_was_started = false;      
    },
    resetErrors: (state) => {
      state.request_was_failed = false;
      state.request_fail_reason = null;
      state.accountsInfo_was_failed = false;      
      state.accountsInfo_fail_reason = null;
    }
  }
});

export const {
  request_was_started,
  request_was_succeed,
  request_was_failed,
  loadingAccountsInfo_started,
  loadingAccountsInfo_completed,
  loadingAccountsInfo_failed,
  clearAccountsInfo,
  resetErrors
} = createTeamSlice.actions;

export const createTeamSelector = (state: IState) => state.createTeam;

export const sendCreateTeamRequest = (
  request: CreateTeamRequestModel,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(request_was_started());
  const response = await customersService.createTeam(request);
  if (response.ok()) {
    dispatch(request_was_succeed());
    onSuccess && onSuccess();
  } else {
    response.getError && dispatch(request_was_failed(response.getError()));
  }
};

export const getAccountsInfo = (accountNumbers: number[]): AppThunk => async (dispatch) => {
  dispatch(loadingAccountsInfo_started());
  const response = await customersService.getAccountsInfo(accountNumbers);
  if (response.ok()) {
    dispatch(loadingAccountsInfo_completed(response.data));
  } else {
    dispatch(loadingAccountsInfo_failed(response.getError ? response.getError() : "Error"));
  }
};

const createTeamReducer = createTeamSlice.reducer;
export default createTeamReducer;

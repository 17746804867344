import React from "react"
import { ProbillProfile } from "../../../app/data/manifest/manifestProfiles";
import {getExceptionsData, manifestsSelector, setActiveProbill} from "../../../slices/manifest/manifestSlice";
import {useDispatch, useSelector} from "react-redux";
import ManifestsState from "../../../slices/manifest/ManifestsState";
import Exceptions from "./Exceptions";
import {formatDate, formatTime} from "../../../hooks/utils";
import Tag from "../../../ui-components/molecules/tag/tag";
import { TagColor } from "../../../app/data/common/tagColor";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";

export interface  ProbillProps {
  probill: ProbillProfile
  shouldShowStatus?: boolean
}

const Probill: React.FC<ProbillProps> = (props) => {
  const dispatch = useDispatch()
  const manifestsState: ManifestsState = useSelector(manifestsSelector)

  const exceptions = manifestsState.manifestExceptionsData;
  
  const probillStatusCssCLass = (): TagColor => {
    switch (props.probill.probillStatus) {
      case "ALL_ITEMS_EXCEPTION":
      case "PARTIAL_ITEMS_EXCEPTION":
      case "DRIVER_ACK_NEGATIVE":
        return TagColor.RED;

      case "PENDING":
        return TagColor.YELLOW;

      case "DRIVER_ACK":
      case "POD":
        return TagColor.GREEN;

      default:
        return TagColor.GREY;
    };
  }

  const formatStatus = (input: string): string => {
    switch (input) {
      case "ALL_ITEMS_EXCEPTION":
      case "PARTIAL_ITEMS_EXCEPTION":
      case "DRIVER_ACK_NEGATIVE":
        return "SEE EXCEPTIONS";

      case "PENDING":
        return "DELIVERING RIGHT NOW";

      case "DRIVER_ACK":
        return "POD IN PROGRESS";

      case "POD":
        return "POD COMPLETE";

      default:
        return input.toString().toUpperCase();
    };
  }

  const handleExceptionInfoLoad = () => {
    dispatch(getExceptionsData(props.probill.manifestNumber, props.probill.probillNumber))

    dispatch(setActiveProbill(props.probill.probillNumber));
  }  

  return (
    <>
      <a href={"/shipments/" + props.probill.probillNumber} target="_blank" rel="noopener noreferrer"
         className="probill-number">{props.probill.probillNumber}</a>
      {props.shouldShowStatus && !props.probill.probillExceptionCount && (
        <Tag mods={{ color: probillStatusCssCLass() }} mix="probill-status">
          {formatStatus(props.probill.probillStatus)}
          <span className="probill-status__details">
            <ul className="probill-status__details-list">
              <li className="probill-status__details-item">
                <span className="probill-status__details-item__label">POD signed by: </span>
                {props.probill.podSignedByName ?? "No data"}
              </li>

              {!!props.probill.podDeliveredDate && !!props.probill.podDeliveredTime && (
                <li className="probill-status__details-item">
                  <span className="probill-status__details-item__label">POD delivered time: </span>
                  {formatDate(props.probill.podDeliveredDate.toString()) + " " + formatTime(props.probill.podDeliveredTime.toString())}
                </li>
              )}

              {props.probill.podProcessingStatus && props.probill.probillPodSignedByName && (
                <li className="probill-status__details-item">
                  <span className="probill-status__details-item__label">Probill signed by: </span>
                  {props.probill.probillPodSignedByName}
                </li>
              )}

              {props.probill.driverAcknowledgment ? (
                <li className="probill-status__details-item">
                  <span className="probill-status__details-item__label">Driver acknowledgement: </span>
                  {props.probill.driverAcknowledgment === "Y" ? "Positive" : "Negative"}
                </li>
              ) : (<li className="probill-status__details-item">No Activity on the app</li>)}

              {props.probill.driverAcknowledgment && !!props.probill.driverAckDate && !!props.probill.driverAckTime && (
                <li className="probill-status__details-item">
                  <span className="probill-status__details-item__label">Driver acknowledgement time: </span>
                  {formatDate(props.probill.driverAckDate.toString()) + " " + formatTime(props.probill.driverAckTime.toString())}
                </li>
              )}
            </ul>
          </span>
        </Tag>
      )}
      {props.probill.probillStatus !== "PENDING" && !props.probill.driverAcknowledgment && <Tag mods={{ color: TagColor.RED }}>NO APP ACTIVITY</Tag>}
      <span className="probill-items">Items: <b>{props.probill.probillItemsCount}</b></span>
      {props.probill?.probillExceptionCount > 0 && (
        <span className="probill-exceptions" onClick={handleExceptionInfoLoad}>
          Exceptions: <b>{props.probill.probillExceptionCount}</b> <XGSIcon icon={XGSIcons.faExternalLinkAlt} />
        </span>
      )}
      <Exceptions
        probillNumber={props.probill.probillNumber}
        exceptions={exceptions}
        show={manifestsState.activeProbill === props.probill.probillNumber}
        onClose={() => dispatch(setActiveProbill(null))}
      />
    </>
  )
}

export default Probill
import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import FeedbackService from "../../app/data/feedback/feedbackService";
import { initialFeedbackState } from "./FeedbackState";
import { ERROR_MESSAGES } from "../../app/data/common/errorMessages";
import { EmployeeFeedbackRequestModel } from "../../app/data/feedback/models";

const feedbackService = FeedbackService.getInstance();

export const feedbackSlice = createSlice({
  name: "feedback",
  initialState: initialFeedbackState,
  reducers: {
    resetFeedbackState: (state) => initialFeedbackState,
    resetFeedbackErrors: (state) => {
      state.requestFailed = false;
      state.requestError = null;
    },
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestFailed = false;
      state.requestSucceed = false;
      state.requestError = null;
      state.requestCreator = payload;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestFailed = true;
      state.requestSucceed = false;
      state.requestError = payload;
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestFailed = false;
      state.requestSucceed = true;
      state.requestError = null;
    },
    setSubjects: (state, { payload }) => {
      state.subjects = payload;
    }
  }
});

export const {
  resetFeedbackErrors,
  resetFeedbackState,
  requestStarted,
  requestFailed,
  requestSucceed,
  setSubjects
} = feedbackSlice.actions;

export const feedbackSelector = (state: IState) => {
  return state.feedback;
};

export const submitFeedback = (
  data: any,
  onSuccess: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("SUBMIT"));
  const response = await feedbackService.submitFeedback(data);
  if (response.ok()) {
    dispatch(requestSucceed());
    onSuccess();
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

export const submitJiraFeedback = (
  data: EmployeeFeedbackRequestModel,
  onSuccess?: () => void,
  onError?: (error: string) => void,
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("SUBMIT"));
  const response = await feedbackService.submitJiraFeedback(data);
  if (response.ok()) {
    dispatch(requestSucceed());
    onSuccess?.();
  } else {
    const error = response.getError && response.getError();
    const message = typeof error === "string" ? error : ERROR_MESSAGES.COMMON;
    dispatch(requestFailed(message));
    onError?.(message);
  }
};

export const getFeedbackSubjects = (
  onSuccess?: (response: any) => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("GET_SUBJECTS"));
  const response = await feedbackService.getSubjects();
  if (response.ok()) {
    dispatch(setSubjects(response.data));
    dispatch(requestSucceed());
    onSuccess && onSuccess(response.data);
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

const feedbackReducer = feedbackSlice.reducer;
export default feedbackReducer;

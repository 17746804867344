import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import mixpanel from "mixpanel-browser";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import TopBannerState from "../../../slices/customers/top-banner/TopBannerState";
import {
  getTopBanner,
  topBannerSelector
} from "../../../slices/customers/top-banner/topBannerSlice";
import "./topBanner.scss";

const TopBanner: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const topBannerState: TopBannerState = useSelector(topBannerSelector);
  const [showBanner, setShowBanner] = useState<boolean>(false);

  const goUrl = () => {
    if (!topBannerState.banner?.url) return;
    mixpanel.track("Top banner was clicked");
    window.open(topBannerState.banner.url, "_blank");
  };

  const closeBanner = () => {
    if (!topBannerState.banner?.date) return;
    setShowBanner(false);
    localStorage.setItem("xgs-top-banner", topBannerState.banner.date);
  };

  useEffect(() => {
    dispatch(getTopBanner());
  }, [dispatch]);

  useEffect(() => {
    if (!topBannerState.banner) return;
    const date = localStorage.getItem("xgs-top-banner");
    if (!date || date !== topBannerState.banner.date) setShowBanner(true);
  }, [topBannerState.banner]);

  return (
    <>
      {topBannerState.requestSucceed && topBannerState.banner?.message && topBannerState.banner?.url && showBanner && (
        <div className="xgs-top-banner xgs-animation__shaking">
          <div className="xgs-top-banner__text">{topBannerState.banner.message}</div>
          <Button
            theme={ButtonThemes.white}
            type="button"
            className="xgs-top-banner__button"
            onClick={goUrl}
          >
            Read More
          </Button>
          <XGSIcon
            icon={XGSIcons.faTimes}
            className="xgs-top-banner__close"
            onClick={closeBanner}
          />
        </div>
      )}
    </>
  );
};

export default TopBanner;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../ui-components/table/table";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import LabeledTextInput from "../../../ui-components/molecules/labeled-inputs/labeled-text-input/labeledTextInput";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import ContentContainer from "../../../templates/content-container/contentContainer";
import EmployeesState from "../../../slices/admin/employees/EmployeesState";
import {
  employeesSelector,
  getEmployees,
  resetErrors
} from "../../../slices/admin/employees/employeesSlice";
import InviteUser from "../../invite-user/inviteUser";
import EmployeesDetails from "./employeesDetails";
import UserProfile from "../../../app/data/user/UserProfile";
import { employeeRoles, employeeStatuses } from "./constants";
import "./employees.scss";

const Employees: React.FC<{}> = () => {
  const [userId, setUserId] = useState<string | undefined>();
  const dispatch = useDispatch();
  const employeesState: EmployeesState = useSelector(employeesSelector);
  const [showInviteUser, setShowInviteUser] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>("");

  const columns = [
    {
      width: 120,
      Header: "Sign-up date",
      accessor: "dateCreated",
      Cell: (cellProps: any) => (
        <>
          { cellProps.value.toUiDateFormat() }
        </>
      )
    },
    {
      width: 200,
      Header: "Name",
      accessor: "name",
      Cell: (cellProps: any) => (
        <>
          { cellProps.row.original.firstName }{" "}{ cellProps.row.original.lastName }
        </>
      )
    },
    {
      width: 140,
      Header: "Title",
      accessor: "title"
    },
    {
      Header: "Email",
      accessor: "email",
      width: 260,
      Cell: (cellProps: any) => (
        <>
          {cellProps.value}
          {cellProps.row.original.pendingEmail && (cellProps.value !== cellProps.row.original.pendingEmail) && (
            <>
              <br />
              <span className="xgs-employees__list__pending-email">({
                cellProps.row.original.pendingEmail.length > 25 ? cellProps.row.original.pendingEmail.substring(0, 25) + "..." : cellProps.row.original.pendingEmail
              })</span>
            </>
          )}
        </>
      )
    },
    {
      Header: "Phone",
      accessor: "phoneNumber",
      width: 140
    },
    {
      Header: "Role",
      accessor: "role",
      width: 120,
      Cell: (cellProps: any) => (
        <>
          { employeeRoles.find(role => role.value === cellProps.value)?.label }
        </>
      )
    },
    {
      Header: (
        <div style={{ textAlign: "center" }}>
          Status
        </div>
      ),      
      accessor: "status",
      width: 70,
      Cell: (cellProps: any) => (
        <div
          className="xgs-company__users__details__status__circle"
          style={{
            backgroundColor: employeeStatuses.find(status => status.value === cellProps.value)?.color,
            marginLeft: "auto",
            marginRight: "auto"
          }}
          title={cellProps.value.toLowerCase()}>
        </div>
      )
    }
  ];

  const onListInfiniteScroll = () => {
    !employeesState.employeesFetchedAll && dispatch(getEmployees(employeesState.employeesRequest.lastIds, undefined));
  };

  const onUserClick = (row: UserProfile) => {
    setUserId(row.id)
  };

  const onSearchStart = () => {
    dispatch(getEmployees("", searchString));
    setUserId(undefined);
  };

  const onSearchClear = () => {
    setSearchString("");
    dispatch(getEmployees("", undefined));
    setUserId(undefined);
  };

  useEffect(() => {
    dispatch(resetErrors());
    dispatch(getEmployees("", undefined));

    return () => {
      dispatch(resetErrors());
    }
  }, [dispatch]);

  return (
    <ContentContainer title="Employees">
      <div className="xgs-employees">
        <div className="xgs-list__controls">
          <div className="xgs-list__controls__search">
            <LabeledTextInput
              labelMode={LabelModes.column}
              className="xgs-list__controls__search__input"
              value={searchString}
              onChange={(e) => setSearchString(e.currentTarget.value)}
              onKeyDown={(e) => (e.key === "Enter") && !employeesState.fetch_was_started && onSearchStart()}
              type="text"
              placeholder="First, last name, email"
            />
            <Button
              className="xgs-list__controls__button"
              theme={ButtonThemes.blue}
              disabled={employeesState.fetch_was_started}
              onClick={onSearchStart}>
              Search
            </Button>
            <Button
              className="xgs-list__controls__button"
              theme={ButtonThemes.gray}
              onClick={onSearchClear}>
              Clear
            </Button>
          </div>
          <div className="xgs-list__controls__actions">
            <Button
              className="xgs-employees__invite-button"
              theme={ButtonThemes.blue}
              onClick={() => { setShowInviteUser(true); }}>
              Invite new user
            </Button>
            <InviteUser
              employee={true}
              show={showInviteUser}
              onInviteSent={() => {
                dispatch(getEmployees("", undefined));
              }}          
              onClose={() => {
                setShowInviteUser(false);
              }}
            />          
          </div>
        </div>
        {employeesState.fetch_was_failed && (
          <XGSErrorMessage>{employeesState.fetch_fail_reason}</XGSErrorMessage>
        )}
        {!employeesState.fetch_was_failed && (
          <Table
            isLoading={employeesState.fetch_was_started}
            columns={columns}
            data={employeesState.employees}
            onRowClicked={onUserClick}
            cursorPointer={true}
            rowHeight={66}
            minTableHeight={420}
            noResultsText="There are no users"
            infiniteScroll={true}
            infiniteScrollLoading={employeesState.fetchPortion_was_started}
            infiniteScrollHasNext={!employeesState.employeesFetchedAll}
            onInfiniteScroll={onListInfiniteScroll}
          />
        )}
        <EmployeesDetails
          id={userId}
          show={!!userId}
          onClose={() => {
            setUserId(undefined);
          }}
          onDelete={() => {
            dispatch(getEmployees("", searchString));
          }}
        />
      </div>
    </ContentContainer>
  );
};

export default Employees;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import SlideOutSidebar from "../../../ui-components/slide-out-sidebar/slideOutSidebar";
import Loading from "../../../ui-components/loading/loading";
import ThreeDotsMenu from "../../../ui-components/three-dots-menu/threeDotsMenu";
import {
  getDriverById,
  getPickupLog,
  pickupAssignmentSelector,
  updatePickup
} from "../../../slices/pickup/pickupAssignmentSlice";
import { userSelector } from "../../../slices/user/userSlice";
import { PickupStatus } from "../../../app/data/common/route";
import boxIcon from "../../../images/box.svg";
import buildingIcon from "../../../images/building.svg";
import calendarIcon from "../../../images/calendar.svg";
import checkIcon from "../../../images/check_gray.svg";
import check2Icon from "../../../images/check_gray-2.svg";
import crossRedIcon from "../../../images/cross_red.svg";
import crossGrayIcon from "../../../images/cross_gray.svg";
import emailIcon from "../../../images/letter_gray.svg";
import imagePreview from "../../../images/image-preview.png";
import newWindow from "../../../images/new-window_gray.svg";
import personIcon from "../../../images/person_blue.svg";
import phoneIcon from "../../../images/phone_gray.svg";
import pointIcon from "../../../images/point.svg";
import clockIcon from "../../../images/clock_blue.svg";
import calendarDotsIcon from "../../../images/calendar-dots_blue.svg";
import infoIcon from "../../../images/info_red.svg";
import PickupDetailsBlock from "./pickupDetailsBlock";
import PickupDetailsLog from "./pickupDetailsLog";
import PickupReschedule from "./pickupReschedule";
import PickupCancel from "./pickupCancel";
import { getPickupById, getStatusColor } from "../../../services/pickups";
import PickupComplete from "./pickupComplete";
import Tag from "../../../ui-components/molecules/tag/tag";
import { TagColor } from "../../../app/data/common/tagColor";

export interface PickupDetailsProps {
  pickupId: string;
  onClose: () => void;
  showReschedule: boolean;
};

const PickupDetails: React.FC<PickupDetailsProps> = (props) => {
  const dispatch = useDispatch();
  const pickupAssignmentState = useSelector(pickupAssignmentSelector);
  const userState = useSelector(userSelector);
  const [pickupData, setPickupData] = useState();
  const [driverName, setDriverName] = useState("");
  const [showReschedulePickup, setShowReschedulePickup] = useState(false);
  const [rescheduledPU, setRescheduledPU] = useState<string | null>(null);
  const [showCancelPickup, setShowCancelPickup] = useState(false);
  const [showCompletePickup, setShowCompletePickup] = useState(false);

  const isAssignedPickup = () => {
    return pickupData.pickup.pickupStatus === PickupStatus.ASSIGNED;
  };

  const isClosedPickup = () => {
    return (pickupData.pickup.pickupStatus === PickupStatus.COMPLETED) || (pickupData.pickup.pickupStatus === PickupStatus.CANCELED);
  };

  const isTodayPickup = () => {
    return moment(pickupData.pickupFullData.pickupRequest.pickupDate).startOf("day").isSame(moment().startOf("day"));
  };

  const pickupTimeHasCome = () => {
    return !(moment(pickupData.pickupFullData.pickupRequest.pickupDate).startOf("day").isAfter(moment().startOf("day")));
  };

  const isAtRisk = () => {
    if (isClosedPickup()) return false;
    if (isTodayPickup()) {
      return moment().isAfter(moment(pickupData.pickupFullData.pickupRequest.pickupCloseTime, "hh:mm:ss").subtract(3, "hours"))
        && moment().isBefore(moment(pickupData.pickupFullData.pickupRequest.pickupCloseTime, "hh:mm:ss"));
    } else {
      return false;
    }
  };

  const isExpired = () => {
    if (isClosedPickup()) return false;
    let result;
    if (isTodayPickup()) {
      result = moment().isAfter(moment(pickupData.pickupFullData.pickupRequest.pickupCloseTime, "hh:mm:ss"));
    } else if (moment(pickupData.pickupFullData.pickupRequest.pickupDate).startOf("day").isBefore(moment().startOf("day"))) {
      result = true;
    } else {
      result = false;
    }
    return result;
  };

  useEffect(() => {
    setShowCancelPickup(false);
    let pickupObj = getPickupById(pickupAssignmentState.pickups, props.pickupId);
    setPickupData(pickupObj || null);
    pickupObj?.pickup.driverId && dispatch(getDriverById(pickupObj.pickup.driverId, (driverName) => setDriverName(driverName), true));
  }, [pickupAssignmentState.pickups, props.pickupId, dispatch]);

  useEffect(() => {
    setShowCancelPickup(false);
    setShowReschedulePickup(false);
    setShowCompletePickup(false);
    setRescheduledPU(null);
  }, [props.pickupId]);

  useEffect(() => {
    if (!props.showReschedule) return;
    setShowReschedulePickup(true);
  }, [props.showReschedule]);

  return (
    <SlideOutSidebar
      header="Pickup Details"
      onClose={props.onClose}
      show={!!props.pickupId}
    >
      <div className="xgs-pickup-assignment__details">
        {pickupData && (
          <>
            <div className="xgs-pickup-assignment__details__header">
              <div>
                <div className="xgs-pickup-assignment__details__pickup-number">Pickup: {pickupData.pickup.pickupNumber}</div>
                {pickupData.pickupFullData.pickupRequest.probillNumber && (
                  <div className="xgs-pickup-assignment__details__probill-number">
                    <div className="xgs-pickup-assignment__details__probill-number__text">Probill: {pickupData.pickupFullData.pickupRequest.probillNumber}</div>
                    <div className="xgs-pickup-assignment__details__probill-number__link">
                      <a href={`/shipments/${pickupData.pickupFullData.pickupRequest.probillNumber}`} target="_blank" rel="noreferrer">
                        <img src={newWindow} alt="open in new window" />
                      </a>
                    </div>
                  </div>
                )}
              </div>
              <div className="xgs-pickup-assignment__details__status">Current Status: <Tag mods={{color: getStatusColor(pickupData.pickup.pickupStatus)}}>{pickupData.pickup.pickupStatus}</Tag></div>
            </div>
            <div className="xgs-pickup-assignment__details__subheader">
              <div className="xgs-pickup-assignment__details__pickup-date">
                {isAtRisk() && <Tag mods={{ color: TagColor.ORANGE, uppercase: true }} mix="xgs-pickup-assignment__details__pickup-date__label">AT RISK</Tag>}
                {isExpired() && !pickupData.pickup.missed && <Tag mods={{ color: TagColor.RED, uppercase: true }} mix="xgs-pickup-assignment__details__pickup-date__label">EXPIRED</Tag>}
                {pickupData.pickup.missed && <div className="xgs-pickup-assignment__details__pickup-date__label">MISSED</div>}
                <div className="xgs-pickup-assignment__details__pickup-date__icon">
                  <img src={calendarIcon} alt="calendar" />
                </div>
                <div className="xgs-pickup-assignment__details__pickup-date__value">
                  {pickupData.pickupFullData.pickupRequest.pickupDate.toUiDateFormat()} {moment(pickupData.pickupFullData.pickupRequest.pickupReadyTime, "hh:mm:ss").format("h:mm A")} - {moment(pickupData.pickupFullData.pickupRequest.pickupCloseTime, "hh:mm:ss").format("h:mm A")}
                </div>
              </div>
              <div className="xgs-pickup-assignment__details__actions">
                {!isClosedPickup() && (
                  <>
                    <div
                      className="xgs-pickup-assignment__details__action"
                      onClick={() => setShowReschedulePickup(true)}
                    >
                      <div className="xgs-pickup-assignment__details__action__icon">
                        <img src={calendarDotsIcon} alt="reschedule" />
                      </div>
                      <div className="xgs-pickup-assignment__details__action__text">
                        Reschedule
                      </div>
                    </div>
                    <ThreeDotsMenu
                      menuItems={[
                        ...((isAssignedPickup() && pickupTimeHasCome())
                          ? [{
                            text: "Complete Pickup",
                            icon: <img src={check2Icon} alt="complete" />,
                            onClick: () => setShowCompletePickup(true)
                          }]
                          : []),
                        {
                          text: "Cancel Pickup",
                          icon: <img src={crossGrayIcon} alt="cancel" />,
                          onClick: () => setShowCancelPickup(true)
                        }
                      ]}
                    />
                  </>
                )}
              </div>
            </div>
            {rescheduledPU && (
              <div className="xgs-pickup-assignment__details__rescheduled">
                <div className="xgs-pickup-assignment__details__rescheduled__header"><img src={infoIcon} alt="info" /> Pickup Rescheduled</div>
                <div className="xgs-pickup-assignment__details__rescheduled__text">
                  This Pickup was rescheduled to new one. New pickup number is {rescheduledPU}.<br />
                  The customer should have received an email notifying this.
                </div>
              </div>
            )}
            <div className="xgs-pickup-assignment__details__section">
              <div className="xgs-pickup-assignment__details__section__line">
                <div className="xgs-pickup-assignment__details__section__icon">
                  <img src={buildingIcon} alt="building" />
                </div>
                <div className="xgs-pickup-assignment__details__section__header">
                  Pickup From
                </div>
              </div>
              <div className="xgs-pickup-assignment__details__section__content">
                <div className="xgs-pickup-assignment__details__section__content__line">
                  <PickupDetailsBlock
                    header="Shipper Location"
                    content={[
                      {
                        label: "Company",
                        value: pickupData.pickupFullData.pickupRequest.shipperName
                      },
                      {
                        label: "Address",
                        value: pickupData.pickupFullData.pickupRequest.shipperAddress + ", " + pickupData.pickupFullData.pickupRequest.shipperCity + ", " + pickupData.pickupFullData.pickupRequest.shipperState + ", " + pickupData.pickupFullData.pickupRequest.shipperZip
                      }
                    ]}
                    oneLine
                  />
                </div>
                <div className="xgs-pickup-assignment__details__section__content__line">
                  <PickupDetailsBlock
                    header="Shipper Contact"
                    content={[
                      ...((pickupData.pickupFullData.pickupRequest.shipperFirstName || pickupData.pickupFullData.pickupRequest.shipperLastName) ? [
                        {
                          label: <div className="xgs-pickup-assignment__details__value-icon"><img src={personIcon} alt="person" /></div>,
                          value: (pickupData.pickupFullData.pickupRequest.shipperFirstName || "") + " " + (pickupData.pickupFullData.pickupRequest.shipperLastName || "")
                        }
                      ] : []),
                      ...(pickupData.pickupFullData.pickupRequest.shipperMobilePhone ? [
                        {
                          label: <div className="xgs-pickup-assignment__details__value-icon"><img src={phoneIcon} alt="phone" /></div>,
                          value: pickupData.pickupFullData.pickupRequest.shipperMobilePhone
                        }
                      ] : []),
                      ...((pickupData.pickupFullData.pickupRequest.shipperAllowSms !== null) ? [
                        {
                          label: pickupData.pickupFullData.pickupRequest.shipperAllowSms
                            ? <div className="xgs-pickup-assignment__details__value-icon"><img src={checkIcon} alt="sms yes" /></div>
                            : <div className="xgs-pickup-assignment__details__value-icon"><img src={crossRedIcon} alt="sms no" /></div>,
                          value: pickupData.pickupFullData.pickupRequest.shipperAllowSms ? "SMS allowed" : "SMS not allowed"
                        }
                      ] : []),
                      {
                        label: <div className="xgs-pickup-assignment__details__value-icon"><img src={emailIcon} alt="email" /></div>,
                        value: pickupData.pickupFullData.pickupRequest.shipperEmail
                      },
                    ]}
                    oneLine
                  />
                </div>
              </div>
            </div>
            <div className="xgs-pickup-assignment__details__section">
              <div className="xgs-pickup-assignment__details__section__line">
                <div className="xgs-pickup-assignment__details__section__icon">
                  <img src={pointIcon} alt="point" />
                </div>
                <div className="xgs-pickup-assignment__details__section__header">
                  Deliver To
                </div>
              </div>
              <div className="xgs-pickup-assignment__details__section__content">
                <PickupDetailsBlock
                  header="Consignee Details"
                  content={[
                    {
                      label: "Company",
                      value: pickupData.pickupFullData.pickupRequest.consigneeName
                    },
                    {
                      label: "Address",
                      value: pickupData.pickupFullData.pickupRequest.consigneeAddress1 + ", " + pickupData.pickupFullData.pickupRequest.consigneeCity + ", " + pickupData.pickupFullData.pickupRequest.consigneeState + ", " + pickupData.pickupFullData.pickupRequest.consigneePostalCode
                    },
                    {
                      label: "Phone",
                      value: pickupData.pickupFullData.pickupRequest.consigneePhone
                    },
                    ...(pickupData.pickupFullData.pickupRequest.consigneeEmail ? [
                      {
                        label: "Email",
                        value: (<a href={`mailto:${pickupData.pickupFullData.pickupRequest.consigneeEmail}`}>{pickupData.pickupFullData.pickupRequest.consigneeEmail}</a>)
                      }
                    ] : [])
                  ]}
                  oneLine
                />
              </div>
            </div>
            <div className="xgs-pickup-assignment__details__section">
              <div className="xgs-pickup-assignment__details__section__line">
                <div className="xgs-pickup-assignment__details__section__icon">
                  <img src={calendarIcon} alt="calendar" />
                </div>
                <div className="xgs-pickup-assignment__details__section__header">
                  Pickup Info
                </div>
              </div>
              <div className="xgs-pickup-assignment__details__section__content">
                <div className="xgs-pickup-assignment__details__grid">
                  <div className="xgs-pickup-assignment__details__grid__item">
                    <PickupDetailsBlock
                      header="Pickup Details"
                      content={[
                        {
                          label: "Pickup Date",
                          value: (
                            <span style={{ color: (!isTodayPickup() && (isExpired() || isAtRisk())) ? "#C43232" : "inherit" }}>
                              {pickupData.pickupFullData.pickupRequest.pickupDate.toUiDateFormat()}
                            </span>
                          )
                        },
                        {
                          label: "Pickup Time",
                          value: (
                            <span style={{ color: (isExpired() || isAtRisk()) ? "#C43232" : "inherit" }}>
                              {moment(pickupData.pickup.readyTime, "hh:mm:ss").format("h:mm A") + " -  " + moment(pickupData.pickup.closeTime, "hh:mm:ss").format("h:mm A")}
                            </span>
                          )
                        },
                        ...pickupData.pickup.driverId ? [{
                          label: "Assigned Driver",
                          value: (
                            <>
                              {pickupAssignmentState.requestStarted && pickupAssignmentState.requestCreator === "GET_DRIVER" && (
                                <div className="xgs-pickup-assignment__details__value-spinner">
                                  <Loading isLoading />
                                </div>
                              )}
                              {driverName
                                ? (<>{driverName} <span>({pickupData.pickupFullData.pickupRequest.driverId})</span></>)
                                : pickupData.pickupFullData.pickupRequest.driverId}
                            </>
                          )
                        }] : []
                      ]}
                    />
                  </div>
                  <div className="xgs-pickup-assignment__details__grid__item">
                    <PickupDetailsBlock
                      header="Request Details"
                      content={[
                        {
                          label: "Pickup Created",
                          value: pickupData.pickupFullData.pickupRequest.dateCreated.toUiDateTimeShortFormat()
                        },
                        ...(pickupData.pickupFullData.pickupRequest.userCreated ? [{
                            label: "Created By",
                            value: pickupData.pickupFullData.pickupRequest.userCreated
                          }] : []
                        ),
                        {
                          label: "Source",
                          value: pickupData.pickupFullData.pickupRequest.creatorType
                        },
                        ...(pickupData.pickupFullData.pickupRequest.userUpdated ? [{
                            label: "Last Status Update",
                            value: pickupData.pickupFullData.pickupRequest.dateUpdated.toUiDateTimeShortFormat()
                          },
                          {
                            label: "Updated By",
                            value: pickupData.pickupFullData.pickupRequest.userUpdated
                          }] : []
                        )
                      ]}
                    />
                  </div>
                  {pickupData.pickupFullData.pickupRequest.payorName && (
                    <div className="xgs-pickup-assignment__details__grid__item">
                      <PickupDetailsBlock
                        header="Payor Details"
                        content={[
                          {
                            label: "Company",
                            value: pickupData.pickupFullData.pickupRequest.payorName
                          },
                          {
                            label: "Address",
                            value: pickupData.pickupFullData.pickupRequest.payorAddress + ", " + pickupData.pickupFullData.pickupRequest.payorCity + ", " + pickupData.pickupFullData.pickupRequest.payorState + ", " + pickupData.pickupFullData.pickupRequest.payorZip
                          },
                          {
                            label: "Phone",
                            value: pickupData.pickupFullData.pickupRequest.payorPhone
                          }
                        ]}
                      />
                    </div>
                  )}
                  {(pickupData.pickupFullData.pickupRequest.bolNumber || pickupData.pickupFullData.pickupRequest.poNumber || pickupData.pickupFullData.pickupRequest.additionalReference) && (
                    <div className="xgs-pickup-assignment__details__grid__item">
                      <PickupDetailsBlock
                        header="Additional Information"
                        content={[
                          ...(pickupData.pickupFullData.pickupRequest.bolNumber ? [
                            {
                              label: "BOL #",
                              value: pickupData.pickupFullData.pickupRequest.bolNumber
                            }
                          ] : []),
                          ...(pickupData.pickupFullData.pickupRequest.poNumber ? [
                            {
                              label: "PO #",
                              value: pickupData.pickupFullData.pickupRequest.poNumber
                            }
                          ] : []),
                          ...(pickupData.pickupFullData.pickupRequest.additionalReference ? [
                            {
                              label: "Add. Ref.",
                              value: pickupData.pickupFullData.pickupRequest.additionalReference
                            }
                          ] : [])
                        ]}
                      />
                    </div>
                  )}
                  {pickupData.pickupFullData.pickupRequest.specialInstructions && (
                    <div className="xgs-pickup-assignment__details__grid__item">
                      <PickupDetailsBlock
                        header="Special Instructions"
                        content={[
                          {
                            value: pickupData.pickupFullData.pickupRequest.specialInstructions
                          }
                        ]}
                      />
                    </div>
                  )}
                  {(pickupData.pickupFullData.pickupRequest.documents && pickupData.pickupFullData.pickupRequest.documents.length > 0) && (
                    <div className="xgs-pickup-assignment__details__grid__item">
                      <PickupDetailsBlock
                        header="Documents"
                        content={pickupData.pickupFullData.pickupRequest.documents.map((item) => {
                          return {
                            value: (
                              <div className="xgs-pickup-assignment__details__document">
                                <a href={item.storageUrl} target="_blank" rel="noreferrer">
                                  <img src={imagePreview} alt="preview" />
                                  {item.type === "BOL_PHOTO" ? "BOL" : "Misc"}
                                </a>
                              </div>
                            )
                          }
                        })}
                        oneline
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="xgs-pickup-assignment__details__section">
              <div className="xgs-pickup-assignment__details__section__line">
                <div className="xgs-pickup-assignment__details__section__icon">
                  <img src={boxIcon} alt="box" />
                </div>
                <div className="xgs-pickup-assignment__details__section__header">
                  Freight Details
                </div>
              </div>
              <div className="xgs-pickup-assignment__details__section__content">
                <div className="xgs-pickup-assignment__details__items">
                  {pickupData.pickupFullData.pickupRequest.items.map((item, index) => (
                    <div className="xgs-pickup-assignment__details__items__row" key={"item-" + index}>
                      <div className="xgs-pickup-assignment__details__items__row__line xgs-pickup-assignment__details__items__row__line--flex">
                        <Tag mods={{color: item.packageType === "ROLL" ? TagColor.BLUE : TagColor.PURPLE}} mix="xgs-pickup-assignment__details__package-type">
                          {item.packageType}
                        </Tag>
                        <div>
                          <span>Units:</span> {item.units}
                        </div>
                        {!!(item.yards && item.yards !== 0) && (
                          <div>
                            <span>Sq Yards:</span> {item.yards}
                          </div>
                        )}
                        <div>
                          <span>Pounds:</span> {item.weight}
                        </div>
                        {(item.length || item.width || item.height) && (
                          <div>
                            <span>Length / Width / Height:</span> {item.length || "-"} / {item.width || "-"} / {item.height || "-"} in.
                          </div>
                        )}
                        {item.ratingClass && (
                          <div>
                            <span>Class:</span> {item.ratingClass}
                          </div>
                        )}
                      </div>
                      {item.notes && (
                        <div className="xgs-pickup-assignment__details__items__row__line">
                          <span>Notes:</span> {item.notes}
                        </div>
                      )}
                      {item.sidemark && (
                        <div className="xgs-pickup-assignment__details__items__row__line">
                          <span>Sidemark:</span> {item.sidemark}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="xgs-pickup-assignment__details__section">
              <div className="xgs-pickup-assignment__details__section__line">
                <div className="xgs-pickup-assignment__details__section__icon">
                  <img src={clockIcon} alt="clock" />
                </div>
                <div className="xgs-pickup-assignment__details__section__header">
                  Activity History
                </div>
              </div>
              <div className="xgs-pickup-assignment__details__section__content">
                <PickupDetailsLog
                  pickupId={props.pickupId}
                  onRescheduled={(pickupNumber) => setRescheduledPU(pickupNumber)}
                />
              </div>
            </div>
          </>
        )}
      </div>
      {pickupData && (
        <>
          <PickupReschedule
            show={showReschedulePickup}
            pickupId={props.pickupId}
            pickupNumber={pickupData.pickup.pickupNumber}
            shipperZip={pickupData.pickupFullData.pickupRequest.shipperZip}
            onReschedule={() => {
              dispatch(updatePickup({
                pickupId: props.pickupId,
                status: PickupStatus.CANCELED,
                username: userState.profile.email
              }));
              dispatch(getPickupLog(props.pickupId));
              setShowReschedulePickup(false);
            }}
            onClose={() => setShowReschedulePickup(false)}
          />
          <PickupCancel
            show={showCancelPickup}
            pickupId={props.pickupId}
            pickupNumber={pickupData.pickup.pickupNumber}
            shipper={{
              firstName: pickupData.pickupFullData.pickupRequest.shipperFirstName,
              lastName: pickupData.pickupFullData.pickupRequest.shipperLastName,
              phoneNumber: pickupData.pickupFullData.pickupRequest.shipperMobilePhone,
              allowSms: pickupData.pickupFullData.pickupRequest.shipperAllowSms,
              email: pickupData.pickupFullData.pickupRequest.shipperEmail
            }}
            onCancel={() => {
              dispatch(updatePickup({
                pickupId: props.pickupId,
                status: PickupStatus.CANCELED,
                username: userState.profile.email
              }));
              dispatch(getPickupLog(props.pickupId));
              setShowCancelPickup(false);
            }}
            onClose={() => setShowCancelPickup(false)}
            type="EMPLOYEE"
          />
          <PickupComplete
            show={showCompletePickup}
            pickupId={props.pickupId}
            pickupNumber={pickupData.pickup.pickupNumber}
            onComplete={() => {
              dispatch(updatePickup({
                pickupId: props.pickupId,
                status: PickupStatus.COMPLETED,
                username: userState.profile.email,
                driverId: pickupData.pickupFullData.pickupRequest.driverId,
                manifestNumber: pickupData.pickupFullData.pickupRequest.manifestNumber
              }));
              dispatch(getPickupLog(props.pickupId));
              setShowCompletePickup(false);
            }}
            onClose={() => setShowCompletePickup(false)}
          />
        </>
      )}
    </SlideOutSidebar>
  );
};

export default PickupDetails;

import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import AddressLookup from "../../../../ui-components/address-lookup/addressLookup";
import XGSFormInput from "../../../../ui-components/form/input/xgsFormInput";
import XGSFormPhoneInput from "../../../../ui-components/form/phoneInput/xgsFormPhoneInput";
import XGSFormSelect from "../../../../ui-components/form/select/xgsFormSelect";
import LabeledSelectInput from "../../../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import { XGSSelectOption } from "../../../../ui-components/xgs-select/xgsSelect";
import { LabelModes } from "../../../../ui-components/molecules/labeled-inputs/labeledInput";
import Button, { ButtonThemes } from "../../../../ui-components/button/button";
import {
  ReturnConsigneeModel,
  ReturnConsigneeSchema
} from "../../../../app/data/return/models";
import { StepProps } from "../../../../app/data/common/models";
import ReturnState from "../../../../slices/return/ReturnState";
import {
  getMillsList,
  returnSelector,
  setReturnConsignee
} from "../../../../slices/return/returnSlice";
import { usaStates } from "../../../../app/data/common/usaStates";
import "../../bol.scss";

const ReturnConsigneeStep: React.FC<StepProps> = (props) => {
  const { previous, next } = props;
  const returnState: ReturnState = useSelector(returnSelector);
  const [stateValue, setStateValue] = useState<XGSSelectOption | null>();
  const [addressLookupValue, setAddressLookupValue] = useState<any>("");
  const [phoneFieldValue, setPhoneFieldValue] = useState<string>("");
  const dispatch = useDispatch();
  const consigneeFormRef = useRef<any>(null);
  const [millFormValue, setMillFormValue] = useState<XGSSelectOption | null>();

  let initialValues: ReturnConsigneeModel = {
    name: "",
    address: {
      address1: "",
      city: "",
      postalCode: "",
      state: ""
    },
    phone: "",
    email: ""
  };

  const onClickBack = (data: ReturnConsigneeModel) => {
    dispatch(setReturnConsignee(data));
    previous && previous();
  };

  const onClickNext = (data: ReturnConsigneeModel) => {
    dispatch(setReturnConsignee(data));
    next && next();
  };

  const mills = () => {
    let options: XGSSelectOption[] = [];
    if (returnState.mills.length > 0) {
      for (let i = 0; i < returnState.mills.length; i++) {
        options.push({
          label: returnState.mills[i].name,
          subtitle: `${returnState.mills[i].address.city}, ${returnState.mills[i].address.state}`,
          value: `${i}`
        });
      }
    }
    return options;
  };

  const onMillSelect = (v: XGSSelectOption | null | undefined) => {
    setMillFormValue(v);
    if (!v?.value || v.value === "-1" || !returnState.mills || returnState.mills.length === 0) return;
    let index = Number(v.value);
    let mill = returnState.mills[index];
    consigneeFormRef.current?.resetForm();
    consigneeFormRef.current?.setFieldValue("name", mill.name);
    consigneeFormRef.current?.setFieldValue("phone", mill.phone.formatPhone());
    setPhoneFieldValue(mill.phone.formatPhone());
    consigneeFormRef.current?.setFieldValue("email", mill.email);
    consigneeFormRef.current?.setFieldValue("address.address1", mill.address.address1);
    consigneeFormRef.current?.setFieldValue("address.city", mill.address.city);
    consigneeFormRef.current?.setFieldValue("address.postalCode", mill.address.postalCode);
    consigneeFormRef.current?.setFieldValue("address.state", mill.address.state);
    const stateOption = usaStates.find(state => state.value ===  mill.address.state);
    setStateValue(stateOption || null);
    setAddressLookupValue({
      label: "",
      value: ""
    });
    setTimeout(() => {
      consigneeFormRef.current?.validateForm();
    }, 50);
  };

  useEffect(() => {
    consigneeFormRef.current?.setFieldValue("name", returnState.returnConsignee.name);
    consigneeFormRef.current?.setFieldValue("address.address1", returnState.returnConsignee.address.address1);
    consigneeFormRef.current?.setFieldValue("address.city", returnState.returnConsignee.address.city);
    consigneeFormRef.current?.setFieldValue("address.postalCode", returnState.returnConsignee.address.postalCode);
    consigneeFormRef.current?.setFieldValue("address.state", returnState.returnConsignee.address.state);
    const stateOption = usaStates.find(state => state.value === returnState.returnConsignee.address.state);
    stateOption && setStateValue(stateOption);
    consigneeFormRef.current?.setFieldValue("phone", returnState.returnConsignee.phone);
    setPhoneFieldValue(returnState.returnConsignee.phone);
    consigneeFormRef.current?.setFieldValue("email", returnState.returnConsignee.email);
    if (returnState.returnConsignee.address.address1) {
      setAddressLookupValue({
        label: `${returnState.returnConsignee.address.address1}, ${returnState.returnConsignee.address.city}, ${returnState.returnConsignee.address.state}, ${returnState.returnConsignee.address.postalCode}`,
        value: {
          address: returnState.returnConsignee.address.address1,
          city: returnState.returnConsignee.address.city,
          state: returnState.returnConsignee.address.state,
          zip: returnState.returnConsignee.address.postalCode
        }
      });
    }
    setTimeout(() => {
      consigneeFormRef.current?.validateForm();
    }, 50);
  }, [returnState.returnConsignee]);

  useEffect(() => {
    if (returnState.mills.length > 0) return;
    dispatch(getMillsList());
  }, [dispatch, returnState.mills]);

  return (
    <div className="xgs-bol__return__step">
      <Formik
        onSubmit={onClickNext}
        initialValues={initialValues}
        validationSchema={ReturnConsigneeSchema}
        innerRef={consigneeFormRef}
        validateOnMount
        enableReinitialize
      >
        {(props: FormikProps<ReturnConsigneeModel>) => (
          <Form>
            <div className="xgs-bol__section">Return to Location</div>
            <LabeledSelectInput
              name="consignee"
              label="Load Previous Mill:"
              placeholder="Select..."
              isSearchable={false}
              labelMode={LabelModes.column}
              value={millFormValue}
              onValueChange={onMillSelect}
              options={mills()}
              required={false}
              requiredAsteriskDisabled={true}
              formik={true}
              className="xgs-bol__field xgs-bol__field--gray-bg"
              isLoading={returnState.requestStarted && returnState.requestCreator === "GET_MILLS"}
            />
            <XGSFormInput
              type="text"
              name="name"
              label="Mill or Vendor:"
              required={true}
              requiredAsteriskDisabled={false}
              labelMode={LabelModes.column}
              className="xgs-bol__field"
            />
            <div className="xgs-bol__address-info">
              <AddressLookup
                defaultValue={addressLookupValue}
                onValueChange={(val) => {
                  props.setFieldValue("address.address1", val.address);
                  props.setFieldValue("address.city", val.city);
                  props.setFieldValue("address.state", val.state);
                  const stateOption = usaStates.find(state => state.value === val.state);
                  stateOption && setStateValue(stateOption);
                  props.setFieldValue("address.postalCode", val.zip);
                  setTimeout(() => {
                    props.setFieldTouched("address.address1", true, true);
                  }, 100);
                }}
                onBlur={() => {
                  props.setFieldTouched("address.address1", true, true);
                }}
              />
              <div className="xgs-form__address-block">
                <XGSFormInput
                  type="text"
                  name="address.address1"
                  label="Address:"
                  required={true}
                  requiredAsteriskDisabled={false}
                  labelMode={LabelModes.column}
                  className="xgs-bol__field"
                />
                <XGSFormInput
                  type="text"
                  name="address.city"
                  label="City:"
                  required={true}
                  requiredAsteriskDisabled={false}
                  labelMode={LabelModes.column}
                  className="xgs-bol__field"
                />
                <div className="xgs-bol__fields-row">
                  <XGSFormSelect
                    isSearchable={false}
                    label="State:"
                    labelMode={LabelModes.column}
                    name="address.state"
                    onValueChange={(v: any) => {
                      props.setFieldValue("address.state", v?.value);
                      if (v?.value) {
                        const stateOption = usaStates.find(state => state.value === v.value);
                        stateOption && setStateValue(stateOption);
                      }
                    }}
                    options={usaStates}
                    required={true}
                    requiredAsteriskDisabled={false}
                    formik={true}
                    value={stateValue}
                    className="xgs-bol__field xgs-bol__fields-row__state"
                  />
                  <XGSFormInput
                    type="text"
                    name="address.postalCode"
                    label="Zip:"
                    required={true}
                    requiredAsteriskDisabled={false}
                    labelMode={LabelModes.column}
                    className="xgs-bol__field xgs-bol__field--zip"
                  />
                </div>
                <div className="xgs-bol__return__field-notes">
                  <strong>Note:</strong> manual address entry could lead to service delays.
                </div>
              </div>
            </div>
            <div className="xgs-bol__contact-row">
              <XGSFormPhoneInput
                name="phone"
                label="Phone:"
                labelMode={LabelModes.column}
                onValueChange={(value) => {
                  props.setFieldValue("phone", value);
                  setPhoneFieldValue(value);
                }}
                onBlur={props.handleBlur}
                value={phoneFieldValue}
                required={true}
                requiredAsteriskDisabled={false}
                className="xgs-bol__field xgs-bol__contact-row__phone"
              />
              <div className="xgs-form__field__notes xgs-form__field__notes--contact xgs-form__field__notes--mobile">
                <strong>Note:</strong> by providing a telephone number and submitting this form you are consenting to be contacted by SMS text message.
                Message &amp; data rates may apply. You can reply STOP to opt-out of further messaging.
              </div>
              <XGSFormInput
                type="text"
                name="email"
                label="Email:"
                required={false}
                requiredAsteriskDisabled={true}
                labelMode={LabelModes.column}
                className="xgs-bol__field xgs-bol__contact-row__email"
              />
            </div>
            <div className="xgs-form__field__notes xgs-form__field__notes--contact xgs-form__field__notes--desktop">
              <strong>Note:</strong> by providing a telephone number and submitting this form you are consenting to be contacted by SMS text message.
              Message &amp; data rates may apply. You can reply STOP to opt-out of further messaging.
            </div>
            <div className="xgs-bol__buttons">
              <Button
                type="button"
                theme={ButtonThemes.gray}
                className="xgs-bol__nav-button"
                onClick={() => onClickBack(props.values)}
              >
                Back
              </Button>
              <Button
                type="submit"
                theme={ButtonThemes.blue}
                disabled={!props.isValid || !props.values.name}
                className="xgs-bol__nav-button">
                Next
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ReturnConsigneeStep;

import React from "react";
import Popup from "reactjs-popup";
import { ReactComponent as CloseIcon } from "../../images/close.svg";
import { cn } from "../../services/common/className";

import "./xgsPopup.scss";

export enum PopupTheme {
  NEW = "new",
};

export interface XGSPopupProps {
  title?: string | JSX.Element;
  opened: boolean;
  triggerElement?: JSX.Element;
  onClose: () => void;
  className?: string;
  closeOnDocumentClick?: boolean;
  closeOnEscape?: boolean;
  hideCloseButton?: boolean;
  theme?: PopupTheme,
}

const XGSPopup: React.FC<XGSPopupProps> = (props) => {
  return (
    <Popup
      className={props.className}
      open={props.opened}
      closeOnDocumentClick={(typeof props.closeOnDocumentClick !== 'undefined') ? props.closeOnDocumentClick : true}
      closeOnEscape={(typeof props.closeOnEscape !== 'undefined') ? props.closeOnEscape : true}
      onOpen={() => document.body.style.overflowY = "hidden"}
      onClose={() => {
        document.body.style.overflowY = "visible";
        props.onClose();
      }}
      arrow={false}
      offsetY={10}
      contentStyle={{
        width: "auto",
        borderRadius: 4,
        border: "1px solid #E0E0E0",
        boxShadow: "0px 1px 6px rgba(0, 0, 0, 0.15)",
        padding: props.theme === PopupTheme.NEW ? "0px" : "16px 24px",
      }}
    >
      {close => (<div className={cn("xgs-popup")({theme: props.theme})}>
        <div className="xgs-popup__header">
          <div className="xgs-popup__title">{props.title}</div>
          {!props.hideCloseButton && (<div className="xgs-popup__close" onClick={close}>
            <CloseIcon />
          </div>)}
        </div>
        <div className="xgs-popup__content">{props.children}</div>
        <div className="xgs-popup__bottom"></div>
      </div>)}
    </Popup>
  );
};

export default XGSPopup;

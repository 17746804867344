import * as Yup from "yup";
import { validationMessages } from "../common/validationMessages";
import { ItemModel } from "../common/models";

export interface ReturnShipperZipModel {
  postalCode: string;
}

export const ReturnShipperZipSchema: Yup.ObjectSchema<ReturnShipperZipModel> = Yup.object({
  postalCode: Yup.string().matches(/^\d*$/, validationMessages.notValid).min(5, "The value must be no less than 5 digits").max(5, "The value must be no more than 5 digits").required(validationMessages.required)
}).defined();

export interface ReturnCommonModel {
  requestorRole: string;
  referenceNumber: string;
  poNumber?: string;
  originalBolNumber?: string;
}

export const ReturnCommonSchema: Yup.ObjectSchema<ReturnCommonModel> = Yup.object({
  requestorRole: Yup.string().required(validationMessages.required),
  referenceNumber: Yup
    .string()
    .matches(/(\w+|-+)/, validationMessages.notValid)
    .max(17, "The value must be no more than 17 characters")
    .required(validationMessages.required),
  poNumber: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).max(12, "The value must be no more than 12 characters"),
  originalBolNumber: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).max(20, "The value must be no more than 20 characters")
}).defined();

export interface ReturnConsigneeModel {
  name: string;
  address: {
    address1: string;
    city: string;
    postalCode: string;
    state: string;
  },
  phone: string;
  email?: string;
}

export const ReturnConsigneeSchema: Yup.ObjectSchema<ReturnConsigneeModel> = Yup.object({
  name: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).required(validationMessages.required).max(25, "The value must be no more than 25 characters"),
  address: Yup.object().shape({
    address1: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).required(validationMessages.required).max(256, "The value must be no more than 256 characters"),
    city: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).required(validationMessages.required).max(64, "The value must be no more than 64 characters"),
    postalCode: Yup.string().matches(/^\d*$/, validationMessages.notValid).min(5, "The value must be no less than 5 digits").max(5, "The value must be no more than 5 digits").required(validationMessages.required),
    state: Yup.string().required(validationMessages.required)
  }).defined(),
  phone: Yup.string().matches(/^\(\d{3}\)\s\d{3}-\d{4}$/, validationMessages.notValid).required(validationMessages.required),
  email: Yup.string().max(60, "The value must be no more than 60 characters").email(validationMessages.notValid)
}).defined();

export interface ReturnShipperModel extends Omit<ReturnConsigneeModel, "email"> {
  shipperAvailabilityHours: {
    from: string;
    to: string;
  };
  email: string;
  specialInstructions?: string;
  specialServices: string[];
  pickupDate: string;
}

export const ReturnShipperSchema: Yup.ObjectSchema<ReturnShipperModel> = Yup.object({
  name: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).required(validationMessages.required).max(25, "The value must be no more than 25 characters"),
  address: Yup.object().shape({
    address1: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).required(validationMessages.required).max(256, "The value must be no more than 256 characters"),
    city: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).required(validationMessages.required).max(64, "The value must be no more than 64 characters"),
    postalCode: Yup.string().matches(/^\d*$/, validationMessages.notValid).min(5, "The value must be no less than 5 digits").max(5, "The value must be no more than 5 digits").required(validationMessages.required),
    state: Yup.string().required(validationMessages.required)
  }).defined(),
  phone: Yup.string().matches(/^\(\d{3}\)\s\d{3}-\d{4}$/, validationMessages.notValid).required(validationMessages.required),
  email: Yup.string().max(60, "The value must be no more than 60 characters").email(validationMessages.notValid).required(validationMessages.required),
  shipperAvailabilityHours: Yup.object().shape({
    from: Yup.string().required(validationMessages.required),
    to: Yup.string().required(validationMessages.required)   
  }).defined(),
  specialInstructions: Yup.string().matches(/(\w+|-+)/, validationMessages.notValid).max(150, "The value must be no more than 150 characters"),
  specialServices: Yup.array().of(Yup.string().defined()).defined(),
  pickupDate: Yup.string().nullable().required(validationMessages.required)
}).defined();


export const ReturnItemSchema: Yup.ObjectSchema<ItemModel> = Yup.object({
  packageType: Yup.string().required(validationMessages.required),
  units: Yup
    .number()
    .integer("The value must be a valid amount without decimal values")
    .positive()
    .max(9999)
    .typeError(validationMessages.notValid)
    .required(validationMessages.required),
  ratingClass: Yup.string().required(validationMessages.required),
  description: Yup.string().max(32, "The value must be no more than 32 characters"),
  notes: Yup.string().max(40, "The value must be no more than 40 characters"),
  yards: Yup
    .number()
    .positive()
    .max(99999)
    .typeError(validationMessages.notValid)
    .when("packageType", {
      is: "ROLL",
      then: Yup
        .number()
        .positive()
        .max(99999)
        .typeError(validationMessages.notValid)
        .required(validationMessages.required)
    })
    .when("ratingClass", {
      is: "1",
      then: Yup
        .number()
        .positive()
        .max(99999)
        .typeError(validationMessages.notValid)
        .required(validationMessages.required)
    })
    .when("ratingClass", {
      is: "3",
      then: Yup
        .number()
        .positive()
        .max(99999)
        .typeError(validationMessages.notValid)
        .required(validationMessages.required)
    }),
  weight: Yup
    .number()
    .integer("The value must be a weight without decimal values")
    .positive()
    .max(99999)
    .typeError(validationMessages.notValid)
    .required(validationMessages.required),
  length: Yup
    .number()
    .positive()
    .max(99999)
    .typeError(validationMessages.notValid),
  width: Yup
    .number()
    .positive()
    .max(99999)
    .typeError(validationMessages.notValid),
  height: Yup
    .number()
    .positive()
    .max(99999)
    .typeError(validationMessages.notValid),
  sidemark: Yup
    .string()
    .max(40, "The value must be no more than 40 characters")
}).defined();

export interface AvailabilityHoursModel {
  from: string;
  to: string;
}

export interface ManualReturnRequestModel {
  referenceNumber: string;
  fileId: string;
  shipper: ReturnConsigneeModel;
}

export interface ReturnRequestModel {
  requestorRole: string;
  referenceNumber: string;
  poNumber?: string;
  pickupDate: string;
  shipper: ReturnConsigneeModel;
  shipperAvailabilityHours: AvailabilityHoursModel;
  specialInstructions?: string;
  specialServices?: string[];
  consignee: ReturnConsigneeModel;
  items: ItemModel[];
  fileId?: string;
}

export interface ShareReturnModel {
  email: string;
}

export const ShareReturnSchema: Yup.ObjectSchema<ShareReturnModel> = Yup.object({
  email: Yup.string().max(60, "The value must be no more than 60 characters").email(validationMessages.notValid).required(validationMessages.required)
}).defined();

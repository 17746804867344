import React from "react";
import { useSelector } from "react-redux";
import Helmet from "react-helmet";
import XGSIcons from "../../ui-components/icon/xgsIcons";
import XGSIcon from "../../ui-components/icon/xgsIcon";
import AgingSummary from "../aging/summary/agingSummary";
import ContentContainer from "../../templates/content-container/contentContainer";
import ServiceCentersStatus from "./service-centers-status/serviceCentersStatus";
import CreditHold from "./credit-hold/creditHold";
// import FuelSurcharge from "./fuel-surcharge/fuelSurcharge";
import TopBanner from "../customers/top-banner/topBanner";
import { userSelector } from "../../slices/user/userSlice";
import UserState from "../../slices/user/UserState";
import { UserUtils } from "../../app/data/user/userUtils";
import "./accountSummary.scss";

const AccountSummary: React.FC<{}> = (props) => {
  const userState: UserState = useSelector(userSelector);
  
  const isPayor = () => {
    return (userState.activeSubAccount?.id && userState.profile?.subAccounts)
      ? userState.profile.subAccounts.find(subAccount => subAccount.id === userState.activeSubAccount?.id)?.payor
      : false;
  };

  return (
    <ContentContainer title="Account Summary">
      <Helmet title="Home" />
      <TopBanner />
      {UserUtils.isUser(userState.profile) && (userState.profile?.subAccounts?.length === 0) && (
        <div className="xgs-summary__warning">
          <XGSIcon
            icon={XGSIcons.faExclamationTriangle}
            className="xgs-summary__warning__icon"
          />
          <div className="xgs-summary__warning__text">
            <strong>Most features are not available or hidden due to lack of permissions.</strong>&nbsp;<br className="xgs-summary__warning__text__br"/> 
            Please contact your company administrator or XGS Customer Service to set up your account.
          </div>
        </div>
      )}
      <div className="xgs-summary__widgets">
        <CreditHold />
        <ServiceCentersStatus />
        {/*<FuelSurcharge />*/}
      </div>
      {isPayor() && UserUtils.hasPermission(userState.profile || null, userState.activeSubAccount?.id || null, "PAYMENT") && (
        <AgingSummary />
      )}
    </ContentContainer>
  );
}

export default AccountSummary;

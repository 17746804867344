import React from "react";
import { Link } from "react-router-dom";
import CustomCell, {
  CustomCellTypes,
} from "../../ui-components/table/custom-cells/customCell";
import { AppointmentColumnCell } from "./AppointmentColumnCell";

const getAppointmentSummaryColumns = () => [
  {
    Header: "Probill",
    accessor: "invoice.invoiceNumber",
    width: 90,
    minWidth: 90,
    Cell: (cellProps: any) => (
      <Link
        className="xgs-table-link-cell"
        to={`/shipments/${cellProps.value}`}
      >
        {cellProps.value}
      </Link>
    )
  },
  {
    Header: "Probill Status",
    accessor: "status.translate",
    width: 145,
    minWidth: 145
  },
  {
    Header: "Bill Date",
    accessor: "invoice.dateInvoice",
    width: 85,
    minWidth: 85,
    Cell: (cellProps: any) => (
      <div>
        <CustomCell
          cellType={CustomCellTypes.date}
          data={cellProps.row.original}
          value={cellProps.value}
        />
      </div>
    )
  },
  {
    Header: "ETA Date",
    accessor: "estimatedDeliveryDate",
    width: 85,
    minWidth: 85,
    Cell: (cellProps: any) => (
      <div>
        <CustomCell
          cellType={CustomCellTypes.date}
          data={cellProps.row.original}
          value={cellProps.value}
        />
      </div>
  )},
  {
    Header: "Shipper Name",
    accessor: "shipper.info",
    width: 170,
    minWidth: 170
  },
  {
    Header: "Consignee name",
    accessor: "consignee.info",
    width: 170,
    minWidth: 170
  },
  {
    Header: "Appointment",
    accessor: "appointment",
    width: 175,
    minWidth: 175,
    Cell: (cellProps: any) => (
      <>
        <AppointmentColumnCell
          date={cellProps.row.original.invoiceAppointment?.date}
          appointmentPending={!cellProps.row.original.invoiceAppointment?.confirmedDateTime}
          earlyTime={cellProps.row.original.invoiceAppointment?.earlyTime}
          lateTime={cellProps.row.original.invoiceAppointment?.lateTime}
        />
      </>
    )
  },
  {
    Header: "Exception",
    accessor: "exception",
    width: 188,
    minWidth: 188,
    Cell: (cellProps: any) => (
      <>
        <AppointmentColumnCell
          exceptionStatus={cellProps.row.original.exception?.reason}
        />
      </>
    )
  }
];

export default getAppointmentSummaryColumns;

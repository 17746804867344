import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../../ui-components/table/table";
import { ConvertPicklistToRouteState, PicklistDetailsState, PicklistPdfState } from "../../../../slices/dispatch-planning/picklists/picklistsState";
import { picklistDetailsSelector } from "../../../../slices/dispatch-planning/picklists/picklistDetailsSlice";
import getPicklistDetailsColumns from "./picklistDetailsColumns";
import Button, { ButtonThemes } from "../../../../ui-components/button/button";
import { downloadPicklistPdf, picklistPdfSelector } from "../../../../slices/dispatch-planning/picklists/picklistPdfSlice";
import './picklistDetails.scss';
import { convertPicklistToRoute, convertPicklistToRouteSelector } from "../../../../slices/dispatch-planning/picklists/convertPicklistToRouteSlice";
import { toast } from "react-toastify";
import ConfirmationModal from "../../../../ui-components/confirmation-modal/confirmationModal";

const PicklistDetails: React.FC<{}> = memo(() => {
  const dispatch = useDispatch();
  const [isShowEditRoute, setShowEditRoute] = useState<boolean>(false);

  const picklistDetailsState: PicklistDetailsState = useSelector(
    picklistDetailsSelector
  );
  const picklistPdfState: PicklistPdfState = useSelector(
    picklistPdfSelector
  );
  const convertPicklistToRouteState: ConvertPicklistToRouteState = useSelector(
    convertPicklistToRouteSelector
  );
  const getPicklistPdf = () => {
    if (!picklistDetailsState.picklistDetails || !picklistDetailsState.picklistDetails[0] || !picklistDetailsState.picklistDetails[0].picklistNumber) {
      return;
    }
    let picklistNumber = picklistDetailsState.picklistDetails[0].picklistNumber;
    dispatch(downloadPicklistPdf(picklistNumber));
  }
  const picklistToRoute = () => {
    if (!picklistDetailsState.picklistDetails || !picklistDetailsState.picklistDetails[0] || !picklistDetailsState.picklistDetails[0].picklistNumber) {
      return;
    }
    let picklistNumber = picklistDetailsState.picklistDetails[0].picklistNumber;
    let terminalNumber = picklistDetailsState.picklistDetails[0].terminalNumber;
    dispatch(convertPicklistToRoute(
      picklistNumber,
      terminalNumber,
      () => { toast.info("Picklits is converted to a Route successfully!") },
      (error) => { toast.error(error) },
      () => { setShowEditRoute(false) }
    ))
  }
  return (
    <div className="xgs-picklist-details">
      <div className="xgs-picklist-details__actions">
        <Button
          theme={ButtonThemes.blue}
          className="xgs-picklist-details__actions__download-pdf-button"
          disabled={(!picklistDetailsState.loaded || picklistPdfState.loading)}
          spinner={(picklistPdfState.loading)}
          onClick={getPicklistPdf}>
          Download Picklist
        </Button>
        <Button
          theme={ButtonThemes.blue}
          className="xgs-picklist-details__actions__edit-button"
          disabled={(!picklistDetailsState.loaded || convertPicklistToRouteState.loading)}
          spinner={(convertPicklistToRouteState.loading)}
          onClick={()=>{setShowEditRoute(true)}}>
          Edit as Route
        </Button>
      </div>
      <Table
        isLoading={picklistDetailsState.loading}
        columns={getPicklistDetailsColumns()}
        data={picklistDetailsState.picklistDetails}
        ignoreCellClick="selection"
        cursorPointer={true}
        onSelectedRowsChange={null}
        infiniteScroll={false}
        responsive={true}
      />

      <ConfirmationModal
        opened={isShowEditRoute}
        header="Picklist to Route"
        confirmButtonText="Convert"
        spinner={convertPicklistToRouteState.loading}
        onCancel={() => { setShowEditRoute(false) }}
        onConfirm={() => { picklistToRoute(); }}
      >
       This action will delete the Picklist and convert it back to a active route, are you sure you want to proceed?
      </ConfirmationModal>
    </div>

  );
});

export default PicklistDetails;

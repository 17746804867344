import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { Form, Formik, FormikProps } from "formik";
import debounce from "lodash/debounce";
import ContentContainer from "../../../templates/content-container/contentContainer";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import XGSFormTextarea from "../../../ui-components/form/textarea/xgsFormTextarea";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import LabeledSelectInput from "../../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import { XGSSelectOption } from "../../../ui-components/xgs-select/xgsSelect";
import { Routes } from "../../../app/route/RoutesConfig";
import { RecordModel, RecordSchema } from "../../../app/data/records/models";
import {
  recordsSelector,
  resetRecordsState,
  searchProbills,
  submitRecord
} from "../../../slices/delivery-records/recordsSlice";
import RecordsState from "../../../slices/delivery-records/RecordsState";
import {
  ACCEPTED_FORMATS,
  MAX_SIZE,
  MAX_PHOTOS
} from "./constants";
import "./deliveryRecords.scss";

let initialValues: RecordModel = {
  probill: undefined,
  manifest: undefined,
  summary: ""
};

const DeliveryRecordsNew: React.FC<{}> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const recordsState: RecordsState = useSelector(recordsSelector);
  const [photos, setPhotos] = useState<any>([]);
  const [fileError, setFileError] = useState<string>("");
  const [probill, setProbill] = useState<XGSSelectOption | null>();
  const [probillData, setProbillData] = useState<any>();  

  const {
    getRootProps,
    getInputProps
  } = useDropzone({
    accept: ACCEPTED_FORMATS,
    maxSize: MAX_SIZE * 1048576,
    maxFiles: MAX_PHOTOS - photos.filter((obj: any) => !obj.deleted).length,
    onDrop: (acceptedFiles, fileRejections) => {
      setFileError("");
      if (fileRejections?.length > 0) {
        fileRejections[0].errors.forEach((err) => {
          if (err.code === "file-too-large") {
            setFileError(`Files no larger than ${MAX_SIZE} MB are allowed!`);
          }
          if (err.code === "file-invalid-type") {
            setFileError("Only images of certain formats are allowed!");
          }
          if (err.code === "too-many-files") {
            setFileError(`Maximum ${MAX_PHOTOS} photos are allowed!`);
          }
        });
      }
      if (acceptedFiles.length === 0) return;
      setPhotos([...photos, ...acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file),
        deleted: false,
        comment: "",
        internalOnly: true
      }))]);
    }
  });

  const photosBlock = photos.map((photo: any, i: number) => (
    <div
      className={`xgs-delivery-records__form__photos__item ${photo.deleted ? "xgs-delivery-records__form__photos__item--deleted" : ""}`}
      key={`photo.name-${i}`}
    >
      <div className="xgs-delivery-records__form__photos__item__image">
        <img
          src={photo.preview}
          alt="Preview"
        />
        <div
          className="xgs-delivery-records__form__photos__item__image__control"
          onClick={() => removePhoto(i)}
        >
          <XGSIcon
            icon={XGSIcons.faTimes}
            className="xgs-delivery-records__form__photos__item__icon"
          />
        </div>
      </div>
      <div className="xgs-delivery-records__form__photos__item__fields">
        <XGSFormTextarea
          name={`comments-${i}`}
          label="Comments:"
          required={false}
          rows={3}
          className="xgs-delivery-records__form__photos__item__fields__textarea"
          onChange={(e) => onCommentChange(i, e.currentTarget.value)}
        />
        {photos[i].comment.length > 255 && (
          <div className="xgs-labeled-input__validation-error">The value must be no more than 255 characters</div>
        )}
        <div className="xgs-form__checkbox">
          <label>
            <input
              type="checkbox"
              name={`internal-${i}`}
              onClick={() => onInternalFlagChange(i)}
              defaultChecked={true}
            />
            <div>
              Internal only
            </div>
          </label>
        </div>
      </div>
    </div>
  ));

  const isPhotoCommentsValid = () => {
    let invalidPhotoComments = photos.find((obj: any) => obj.comment.length > 255);
    return !invalidPhotoComments;
  };

  const onCommentChange = (i: number, val: string) => {
    let newArr = [...photos];
    newArr[i].comment = val;
    setPhotos(newArr);
  };

  const onInternalFlagChange = (i: number) => {
    let newArr = [...photos];
    newArr[i].internalOnly = !newArr[i].internalOnly;
    setPhotos(newArr);
  };  

  const removePhoto = (i: number) => {
    let newArr = [...photos];
    newArr[i].deleted = true;
    setPhotos(newArr);
  };

  const onSubmitRecord = (data: RecordModel) => {
    const actualPhotos = photos.filter((obj: any) => !obj.deleted);
    const fd = new FormData();
    for (let photo of actualPhotos) {
      fd.append("files", photo);
    }
    const preparedData = {
      probill: data.probill,
      manifest: data.manifest,
      summary: data.summary,
      photos: actualPhotos.map((photo: any) => {
        return {
          internalOnly: photo.internalOnly,
          comment: photo.comment
        }
      })
    };
    fd.append("data", JSON.stringify(preparedData));
    dispatch(submitRecord(fd, () => {
      toast.info("Record created!");
      mixpanel.track("Delivery record was submitted");
      history.push(Routes.deliveryRecords.list);
    }));
  };

  let probillSearch = (query: string) => {
    query?.length > 1 && dispatch(searchProbills(query));
  }
  probillSearch = debounce(probillSearch, 500);

  useEffect(() => {
    return () => {
      dispatch(resetRecordsState());
    };
  }, [dispatch]);

  return (
    <ContentContainer title="Upload New Images">
      <div className="xgs-delivery-records">
          <>
            <div>
              <Formik
                onSubmit={onSubmitRecord}
                initialValues={initialValues}
                validationSchema={RecordSchema}
                enableReinitialize
              >
                {(props: FormikProps<RecordModel>) => (
                  <Form>
                    <div className="xgs-delivery-records__form__field">
                      <LabeledSelectInput
                        isLoading={recordsState.requestStarted && recordsState.requestCreator === "SEARCH_PROBILL"}
                        label="Probill:"
                        labelMode={LabelModes.column}
                        name="probill"
                        onInputChange={(query) => probillSearch(query)}
                        onValueChange={(v) => {
                          props.setFieldValue("probill", v?.value || "");
                          setProbill(v);
                          props.setFieldValue("manifest", v?.subtitle || "");
                          if (v?.value) {
                            const probillObj = recordsState.probillsRaw.find((obj: any) => obj.probill === v.value);
                            setProbillData(probillObj);
                          } else {
                            setProbillData(undefined);
                          }
                          setTimeout(() => {
                            props.validateForm();
                          }, 50);                          
                        }}
                        openMenuOnClick={recordsState.probills.length > 0}
                        options={recordsState.probills}
                        placeholder="Start typing the probill..."
                        value={probill}
                        isClearable
                      />
                    </div>
                    {probillData?.payor && (
                      <div className="xgs-delivery-records__probill-data">
                        <div>Payor:</div>
                        <div>{probillData.payor.info} <span className="xgs-delivery-records__details__account-number">{probillData.payor.number}</span></div>
                      </div>
                    )}
                    {probillData?.shipper && (
                      <div className="xgs-delivery-records__probill-data">
                        <div>Shipper:</div>
                        <div>{probillData.shipper.info} <span className="xgs-delivery-records__details__account-number">{probillData.shipper.number}</span></div>
                      </div>
                    )}
                    {probillData?.consignee && (
                      <div className="xgs-delivery-records__probill-data">
                        <div>Consignee:</div>
                        <div>{probillData.consignee.info} <span className="xgs-delivery-records__details__account-number">{probillData.consignee.number}</span></div>
                      </div>
                    )}
                    <div className="xgs-delivery-records__form__field">
                      <XGSFormInput
                        type="text"
                        name="manifest"
                        label="Manifest:"
                        required={true}
                        requiredAsteriskDisabled={true}
                        labelMode={LabelModes.column}
                      />
                    </div>
                    <div className="xgs-delivery-records__form__upload">
                      <div className="xgs-delivery-records__form__upload__header">Photos:</div>
                      {(photos.filter((obj: any) => !obj.deleted).length < MAX_PHOTOS) && (
                        <div {...getRootProps({ className: "xgs-upload__area" })}>
                          <input {...getInputProps()} />
                          <span className="blue-link">tap, click or drag &amp; drop photos here</span>
                          <div className="xgs-upload__notes">(<strong>JPEG</strong>, <strong>PNG</strong>, <strong>WebP</strong>, <strong>GIF</strong> and <strong>BMP</strong> formats only)</div>
                          {fileError && (
                            <span className="xgs-upload__area__error">
                              {fileError}
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                    {(photos.length > 0) && (
                      <div className="xgs-delivery-records__form__photos">
                        {photosBlock}
                      </div>
                    )}
                    <div className="xgs-delivery-records__form__field xgs-delivery-records__form__field--long">
                      <XGSFormTextarea
                        name="summary"
                        label="Comments:"
                        required={false}
                        rows={4}
                        className="xgs-delivery-records__form__field__textarea"
                      />
                    </div>
                    {recordsState.requestFailed && (
                      <XGSErrorMessage className="xgs-delivery-records__form__error">{recordsState.requestError || "Error"}</XGSErrorMessage>
                    )}
                    <div className="xgs-delivery-records__form__buttons">
                      <Button
                        theme={ButtonThemes.blue}
                        type="submit"
                        className="xgs-delivery-records__form__submit"
                        spinner={recordsState.requestStarted && recordsState.requestCreator === "SUBMIT_RECORD"}
                        disabled={!props.isValid || !props.dirty || photos.filter((obj: any) => !obj.deleted).length === 0 || !isPhotoCommentsValid()}
                      >
                        Submit
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </>
      </div>
    </ContentContainer>
  );
};

export default DeliveryRecordsNew;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import mixpanel from "mixpanel-browser";
import ConfirmationModal from "../../../../ui-components/confirmation-modal/confirmationModal";
import Button, { ButtonThemes } from "../../../../ui-components/button/button";
import PickupItemForm from "./pickupItemForm";
import {
  addItem,
  clearDeletedItems,
  clearRequestError,
  createPickup,
  deleteItem,
  pickupSelector,
  updateItem
} from "../../../../slices/pickup/pickupSlice";
import { PickupRequestModel } from "../../../../app/data/pickup/models";
import { StepProps } from "../../../../app/data/common/models";
import CommonState from "../../../../slices/common/CommonState";
import { commonSelector } from "../../../../slices/common/commonSlice";
import rollIcon from "../../../../images/roll_blue.svg";
import palleteIcon from "../../../../images/pallete_blue.svg";
import editIcon from "../../../../images/edit_blue.svg";
import removeIcon from "../../../../images/trash_blue.svg";
import "../../../../sass/blocks.scss";

const PickupItemsStep: React.FC<StepProps> = ({ previous, next }) => {
  const dispatch = useDispatch();

  const pickupState = useSelector(pickupSelector);
  const commonState: CommonState = useSelector(commonSelector);
  const [showAddItemForm, setAddItemForm] = useState<boolean>(false);
  const [removeItemIndex, setRemoveItemIndex] = useState<number | undefined>(undefined);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState<boolean>(false);
  const [editItemIndex, setEditItemIndex] = useState<number | undefined>(undefined);

  const onClickBack = () => {
    dispatch(clearDeletedItems());
    previous && previous();
  };

  const onSubmit = () => {
    if (!pickupState.shipper) return;
    dispatch(clearDeletedItems());
    let items = pickupState.items.filter((item: any) => !item.deleted);
    let request: PickupRequestModel = {
      teamId: pickupState.teamId,
      shipper: pickupState.shipper,
      ...(pickupState.bolNumber && {bolNumber: pickupState.bolNumber}),
      ...(pickupState.poNumber && {poNumber: pickupState.poNumber}),
      ...(pickupState.additionalReference && {additionalReference: pickupState.additionalReference}),
      pickupDate: pickupState.pickupDate.toApiDateFormat(),
      pickupReadyTime: pickupState.pickupReadyTime || "",
      pickupCloseTime: pickupState.pickupCloseTime || "",
      specialInstructions: pickupState.specialInstructions,
      consignee: pickupState.consignee,
      items: items.map(({deleted, ...properties}) => properties)
    };
    dispatch(createPickup(request, () => {
      toast.info("Pickup was successfully created!");
      mixpanel.track("Pickup was created");
      next?.();
    }));
  };

  const getClassLabel = (ratingClassValue: string) => {
    let ratingClass = commonState.freightClasses.find(obj => obj.value === ratingClassValue);
    return ratingClass ? ratingClass.label : ratingClassValue;
  };

  useEffect(() => {
    if (!pickupState.requestFailed || pickupState.requestCreator === "CHECK_PICKUP") return;
    toast.error(pickupState.requestError || "Error", { autoClose: 4000 });
  }, [pickupState.requestFailed, pickupState.requestError, pickupState.requestCreator]);

  useEffect(() => {
    return () => {
      dispatch(clearRequestError());
    };
  }, [dispatch]);

  return (
    <div className="xgs-items">
      <div className="xgs-bol__section">Detail Information</div>
      {pickupState.items.map((item: any, index: number) => (
        <div key={index}>
          {(index === editItemIndex) && (
            <PickupItemForm
              itemIndex={index}
              onSubmit={(data) => {
                dispatch(updateItem({ index, data }));
                setEditItemIndex(undefined);
              }}
              onCancel={() => {
                setEditItemIndex(undefined);
              }}
            />
          )}
          {(editItemIndex === undefined || (index !== editItemIndex)) && !item.deleted && (
            <div className="xgs-items__item">
              <div className="xgs-items__item__columns xgs-items__item__columns--space-between">
                <div className="xgs-items__item__columns xgs-items__item__columns--no-margin xgs-items__item__columns--stretch">
                  <div className="xgs-items__item__icon">
                    {item.packageType === "ROLL" && (
                      <img
                        src={rollIcon}
                        alt="roll"
                      />
                    )}
                    {item.packageType === "PALLET" && (
                      <img
                        src={palleteIcon}
                        alt="pallet"
                      />
                    )}
                  </div>
                  <div className="xgs-items__item__flex-center-area">
                    <div className="xgs-items__item__type">
                      {item.packageType === "ROLL" && "Roll"}
                      {item.packageType === "PALLET" && "Pallet"}
                    </div>
                    {item.ratingClass && (<div className="xgs-items__item__property xgs-items__item__class">Class: <span>{getClassLabel(item.ratingClass)}</span></div>)}
                  </div>
                </div>
                <div className="xgs-items__item__columns xgs-items__item__columns--no-margin xgs-items__item__columns--actions">
                  <div
                    className="xgs-items__item__action"
                    onClick={() => {
                      setAddItemForm(false);
                      setEditItemIndex(index);
                    }}
                  >
                    <img
                      src={editIcon}
                      alt="edit"
                    />
                    Edit
                  </div>
                  <div
                    className="xgs-items__item__action"
                    onClick={() => {
                      setRemoveItemIndex(index);
                      setShowRemoveConfirmation(true);
                    }}
                  >
                    <img
                      src={removeIcon}
                      alt="remove"
                    />
                    Remove
                  </div>
                </div>
              </div>
              <div className="xgs-items__item__columns xgs-items__item__columns--allow-wrap">
                <div className="xgs-items__item__property">Units: <span>{item.units}</span></div>
                {item.yards && (<div className="xgs-items__item__property">Sq. Yards: <span>{item.yards}</span></div>)}
                <div className="xgs-items__item__property">Pounds: <span>{item.weight}</span></div>
                {!!(item.length && item.width && item.height) && (
                  <div className="xgs-items__item__property">Size: <span>{item.length}</span> x <span>{item.width}</span> x <span>{item.height}</span> in</div>
                )}
                {(item.packageType === "ROLL" && item.length) && (
                  <div className="xgs-items__item__property">Length: <span>{item.length}</span> in</div>
                )}
              </div>
              {item.sidemark && (
                <div className="xgs-items__item__columns xgs-items__item__columns--allow-wrap">
                  <div className="xgs-items__item__property">Sidemark: <span>{item.sidemark}</span></div>
                </div>
              )}
              {item.notes && (
                <div className="xgs-items__item__columns xgs-items__item__columns--allow-wrap">
                  <div className="xgs-items__item__property">Notes: <span>{item.notes}</span></div>
                </div>
              )}
            </div>
          )}
        </div>
      ))}
      {(showAddItemForm || pickupState.items.length === 0) && <PickupItemForm onSubmit={(data) => {
        dispatch(addItem(data));
        setAddItemForm(false);
      }} />}
      {!showAddItemForm &&
        (editItemIndex === undefined) &&
        (pickupState.items.length > 0) &&
        (pickupState.items.filter((item: any) => !item.deleted).length < 15) && (
        <div className="xgs-bol__items__add">
          <span
            className="xgs-bol__items__link"
            onClick={() => setAddItemForm(true)}
          >
            + Add new item
          </span>
        </div>
      )}
      <div className="xgs-bol__items__limitation">
        {pickupState.items.filter((item: any) => !item.deleted).length === 0
          ? (
            <span>Up to 15 items are allowed.</span>
          )
          : (
            <span>{pickupState.items.filter((item: any) => !item.deleted).length} of 15 allowed items have been entered.</span>
          )
        }
      </div>
      <div className="xgs-pickup__buttons">
        <Button
          type="button"
          theme={ButtonThemes.gray}
          className="xgs-bol__nav-button"
          onClick={onClickBack}
        >
          Back
        </Button>
        <Button
          type="button"
          theme={ButtonThemes.blue}
          disabled={
            pickupState.items.length === 0
            || pickupState.items.filter((item: any) => !item.deleted).length === 0
            || (editItemIndex !== undefined)
            || (pickupState.requestSucceed && pickupState.requestCreator === "CREATE_PICKUP")
          }
          className="xgs-bol__nav-button"
          spinner={pickupState.requestStarted && pickupState.requestCreator === "CREATE_PICKUP"}
          onClick={onSubmit}
        >
          Submit
        </Button>
      </div>
      <ConfirmationModal
        fitContent
        opened={showRemoveConfirmation}
        confirmButtonText="Remove"
        onCancel={() => {
          setShowRemoveConfirmation(false);
        }}
        onConfirm={() => {
          dispatch(deleteItem(removeItemIndex));
          setShowRemoveConfirmation(false);
          setRemoveItemIndex(undefined);
        }}>
        <div className="text-center">The item will be removed from the list.</div>
      </ConfirmationModal>
    </div>
  );
};

export default PickupItemsStep;

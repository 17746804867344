import {
  ReturnCommonModel,
  ReturnRequestModel,
  ReturnShipperModel,
  ReturnConsigneeModel
} from "../../app/data/return/models";
import { ReturnItemModel } from "../../app/data/return/models";

interface ReturnRates {
  totalAmount: number;
  error: {
    message: string;
  }
};

export default interface ReturnState {
  returnRequest: ReturnRequestModel | null;
  requestStarted: boolean;
  requestSucceed: boolean;
  requestFailed: boolean;
  requestCreator: string;
  requestError: string;
  returnCommon: ReturnCommonModel;
  returnShipper: ReturnShipperModel;
  returnConsignee: ReturnConsigneeModel;
  shipperZipServiceable: boolean;
  shipperZipChecked: string;
  freightLabeled: boolean;
  items: ReturnItemModel[];
  rates: ReturnRates | null;
  referenceDocumentFilename: string;
  referenceDocumentId: string;
  mills: ReturnConsigneeModel[];
};

export const initialReturnState: ReturnState = {
  returnRequest: null,
  requestStarted: false,
  requestSucceed: false,
  requestFailed: false,
  requestCreator: "",
  requestError: "",
  returnCommon: {
    referenceNumber: "",
    requestorRole: ""
  },
  returnShipper: {
    name: "",
    address: {
      address1: "",
      city: "",
      postalCode: "",
      state: ""
    },
    phone: "",
    email: "",
    shipperAvailabilityHours: {
      from: "",
      to: ""
    },
    specialServices: [],
    pickupDate: ""
  },
  returnConsignee: {
    name: "",
    address: {
      address1: "",
      city: "",
      postalCode: "",
      state: ""
    },
    phone: "",
    email: ""
  },
  shipperZipServiceable: false,
  shipperZipChecked: "",
  freightLabeled: false,
  items: [],
  rates: null,
  referenceDocumentFilename: "",
  referenceDocumentId: "",
  mills: []
};

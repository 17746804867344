import React from "react";
import { useFormikContext } from "formik";
import AddressLookup from "../../../ui-components/address-lookup/addressLookup";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import XGSFormSelect from "../../../ui-components/form/select/xgsFormSelect";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import { XGSSelectOption } from "../../xgs-select/xgsSelect";
import usaStates from "../../../app/data/common/usaStates";

import "./formAddressSelector.scss";

interface FormAddressSelectorProps {
  addressLookupValue: XGSSelectOption | null;
  setAddressLookupValue: (value: any) => void;
  onZipChange?: (zip: string) => void;  
  isLoading?: boolean;
}

const FormAddressSelector: React.FC<FormAddressSelectorProps> = (props) => {  
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const onAddressValueChange = (val: any) => {
    setFieldValue("address.address1", val.address);
    setFieldValue("address.city", val.city);
    setFieldValue("address.state", val.state);
    setFieldValue("address.postalCode", val.zip);    

    props.setAddressLookupValue({
      label: `${val.address}, ${val.city}, ${val.state}, ${val.zip}`,
      value: {
        address: val.address,
        city: val.city,
        state: val.state,
        zip: val.zip
      }
    });

    setTimeout(() => {
      setFieldTouched("address.address1", true, true);
    }, 100);

    props.onZipChange?.(val.zip);
  };

  return (
    <div className="form-address-selector">      
      <AddressLookup
        defaultValue={props.addressLookupValue}
        onValueChange={onAddressValueChange}
        onBlur={() => {
          setFieldTouched("address.address1", true, true);
        }}
        allowReset={true}
      />
      <div className="xgs-form__address-block">
        <XGSFormInput
          type="text"
          name="address.address1"
          label="Address:"
          required={true}
          requiredAsteriskDisabled={false}
          labelMode={LabelModes.column}
          onChange={(e) => {
            setFieldValue("address.address1", e.currentTarget.value);
            props.setAddressLookupValue(null);
          }}
        />
        <XGSFormInput
          type="text"
          name="address.city"
          label="City:"
          required={true}
          requiredAsteriskDisabled={false}
          labelMode={LabelModes.column}
          onChange={(e) => {
            setFieldValue("address.city", e.currentTarget.value);
            props.setAddressLookupValue(null);
          }}
        />      

        <div className="form-address-selector__row">
          <XGSFormSelect
            className="form-address-selector__field--state"
            isSearchable={false}
            label="State:"
            labelMode={LabelModes.column}
            name="address.state"
            options={usaStates}
            required={true}
            requiredAsteriskDisabled={false}
            onChange={(value) => {
              setFieldValue("address.state", value);
              props.setAddressLookupValue(null);
            }}
            controlled={true}
          />

          <XGSFormInput
            className="form-address-selector__field--zip"
            type="text"
            name="address.postalCode"
            label="Zip:"
            required={true}
            labelMode={LabelModes.column}
            onChange={(e) => {              
              props.onZipChange?.(e.currentTarget.value);
              setFieldValue("address.postalCode", e.currentTarget.value);
              props.setAddressLookupValue(null);
            }}
            isLoading={props.isLoading}
          />          
        </div>
      </div>            
    </div>
  );
};

export default FormAddressSelector;

import React from "react";
import XGSIcon from "../../ui-components/icon/xgsIcon";
import XGSIcons from "../../ui-components/icon/xgsIcons";
import "./stageBanner.scss";

const StageBanner: React.FC<{}> = (props) => {
  return process.env.REACT_APP_XGS_ENV !== "production"
  ? (
    <div className="xgs-stage-banner">
      <div className="xgs-stage-banner__icon">
        <XGSIcon
          icon={XGSIcons.faExclamationCircle}
          size="sm"
        />
      </div>
      <div>
        <div className="xgs-stage-banner__header">This is not a production environment!</div>
        <div className="xgs-stage-banner__text">Data may not match actual one, features may not work as expected.</div>
      </div>
    </div>
  )
  : null;
};

export default StageBanner;

export const frequencyTypes = [
  { 
    label: "Daily",
    value: "DAILY"
  },
  { 
    label: "Weekly",
    value: "WEEKLY"
  },
  { 
    label: "Monthly",
    value: "MONTHLY"
  }
];

export const dayOfWeek = [
  { 
    label: "Sunday",
    value: "SUNDAY"
  },
  { 
    label: "Monday",
    value: "MONDAY"
  },
  { 
    label: "Tuesday",
    value: "TUESDAY"
  },
  { 
    label: "Wednesday",
    value: "WEDNESDAY"
  },
  { 
    label: "Thursday",
    value: "THURSDAY"
  },
  { 
    label: "Friday",
    value: "FRIDAY"
  },
  { 
    label: "Saturday",
    value: "SATURDAY"
  }
];

export const fields = [
  {
    name: "PROBILL",
    label: "Probill",
    default: true,
    section: "probill"
  },
  {
    name: "BILL_DATE",
    label: "Bill Date",
    default: true,
    section: "probill"
  },
  {
    name: "PROBILL_STATUS",
    label: "Probill Status",
    default: true,
    section: "probill"
  },
  {
    name: "ACCOUNT_NUMBER",
    label: "Account Number",
    default: false,
    section: "probill"
  },
  {
    name: "ORIGINAL_ETA",
    label: "Original ETA Date",
    default: false,
    section: "probill"
  },
  {
    name: "CURRENT_ETA",
    label: "Current ETA Date",
    default: false,
    section: "probill"
  },  
  {
    name: "DESTINATION",
    label: "Destination (Service Center Name)",
    default: false,
    section: "probill"
  },
  {
    name: "DESTINATION_NUMBER",
    label: "Destination Number (SC Number)",
    default: false,
    section: "probill"
  },
  {
    name: "CONSIGNEE_NUMBER",
    label: "Consignee Number",
    default: false,
    section: "probill"
  },
  {
    name: "CONSIGNEE_NAME",
    label: "Consignee Name",
    default: false,
    section: "probill"
  },
  {
    name: "CONSIGNEE_ADDRESS",
    label: "Consignee Address",
    default: false,
    section: "probill"
  },
  {
    name: "CONSIGNEE_CITY",
    label: "Consignee City",
    default: false,
    section: "probill"
  },
  {
    name: "STATE",
    label: "Consignee State",
    default: false,
    section: "probill"
  },
  {
    name: "ZIP_CODE",
    label: "Consignee Zip Code",
    default: false,
    section: "probill"
  },
  {
    name: "BILL_OF_LADING",
    label: "Bill of Lading",
    default: false,
    section: "probill"
  },
  {
    name: "PO_NUMBER",
    label: "PO Number",
    default: false,
    section: "probill"
  },
  {
    name: "SHIPPER_NUMBER",
    label: "Shipper Number",
    default: false,
    section: "probill"
  },
  {
    name: "SHIPPER_NAME",
    label: "Shipper Name",
    default: false,
    section: "probill"
  },
  {
    name: "TOTAL_PIECES",
    label: "Total Pieces",
    default: false,
    section: "probill"
  },
  {
    name: "TOTAL_SQ_YARDS",
    label: "Total Sq. Yards",
    default: false,
    section: "probill"
  },
  {
    name: "TOTAL_WEIGHT",
    label: "Total Weight",
    default: false,
    section: "probill"
  },
  {
    name: "PIECES",
    label: "Pieces",
    default: false,
    section: "roll/item"
  },
  {
    name: "COMMODITY_TYPE",
    label: "Commodity Type",
    default: false,
    section: "roll/item"
  },
  {
    name: "COMMODITY_DESCRIPTION",
    label: "Commodity Description",
    default: false,
    section: "roll/item"
  },
  {
    name: "ROLL_NUMBER",
    label: "Roll Number",
    default: false,
    section: "roll/item"
  },
  {
    name: "SERIAL_ITEM_NUMBER",
    label: "Serial / Item Number",
    default: false,
    section: "roll/item"
  },
  {
    name: "WIDTH",
    label: "Width",
    default: false,
    section: "roll/item"
  },
  {
    name: "LENGTH",
    label: "Length",
    default: false,
    section: "roll/item"
  },
  {
    name: "WEIGHT",
    label: "Weight",
    default: false,
    section: "roll/item"
  },
  {
    name: "QUANTITY",
    label: "Quantity",
    default: false,
    section: "roll/item"
  },
  {
    name: "DYELOT",
    label: "DL - Dye Lot",
    default: false,
    section: "roll/item"
  },
  {
    name: "COLOR",
    label: "C - Color",
    default: false,
    section: "roll/item"
  },
  {
    name: "STYLE",
    label: "S - Style",
    default: false,
    section: "roll/item"
  },
  {
    name: "SIDE_MARK",
    label: "SM - Side Mark",
    default: false,
    section: "roll/item"
  }
];

import React from "react";
import Popup from "reactjs-popup";

import { ReactComponent as Info } from "../../../images/info.svg";

import { cn } from "../../../services/common/className";

import "./legend.scss";

export interface LegendProps {
  mix?: string;
  children: React.ReactElement;
}

const Legend: React.FC<LegendProps> = ({ mix, children }) => {
  return (
    <Popup
        trigger={
          <div className={cn("legend__trigger")(null, [mix])}>
            <Info />
          </div>
        }
        position="bottom right"
        closeOnDocumentClick
        offsetX={16}
        offsetY={5}
        arrowStyle={{
          right: "23px",
          left: "auto",
          borderWidth: "0px",
          boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.2)",
        }}
        contentStyle={{
          borderRadius: "4px",
          top: "50px",
          boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.20)",
          borderWidth: "0px",
          padding: "24px",
          width: "auto",
        }}
      >
        {children}
      </Popup>
  )
};

export default Legend;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ContentContainer from "../../../templates/content-container/contentContainer";
import XGSToggle from "../../../ui-components/xgs-toggle/xgs-toggle";
import PickupMap from "./pickupMap";
import DriversList from "./driversList";
import PickupList from "./pickupList";
import PickupListFilters from "./pickupListFilters";
import PickupDetails from "./pickupDetails";
import { PickupStatus } from "../../../app/data/common/route";
import { userSelector } from "../../../slices/user/userSlice";
import { pickupAssignmentSelector } from "../../../slices/pickup/pickupAssignmentSlice";
import { isGroup } from "../../../services/pickups";
import "./pickupManagement.scss";

const PickupAssignment: React.FC<{}> = (props) => {
  const userState = useSelector(userSelector);
  const pickupAssignmentState = useSelector(pickupAssignmentSelector);
  const [selectedPickupIds, setSelectedPickupIds] = useState([]);
  const [selectedPickupGroupId, setSelectedPickupGroupId] = useState("");
  const [selectedDriverId, setSelectedDriverId] = useState<number | null>(null);
  const [scrollToDriverId, setScrollToDriverId] = useState<number | null>(null);
  const [statuses, setStatuses] = useState([PickupStatus.UNASSIGNED, PickupStatus.ASSIGNED]);
  const [expiredOnly, setExpiredOnly] = useState(false);
  const [pickupNumber, setPickupNumber] = useState("");
  const [showPickupDetailsId, setShowPickupDetailsId] = useState("");
  const [showAllRoutes, setShowAllRoutes] = useState(false);
  const [showReschedule, setShowReschedule] = useState(false);

  const clearSelectedPickup = () => {
    setSelectedPickupIds([]);
    setShowPickupDetailsId("");
    setSelectedDriverId(null);
    setScrollToDriverId(null);
  };

  const changeRoutesMode = (showAll: boolean) => {
    if (showAll) {
      setSelectedDriverId(null);
    }
    setShowAllRoutes(showAll);
  }

  const selectDriver = (id: number) => {
    if (id) {
      setShowAllRoutes(false);
    }
    setSelectedDriverId(id);
  }

  useEffect(() => {
    clearSelectedPickup();
  }, [userState.activeTerminal]);

  useEffect(() => {
    if (!pickupAssignmentState.createdGroupId) return;
    const isGroupShown = pickupAssignmentState.pickups.findIndex(obj => (isGroup(obj) && obj.id === pickupAssignmentState.createdGroupId)) >= 0;
    setSelectedPickupGroupId(isGroupShown ? pickupAssignmentState.createdGroupId : "");
  }, [pickupAssignmentState.createdGroupId, pickupAssignmentState.pickups]);

  return (
    <ContentContainer title="Pickup Assignment" mods={{ 'full-width': true }}>
      <div className="xgs-pickup-assignment">
        <div className="xgs-pickup-assignment__row">
          <div className="xgs-pickup-assignment__map">
            <PickupMap
              pickupIds={selectedPickupIds}
              groupId={selectedPickupGroupId}
              driverId={selectedDriverId}
              showAllRoutes={showAllRoutes}
              onDriverSelect={(driverId) => {
                selectDriver(driverId);
                setScrollToDriverId(driverId);
               }}
            />
          </div>
          <div className="xgs-pickup-assignment__drivers">
            <div className="xgs-pickup-assignment__block-header">
              <span>Drivers</span>
              <XGSToggle
                onChange={changeRoutesMode}
                checked={showAllRoutes}
                label="Show all routes"
              />
            </div>
            <DriversList
              pickupIds={selectedPickupIds}
              groupId={selectedPickupGroupId}
              selectedDriverId={selectedDriverId}
              scrollToDriverId={scrollToDriverId}
              onDriverSelect={(driverId) => selectDriver(driverId)}
              onClickReschedule={() => {
                setShowPickupDetailsId(selectedPickupIds[0]);
                setShowReschedule(true);
              }}
            />
          </div>
        </div>
        <div className="xgs-pickup-assignment__pickups">
          <div className="xgs-pickup-assignment__block-header">
            <div>Orders List</div>
            <PickupListFilters
              onSetExpiredOnly={(v) => {
                setExpiredOnly(v);
                clearSelectedPickup();
              }}
              onSetStatuses={(v) => {
                setStatuses(v);
                clearSelectedPickup();
              }}
              onSetPickupNumber={(v) => {
                setPickupNumber(v);
                clearSelectedPickup();
              }}
            />
          </div>
          <PickupList
            expiredOnly={expiredOnly}
            selectedPickupIds={selectedPickupIds}
            selectedPickupGroupId={selectedPickupGroupId}
            statuses={statuses}
            terminal={userState.activeTerminal?.id || null}
            pickupNumber={pickupNumber}
            onPickupSelect={(ids) => {
              setSelectedPickupIds(ids);
              setSelectedDriverId("");
              setShowPickupDetailsId("");
            }}
            onGroupSelect={(id) => {
              setSelectedPickupGroupId(id);
            }}
            onShowPickupDetails={(id) => setShowPickupDetailsId(id)}
          />
        </div>
        <PickupDetails
          pickupId={showPickupDetailsId}
          onClose={() => {
            setShowPickupDetailsId("");
            setShowReschedule(false);
          }}
          showReschedule={showReschedule}
        />
      </div>
    </ContentContainer>
  );
};

export default PickupAssignment;

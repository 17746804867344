
export default interface FeedbackState {
  requestStarted: boolean;
  requestSucceed: boolean;
  requestFailed: boolean;
  requestError?: string | null;
  requestCreator: string | null;
  subjects: any[];
}

export const initialFeedbackState: FeedbackState = {
  requestStarted: false,
  requestSucceed: false,
  requestFailed: false,
  requestError: null,
  requestCreator: null,
  subjects: []
};

import React from "react";
import { StepProps } from "../../../app/data/common/models";
import { AppointmentStepIndicator } from "./appointmentStepIndicator";
import Loading from "../../../ui-components/loading/loading";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import { useDispatch, useSelector } from "react-redux";
import {
  appointmentSelector,
  createAppointment,
} from "../../../slices/appointments/appointmentsSlice";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import { ContactCard } from "../../customers/account-contact/contactCard";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import { toast } from "react-toastify";
import { decrementUnscheduledProbillsLocally, updateShipmentsAppointmentLocally } from "../../../slices/track-shipment/trackShipmentSlice";
import scheduleAvailableBlue from "../../../images/schedule-available_blue.svg";
import note2Blue from "../../../images/note-2_blue.svg";
import { ReactComponent as Info } from "../../../images/info.svg";
import arrowLeft from "../../../images/arrow-left.svg";

export interface AppointmentConfirmStepProps extends StepProps {
  closeModal: any;
  stepCount: number;
  stepIdx: number;
}

export const AppointmentConfirmStep: React.FC<AppointmentConfirmStepProps> = (props) => {
  const dispatch = useDispatch();
  const appointmentState = useSelector(appointmentSelector);

  const handleSubmit = () => {
    appointmentState.currentAppointment &&
      dispatch(
        createAppointment(
          appointmentState.currentAppointment,
          () => {
            toast.info("Appointment set successfully");
            dispatch(updateShipmentsAppointmentLocally({
              probillNumber: appointmentState.currentAppointment?.probillNumber,
              date: appointmentState.currentAppointment?.date,
              earlyTime: appointmentState.currentAppointment?.earlyTime,
              lateTime: appointmentState.currentAppointment?.lateTime,
            }))

            if (!appointmentState.existingAppointment) dispatch(decrementUnscheduledProbillsLocally());
            props.closeModal();
          },
          (message: string) => {
            toast.error(message || "Something went wrong");
          }
        )
      );
  };

  return (
    <div>
      <div className="xgs-create-appt__wizard">
        <div className="xgs-create-appt__wizard__check">
          <XGSIcon
            className="xgs-create-appt__wizard__check__tick"
            icon={XGSIcons.faCheck}
          />
        </div>
        <div className="xgs-create-appt__wizard__heading">Review</div>
      </div>
      <AppointmentStepIndicator step={props.stepIdx} stepCount={props.stepCount} />
      {appointmentState.fetchConsigneeStarted && <Loading isLoading />}
      {appointmentState.fetchConsigneeFailed && (
        <XGSErrorMessage>{appointmentState.fetchConsigneeError}</XGSErrorMessage>
      )}
      {appointmentState.currentContact && (
        <ContactCard hideButtons contact={appointmentState.currentContact} />
      )}
      <div className="xgs-create-appt__confirm-tile">
        <div>
          <img src={scheduleAvailableBlue} alt="calendar" />
          {appointmentState.currentAppointment?.date.toUiDateFormat()}&nbsp;&nbsp;
          {appointmentState.currentAppointment?.earlyTime.toUiTimeFormat()} -{" "}
          {appointmentState.currentAppointment?.lateTime.toUiTimeFormat()}
        </div>
        {appointmentState.currentAppointment?.notes && <div>
          <img src={note2Blue} alt="note" />
          {appointmentState.currentAppointment?.notes}
        </div>}
      </div>
      <div className="xgs-create-appt__confirm-tile">
        <div className="xgs-create-appt__important">
          <Info className="xgs-create-appt__important__icon"/>
          <span>
            <b>Important:</b> <br />
            Customer will be sent a notification with appointment details.
          </span>
        </div>
      </div>
      <div className="xgs-create-appt__form__submit">
        <Button
          type="button"
          onClick={() => props.previous && props.previous()}
          theme={ButtonThemes.gray}
        >
          <img src={arrowLeft} alt="arrow-left"/>
          &nbsp;&nbsp;Back
        </Button>
        <Button spinner={appointmentState.requestStarted} onClick={handleSubmit} theme={ButtonThemes.blue}>Set Appointment</Button>
      </div>
    </div>
  );
};

import {
  ManifestStatusProfile, ManifestStopProfileRaw, PickupProfile, ProbillsRawAll,
} from '../../app/data/manifest/manifestProfiles';

export default interface ManifestsState {
  fetch_was_started: boolean;
  fetchPortion_was_started: boolean;
  fetch_was_succeed: boolean;
  fetch_was_failed: boolean;
  fetch_fail_reason?: string | null;
  update_was_started: boolean;
  update_creator?: string;
  update_was_succeed: boolean;
  update_was_failed: boolean;
  update_fail_reason?: string | null;
  requestCreator: string;
  manifestsStatuses: ManifestStatusProfile[];
  manifestsStops: {[key: string]: ManifestStopProfileRaw[]};
  manifestsProbills: ProbillsRawAll;
  manifestsPickups: {[key: string]: PickupProfile[]};
  manifestMapData: any;
  manifestsRequest: any;
  manifestExceptionsData: any;
  manifestsFetchedAll: boolean;
  userPermissions: any;
  map_fetch_was_failed: boolean;
  map_fetch_was_succeed: boolean;
  map_fetch_was_started: boolean;
  map_fetch_fail_reason: string;
  loadingCSVStarted: boolean;
  activeProbill: number | null;
}

export const initialManifestsState: ManifestsState = {
  fetch_was_started: false,
  fetchPortion_was_started: false,
  fetch_was_succeed: false,
  fetch_was_failed: false,
  fetch_fail_reason: null,
  update_was_started: false,
  update_creator: undefined,
  update_was_succeed: false,
  update_was_failed: false,
  update_fail_reason: null,
  requestCreator: "",
  manifestsStatuses: [],
  manifestsStops: {},
  manifestsProbills: {},
  manifestExceptionsData: {},
  manifestsPickups: {},
  manifestMapData: null,
  manifestsRequest: null,
  manifestsFetchedAll: false,
  userPermissions: null,
  map_fetch_was_failed: false,
  map_fetch_was_succeed: false,
  map_fetch_was_started: false,
  map_fetch_fail_reason: "",
  loadingCSVStarted: false,
  activeProbill: null,
}
import * as Yup from "yup";
import { validationMessages } from "../common/validationMessages";

export interface AnnouncementModel {
  id?: string;
  header: string;
  body: string;
  needUserAcceptance?: boolean;
  needUserNotification?: boolean;
  from?: string | null;
  to?: string | null;
  status?: string;
  recipientType: "EMPLOYEE" | "CUSTOMER";
};

export const AnnouncementSchema: Yup.ObjectSchema<AnnouncementModel> = Yup.object({
  id: Yup.string(),
  header: Yup.string().required(validationMessages.required).max(64, "The value must be no more than 64 characters"),
  body: Yup.string().required(validationMessages.required).max(2048, "The value exceeds the maximum length"),
  needUserAcceptance: Yup.boolean(),
  needUserNotification: Yup.boolean(),
  from: Yup.string().nullable(),
  to: Yup.string().nullable(),
  status: Yup.string(),
  recipientType: Yup.string()
}).defined();

export interface TestEmailModel {
  email: string;
};

export const TestEmailSchema: Yup.ObjectSchema<TestEmailModel> = Yup.object({
  email: Yup.string().max(60, "The value must be no more than 60 characters").email(validationMessages.emailNotValid).required(validationMessages.required)
}).defined();

export interface AnnouncementResponseModel {
  id: string;
  header: string;
  body: string;
  status: string;
  needUserAcceptance: boolean;
  needUserNotification: boolean;
  from: string;
  to: string;
  lastNotificationDate: string;
  nextNotificationDate: string;
  recipientType: string;
};

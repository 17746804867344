import { IState } from "../..";
import { createSlice } from "@reduxjs/toolkit";
import cloneDeep from "lodash/cloneDeep";
import { AppThunk } from "../../../app/store";
import EmployeesService from "../../../app/data/employees/employeesService";
import UserService from "../../../app/data/user/userService";
import { initialEmployeesState } from "./EmployeesState";
import { InvitationDataModel } from "../../../app/data/user/UserProfile";
import { ProfileSettingsRequestModel } from "../../../app/data/user/requestModels";

const employeesService = EmployeesService.getInstance();
const userService = UserService.getInstance();

export const employeesSlice = createSlice({
  name: "employees",
  initialState: initialEmployeesState,
  reducers: {
    fetch_was_started: (state, { payload }) => {
      if (payload) {
        state.fetchPortion_was_started = true;
      } else {
        state.fetch_was_started = true;
      }
      state.fetch_was_succeed = false;
      state.fetch_was_failed = false;
      state.fetch_fail_reason = null;
    },
    fetch_was_succeed: (state) => {
      state.fetch_was_succeed = true;
      state.fetch_was_started = false;
      state.fetchPortion_was_started = false;
      state.fetch_was_failed = false;
    },
    set_employees: (state, { payload }) => {
      state.employees = payload.content;
      state.employeesRequest = payload.scroll;
      state.employeesFetchedAll = !!(payload.content.length < 50);
    },
    add_employees: (state, { payload }) => {
      state.employees = [...state.employees, ...payload.content];
      state.employeesRequest = payload.scroll;
      state.employeesFetchedAll = !!(payload.content.length < 50);
    },
    fetch_was_failed: (state, { payload }) => {
      state.fetch_was_failed = true;
      state.fetch_was_succeed = false;
      state.fetch_was_started = false;
      state.fetch_fail_reason = payload;
    },
    update_was_started: (state, { payload }) => {
      state.update_was_started = true;
      state.update_was_succeed = false;
      state.update_was_failed = false;
      state.update_fail_reason = null;
      state.update_creator = payload;
    },
    update_was_succeed: (state) => {
      state.update_was_succeed = true;
      state.update_was_started = false;
      state.update_was_failed = false;
      state.update_creator = undefined;
    },
    update_employee: (state, { payload }) => {
      let index = state.employees.findIndex(employee => employee.id === payload.id);
      // we could use 'state.employees[index][field] = data' below, 
      // but TS has limitation of accessing fields with possible different types by variable property name
      let employee = cloneDeep(state.employees[index]);
      state.employees[index] = Object.defineProperty(employee, payload.field, {
        configurable: true,
        enumerable: true,
        writable: true,
        value: payload.data
      });
    },
    update_employee_invite: (state, { payload }) => {
      let index = state.employees.findIndex(user => user.id === payload.id);
      state.employees[index].invitation = {
        date: payload.data.date,
        link: payload.data.link
      };
      // we could use 'state.employees[index][field] = data' below, 
      // but TS has limitation of accessing fields with possible different types by variable property name
      // let user = cloneDeep(state.employees[index]);
      // state.employees[index].invitation = Object.defineProperty(user, "date", {
      //   configurable: true,
      //   enumerable: true,
      //   writable: true,
      //   value: payload.data.date
      // });
      // state.employees[index].invitation = Object.defineProperty(user, "link", {
      //   configurable: true,
      //   enumerable: true,
      //   writable: true,
      //   value: payload.data.link
      // });
    },
    update_employee_obj: (state, { payload }) => {
      let index = state.employees.findIndex(employee => employee.id === payload.id);
      if (payload.request.email) {
        payload.request.pendingEmail = payload.request.email;
        delete payload.request.email;
      }
      state.employees[index] = {...state.employees[index], ...payload.request};
    },
    update_was_failed: (state, { payload }) => {
      state.update_was_failed = true;
      state.update_was_succeed = false;
      state.update_was_started = false;
      state.update_fail_reason = payload;
    },
    resetErrors: (state) => {
      state.fetch_was_failed = false;
      state.fetch_fail_reason = null;
      state.update_was_failed = false;
      state.update_fail_reason = null;
    }
  }
});

export const {
  fetch_was_started,
  fetch_was_succeed,
  set_employees,
  add_employees,
  fetch_was_failed,
  update_was_started,
  update_was_succeed,
  update_employee,
  update_employee_invite,
  update_employee_obj,
  update_was_failed,
  resetErrors
} = employeesSlice.actions;

export const employeesSelector = (state: IState) => state.employees;

export const getEmployees = (
  lastIds: string,
  searchString?: string
): AppThunk => async (dispatch) => {
  dispatch(fetch_was_started(!!lastIds));
  const response = await employeesService.getEmployees(lastIds, searchString);
  if (response.ok()) {
    dispatch(fetch_was_succeed());
    dispatch(lastIds ? add_employees(response.data) : set_employees(response.data));
  } else {
    response.getError && dispatch(fetch_was_failed(response.getError()));
  }
};

export const updateEmployee = (
  userId: string,
  field: string,
  data: string,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(update_was_started(field));
  const request = {};
  // we could use 'request[field] = data' below, but TS has limitation of accessing fields with possible different types by variable property name
  Object.defineProperty(request, field, {
    enumerable: true,
    value: data
  });
  const response = await employeesService.updateEmployee(userId, request);
  if (response.ok()) {
    dispatch(update_employee({
      id: userId,
      field: field,
      data: data
    }));
    dispatch(update_was_succeed());
    onSuccess && onSuccess();
  } else {
    response.getError && dispatch(update_was_failed(response.getError()));
  }
};

export const updateEmployeeInviteData = (
  userId: string,
  data: InvitationDataModel,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(update_was_started("invite"));
  dispatch(update_employee_invite({
    id: userId,
    data: data
  }));
  dispatch(update_was_succeed());
  onSuccess && onSuccess();
};

export const deleteEmployee = (
  userId: string,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(update_was_started("delete"));
  const response = await employeesService.deleteEmployee(userId);
  if (response.ok()) {
    dispatch(update_was_succeed());
    onSuccess && onSuccess();
  } else {
    response.getError && dispatch(update_was_failed(response.getError()));
  }
};

export const updateEmployeeProfile = (
  request: ProfileSettingsRequestModel,
  userId: string,
  onSuccess: () => void
): AppThunk => async (dispatch) => {
  dispatch(update_was_started("UPDATE_PROFILE"));
  const response = await userService.updateProfile(userId, request);
  if (response.ok()) {
    dispatch(update_employee_obj({
      id: userId,
      request
    }));    
    dispatch(update_was_succeed());
    onSuccess();
  } else {
    response.getError && dispatch(update_was_failed(response.getError()));
  }
};

const employeesReducer = employeesSlice.reducer;
export default employeesReducer;

import React, { useCallback, useEffect, useRef, useState } from "react";
import SlideOutSidebar from "../../ui-components/slide-out-sidebar/slideOutSidebar";
import { Field, Form, Formik, FormikProps } from "formik";
import { getLinehaulLaneRequestModel, LinehaulLaneRequest } from "../../app/data/linehaul-mileage/models";
import { LabelModes } from "../../ui-components/molecules/labeled-inputs/labeledInput";
import XGSFormInput from "../../ui-components/form/input/xgsFormInput";
import { useDispatch, useSelector } from "react-redux";
import { getServiceCenters, serviceCentersSelector } from "../../slices/service-centers/serviceCentersSlice";
import { resetServiceCentersStatus } from "../../slices/service-centers-status/serviceCentersStatusSlice";
import Button, { ButtonThemes } from "../../ui-components/button/button";
import check from "../../images/check_white.svg";
import { createLinehaulLane, editLinehaulLane, linehaulMileageSelector } from "../../slices/linehaul-mileage/linehaulMileageSlice";
import { toast } from "react-toastify";
import ServiceCentersState from "../../slices/service-centers/ServiceCentersState";
import { XGSSelectOption } from "../../ui-components/xgs-select/xgsSelect";
import XGSFormSelect from "../../ui-components/form/select/xgsFormSelect";
import XGSFormTextarea from "../../ui-components/form/textarea/xgsFormTextarea";
import LabeledSelectInput from "../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import usaStates from "../../app/data/common/usaStates";

export interface AddLaneProps {
  show: boolean;
  closeModal: any;
  originTerminalNumber?: number;
  destinationTerminalNumber?: number;
  savedValues?: any;
  isEditing?: boolean;
  laneId?: string;
}

const _initialValues: LinehaulLaneRequest = {
  laneName: "",
  miles: "",
  relay: false,
  relayCity: "",
  relayState: "",
  relayZipcode: '',
  laneNotes: "",
  originTerminalNumber: null,
  destinationTerminalNumber: null
}

export const AddLane: React.FC<AddLaneProps> = (props) => {
  const dispatch = useDispatch();
  const formRef = useRef<FormikProps<LinehaulLaneRequest> | null>(null);
  const serviceCentersState: ServiceCentersState = useSelector(serviceCentersSelector);
  const linehaulMileageState = useSelector(linehaulMileageSelector);
  const [origin, setOrigin] = useState<XGSSelectOption | null | undefined>();
  const [destination, setDestination] = useState<XGSSelectOption | null | undefined>();
  const initalValues = { ..._initialValues, ...(props.savedValues ? props.savedValues : {})};
  const isEditing = props.isEditing;
  const [hasRelay, setHasRelay] = useState(initalValues.relay);

  useEffect(() => {
    dispatch(getServiceCenters());
    return () => {
      dispatch(resetServiceCentersStatus());
    }
  }, [dispatch])

  const cancel = () => {
    formRef.current?.resetForm();
    props.closeModal();
  }

  const getOriginsOptions = useCallback(() => {
    return (
      serviceCentersState.origins?.map(
        (origin): XGSSelectOption => ({
          label: origin.name,
          value: origin.id.toString(),
        })
      ) || []
    );
  }, [serviceCentersState])

  const getDestinationsOptions = useCallback(() => {
    return (
      serviceCentersState.destinations?.map(
        (destination): XGSSelectOption => ({
          label: destination.name,
          value: destination.id.toString(),
        })
      ) || []
    );
  }, [serviceCentersState.destinations]);
  console.log(formRef.current?.errors)
  const onOriginChanged = (_origin: XGSSelectOption | null | undefined) => {
    setOrigin(_origin);
  };

  const onDestinationChanged = (_destination: XGSSelectOption | null | undefined) => {
    setDestination(_destination);
  };

  const onSubmit = (request: LinehaulLaneRequest) => {
    dispatch(
      !isEditing ? (
        createLinehaulLane(
          { ...request, 
            ...(request.relay ? {} : {relay: false, relayCity: undefined, relayState: undefined, relayZipcode: undefined}) 
          },
          () => {
            toast.info(isEditing ? "Lane updated" : "Lane created");
            props.closeModal();
          },
          (error: string) => toast.error(error))
        ) : (
        editLinehaulLane(
          props.laneId!,
          { ...request },
          () => {
            toast.info(isEditing ? "Lane updated" : "Lane created");
            props.closeModal();
          },
          (error: string) => toast.error(error)
          )
        )
    )
  }

  useEffect(() => {
    setOrigin(getOriginsOptions().find(o => o?.value === props.savedValues?.originTerminalNumber + ""))
    setDestination(getDestinationsOptions().find(d => d?.value === props.savedValues?.destinationTerminalNumber + ""))
    formRef.current?.setFieldValue("originTerminalNumber", props.savedValues?.originTerminalNumber);
    formRef.current?.setFieldValue("destinationTerminalNumber", props.savedValues?.destinationTerminalNumber);
  }, [props.savedValues?.originTerminalNumber, props.savedValues?.destinationTerminalNumber, getDestinationsOptions, getOriginsOptions]);


  return (
    <SlideOutSidebar size={"small"} header="Add Lane" show={props.show} onClose={props.closeModal}>
      <div className="xgs-lmi__title">Add New Linehaul Lane</div>
      <Formik
        initialValues={initalValues}
        innerRef={formRef}
        onSubmit={onSubmit}
        validationSchema={getLinehaulLaneRequestModel(hasRelay)}
        enableReinitialize
      >
        {(formProps: FormikProps<LinehaulLaneRequest>) => (
          <Form className="xgs-lmi__form">
            <XGSFormInput
              label="Linehaul Lane Name:"
              name="laneName"
              required
              labelMode={LabelModes.column}
              className="xgs-lmi__form__input"
              type="text"
              placeholder="Name"
            />
            <XGSFormInput
              label="Miles:"
              name="miles"
              required
              labelMode={LabelModes.column}
              className="xgs-lmi__form__input"
              type="number"
              placeholder="Miles"
            />
            <XGSFormSelect
              onValueChange={(option) => {
                formRef.current?.setFieldValue("originTerminalNumber", option?.value);
                onOriginChanged(option)
              }}
              value={origin}
              options={getOriginsOptions()}
              disabled={!!props.savedValues?.originTerminalNumber}
              name="originTerminalNumber"
              label="Origin:"
              labelMode={LabelModes.column}
              className="xgs-tracking-filter__input"
            />
            <LabeledSelectInput
              onValueChange={(option) => {
                formRef.current?.setFieldValue("destinationTerminalNumber", option?.value);
                onDestinationChanged(option)
              }}
              value={destination}
              options={getDestinationsOptions()}
              disabled={!!props.savedValues?.destinationTerminalNumber}
              label="Destination:"
              name="destinationTerminalNumber"
              labelMode={LabelModes.column}
              className="xgs-tracking-filter__input"
            />
             <XGSFormTextarea
              label="Lane Notes:"
              className="xgs-lmi__form__input"
              type="text"
              name="laneNotes"
              placeholder="Notes"
              counter={40}
            />
            <div className="xgs-lmi__form__relay">
              <Field
                disabled={isEditing}
                name="relay"
                id="relay"
                label="Relay"
                type="checkbox"
                checked={!!hasRelay}
                onClick={() => setHasRelay((state: any) => !state)}
              />
              <label
                htmlFor="relay"
              >
                Relay
              </label>
            </div>
            {hasRelay && (
              <>
                <XGSFormInput
                  disabled={isEditing}
                  label="Relay city:"
                  required
                  labelMode={LabelModes.column}
                  className="xgs-lmi__form__input"
                  type="text"
                  name="relayCity"
                  placeholder="City"
                />
                <XGSFormSelect
                  options={usaStates}
                  disabled={isEditing}
                  label="Relay state:"
                  required
                  labelMode={LabelModes.column}
                  className="xgs-lmi__form__input"
                  type="text"
                  name="relayState"
                  placeholder="State"
                  controlled={true}
                />
                <XGSFormInput
                  disabled={isEditing}
                  label="Relay Zipcode:"
                  required
                  labelMode={LabelModes.column}
                  className="xgs-lmi__form__input"
                  type="text"
                  name="relayZipcode"
                  placeholder="Zip"
                />
              </>
            )}
            <div className="xgs-lmi__form__buttons">
              <Button theme={ButtonThemes.gray} type="button" onClick={cancel}>
                Cancel
              </Button>
              {!isEditing && (
                <Button spinner={linehaulMileageState.requestStarted} theme={ButtonThemes.blue} type="submit">
                  <img alt="Checkbox" src={check}/> &nbsp;Add
                </Button>
              )}
              {isEditing && (
                <Button spinner={linehaulMileageState.requestStarted} theme={ButtonThemes.blue} type="submit">
                  <img alt="Checkbox" src={check}/> &nbsp;Update
                </Button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </SlideOutSidebar>
  )
}

export const ACCOUNT_SERVICES = [
  {
    value: "INSIDE_DELIVERY",
    label: "Inside Delivery",
    description: "Move shipments from or to positions beyond the immediately adjacent loading or unloading area."
  },
  {
    value: "LIMITED_ACCESS",
    label: "Limited Access",
    description: "When pick-up or delivery service is provided for locations where tractor trailer clearance is restricting."
  },
  {
    value: "LIFTGATE",
    label: "Liftgate",
    description: "Liftgate required in order to accomplish pickup or delivery of a shipment."
  },
  {
    value: "RESIDENTIAL_AREA",
    label: "Residential Delivery",
    description: "Pick up or delivery to a residential area or apartment. (Charges not applicable in conjunction with White Glove Service.)"
  },
  {
    value: "SORT",
    label: "Sort and Segregate",
    description: "Sort and/or segregate Consignor or Consignee's shipment."
  }
];
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { toast } from "react-toastify";
import "../../../ui-components/tabs/tabs.scss";
import SlideOutSidebar from "../../../ui-components/slide-out-sidebar/slideOutSidebar";
import Loading from "../../../ui-components/loading/loading";
import TeamsState from "../../../slices/customers/teams/TeamsState";
import { teamsSelector } from "../../../slices/customers/teams/teamsSlice";
import TeamAccountState from "../../../slices/customers/teams/TeamAccountState";
import {
  resetErrors,
  teamAccountSelector,
  updateTeam
} from "../../../slices/customers/teams/teamAccountSlice";
import { updateTeamNameLocally } from "../../../slices/customers/teams/teamsSlice";
import TeamAccounts from "./teamAccounts"
import TeamShippers from "./teamShippers"
import TeamUsers from "./teamUsers"
import { ERROR_MESSAGES } from "../../../app/data/common/errorMessages";
import "./teams.scss";
import ConfirmationModal from "../../../ui-components/confirmation-modal/confirmationModal";

export interface TeamDetailsProps {
  id: string | undefined;
  show: boolean;
  onClose: () => void;
}

const TeamDetails: React.FC<TeamDetailsProps> = (props) => {
  const dispatch = useDispatch();
  const teamsState: TeamsState = useSelector(teamsSelector);
  const teamAccountState: TeamAccountState = useSelector(teamAccountSelector);
  const [teamName, setTeamName] = useState("");
  const [editTeamNameConfirm, setEditTeamNameConfirm] = useState(false);

  const onTeamNameInput = () => {
    const el = document.getElementById("xgs-teams__details__header--js") as HTMLInputElement;
    if (el.innerText.length > 30) {
      const cuttedName = el.innerText.slice(0, 30);
      setTeamName(cuttedName);
      el.innerText = cuttedName;
    }
  };

  const onTeamNameKeyPress = (e: any) => {
    if (e.charCode === 13) {
      e.preventDefault();
      const el = document.getElementById("xgs-teams__details__header--js") as HTMLElement;
      el.blur();
      onTeamNameBlur();
    }
  };

  const onTeamNameBlur = () => {
    if (!props.id) return;
    const teamOriginalName = teamsState.teams.find((team: any) => team.id === props.id)?.name;
    const el = document.getElementById("xgs-teams__details__header--js") as HTMLElement;
    if (el.textContent === teamOriginalName) return;
    if (!el.textContent || el.textContent?.length === 0 || !/\S/.test(el.textContent)) {
      // return to original name
      setTeamName(teamOriginalName);
      el.innerText = teamOriginalName;
    } else {
      // open confirmation modal
      setEditTeamNameConfirm(true);
    }
  };

  const onCancelEditTeamName = () => {
    setEditTeamNameConfirm(false);
    const teamOriginalName = teamsState.teams.find((team: any) => team.id === props.id)?.name;
    const el = document.getElementById("xgs-teams__details__header--js") as HTMLElement;

    setTeamName(teamOriginalName);
    el.innerText = teamOriginalName;
  }

  const onConfirmEditTeamName = () => {
    dispatch(updateTeam({
      teamId: props.id ?? '',
      name: document.getElementById("xgs-teams__details__header--js")?.textContent ?? ''
    }, () => {
      // update name in the teams list without reload of entire list
      dispatch(updateTeamNameLocally({
        teamId: props.id,
        name: document.getElementById("xgs-teams__details__header--js")?.textContent ?? ''
      }));
    }));
    setEditTeamNameConfirm(false);
  }

  useEffect(() => {
    setTeamName(teamsState.teams.find((team: any) => team.id === props.id)?.name);
  }, [teamsState.teams, props.id]);

  useEffect(() => {
    if (!teamAccountState.request_was_failed) return;
    toast.error(teamAccountState.request_fail_reason || ERROR_MESSAGES.COMMON);
    dispatch(resetErrors());
  }, [dispatch, teamAccountState.request_was_failed, teamAccountState.request_fail_reason]);

  let currentTeam = teamsState.teams.find((team: any) => team.id === props.id);

  return (
    <SlideOutSidebar
      header="Team information"
      onClose={props.onClose}
      show={props.show}
      className="xgs-teams__details"
    >
      <>
        <div className="xgs-teams__details__header-container">
          <div
            className="xgs-teams__details__header"
            onInput={onTeamNameInput}
            onBlur={onTeamNameBlur}
            onKeyPress={onTeamNameKeyPress}
            id="xgs-teams__details__header--js"
            contentEditable
            suppressContentEditableWarning={true}
          >{teamName}</div>
          <Loading isLoading={teamAccountState.request_was_started} />
        </div>
        <div className="xgs-teams__details__team-type">{currentTeam?.agent ? "Agent" : "Customer"}</div>
        <Tabs>
          <TabList>
            <Tab>Accounts</Tab>
            <Tab disabled={currentTeam?.agent}>Shippers</Tab>
            <Tab>Users</Tab>
          </TabList>
          <TabPanel>
            {props.id && <TeamAccounts id={props.id} />}
          </TabPanel>
          <TabPanel>
            {props.id && <TeamShippers id={props.id} />}
          </TabPanel>
          <TabPanel>
            {props.id && <TeamUsers agent={currentTeam?.agent} id={props.id} />}
          </TabPanel>
        </Tabs>

        <ConfirmationModal
          opened={editTeamNameConfirm}
          confirmButtonText="Yes"
          cancelButtonText="No"
          onCancel={() => onCancelEditTeamName()}
          onConfirm={() => onConfirmEditTeamName()}
        >
          <span className="--team-update-modal">Confirm team name update?</span>
        </ConfirmationModal>
      </>
    </SlideOutSidebar>
  );
};

export default TeamDetails;

import api, { ApiResponse } from "../api";

class EmployeesService {
  private static instance: EmployeesService;
  private constructor() {}

  static getInstance(): EmployeesService {
    if (!EmployeesService.instance) {
      EmployeesService.instance = new EmployeesService();
    }
    return EmployeesService.instance;
  }

  public getEmployees = async (
    lastIds: string,
    searchString?: string
  ): Promise<ApiResponse<any>> => {
    const params = new URLSearchParams();
    lastIds && params.append("lastIds", lastIds);
    searchString && params.append("search", searchString);
    return await api.get("/employees", { params });
  };

  public updateEmployee = async (
    userId: string,
    request: any
  ): Promise<ApiResponse<any>> => {
    return await api.put(`/employees/${userId}`, request);
  };

  public deleteEmployee = async (
    userId: string
  ): Promise<ApiResponse<any>> => {
    return await api.delete(`/users/${userId}`);
  };
}

export default EmployeesService;

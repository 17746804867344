import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import { ManualReturnRequestModel, ReturnRequestModel } from "../../app/data/return/models";
import ReturnService from "../../app/data/return/returnService";
import BolService from "../../app/data/bol/bolService";
import RatesService from "../../app/data/rates/ratesService";
import { GetRatesRequestModel } from "../../app/data/rates/models";
import { initialReturnState } from "./ReturnState";

const returnService = ReturnService.getInstance();
const bolService = BolService.getInstance();
const requestRatesService = RatesService.getInstance();

export const returnSlice = createSlice({
  name: "return",
  initialState: initialReturnState,
  reducers: {
    resetReturnState: (state) => initialReturnState,
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestSucceed = false;
      state.requestFailed = false;
      state.requestCreator = payload;
      state.requestError = "";
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestSucceed = true;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestSucceed = false;
      state.requestFailed = true;
      state.requestError = payload;
    },
    setReturnCommon: (state, { payload }) => {
      state.returnCommon.requestorRole = payload.requestorRole;
      state.returnCommon.referenceNumber = payload.referenceNumber;
      if (payload.poNumber !== undefined) {
        state.returnCommon.poNumber = payload.poNumber;
      }
      if (payload.originalBolNumber !== undefined) {
        state.returnCommon.originalBolNumber = payload.originalBolNumber;
      }
    },
    setReturnShipper: (state, { payload }) => {
      state.returnShipper = {
        name: payload.name,
        address: {
          address1: payload.address.address1,
          city: payload.address.city,
          postalCode: payload.address.postalCode,
          state: payload.address.state
        },
        phone: payload.phone,
        email: payload.email,
        shipperAvailabilityHours: {
          from: payload.shipperAvailabilityHours.from,
          to: payload.shipperAvailabilityHours.to
        },
        specialInstructions: payload.specialInstructions,
        specialServices: payload.specialServices,
        pickupDate: payload.pickupDate
      }
    },
    setFreightLabeled: (state, { payload }) => {
      state.freightLabeled = payload;
    },
    setReturnConsignee: (state, { payload }) => {
      state.returnConsignee = {
        name: payload.name,
        address: {
          address1: payload.address.address1,
          city: payload.address.city,
          postalCode: payload.address.postalCode,
          state: payload.address.state
        },
        phone: payload.phone,
        email: payload.email
      }
    },
    addItem: (state, { payload }) => {
      state.items.push({
        packageType: payload.packageType,
        units: payload.units ? parseFloat(payload.units) : undefined,
        notes: payload.notes,
        yards: payload.yards ? parseFloat(payload.yards) : undefined,
        weight: payload.weight ? parseFloat(payload.weight) : undefined,
        length: payload.length ? parseFloat(payload.length) : undefined,
        width: payload.width ? parseFloat(payload.width) : undefined,
        height: payload.height ? parseFloat(payload.height) : undefined,
        ratingClass: payload.ratingClass,
        description: payload.description,
        sidemark: payload.sidemark,
        deleted: false
      });
    },
    updateItem: (state, { payload }) => {
      const i = payload.index;
      state.items[i].packageType = payload.data.packageType;
      state.items[i].units = payload.data.units ? parseFloat(payload.data.units) : undefined;
      state.items[i].yards = payload.data.yards ? parseFloat(payload.data.yards) : undefined;
      state.items[i].weight = payload.data.weight ? parseFloat(payload.data.weight) : undefined;
      state.items[i].length = payload.data.length ? parseFloat(payload.data.length) : undefined;
      state.items[i].width = payload.data.width ? parseFloat(payload.data.width) : undefined;
      state.items[i].height = payload.data.height ? parseFloat(payload.data.height) : undefined;
      state.items[i].ratingClass = payload.data.ratingClass;
      state.items[i].description = payload.data.description;
      state.items[i].notes = payload.data.notes;
      state.items[i].sidemark = payload.data.sidemark;
    },
    deleteItem: (state, { payload }) => {
      state.items[payload].deleted = true;
    },
    clearDeletedItems: (state) => {
      state.items = state.items.filter(item => !item.deleted);
    },
    clearItems: (state) => {
      state.items = [];
    },
    resetErrors: (state) => {
      state.requestFailed = false;
      state.requestError = "";
    },
    storeRatesResult: (state, { payload }) => {
      state.rates = payload;
    },
    storeReferenceDocumentFilename: (state, { payload }) => {
      state.referenceDocumentFilename = payload;
    },
    storeReferenceDocumentId: (state, { payload }) => {
      state.referenceDocumentId = payload;
    },
    removeReferenceDocument: (state) => {
      state.referenceDocumentFilename = "";
      state.referenceDocumentId = "";
    },
    storeMills: (state, { payload }) => {
      state.mills = payload;
    },
    setShipperZipServiceable: (state, { payload }) => {
      state.shipperZipServiceable = payload;
    },
    setShipperZipChecked: (state, { payload }) => {
      state.shipperZipChecked = payload;
    }
  }
});

export const {
  resetReturnState,
  requestStarted,
  requestSucceed,
  requestFailed,
  setReturnCommon,
  setReturnShipper,
  setFreightLabeled,
  setReturnConsignee,
  addItem,
  updateItem,
  deleteItem,
  clearDeletedItems,
  clearItems,
  resetErrors,
  storeRatesResult,
  storeReferenceDocumentFilename,
  storeReferenceDocumentId,
  removeReferenceDocument,
  setShipperZipServiceable,
  setShipperZipChecked,
  storeMills
} = returnSlice.actions;

export const returnSelector = (state: IState) => {
  return state.return;
};

export const createReturn = (
  request: ReturnRequestModel,
  onSuccess?: (probill: number) => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("CREATE_REQUEST"));
  const response = await returnService.createReturn(request);
  if (response.ok()) {
    dispatch(requestSucceed());
    onSuccess && onSuccess(response.data.probill);
  } else {
    dispatch(requestFailed(response.getError()));
  }
};

export const checkShipperZip = (
  zip: string,
  onSuccess: () => void,
  onNotFound: () => void,
  onFailed: (error: any) => void
): AppThunk => async (dispatch) => {
  dispatch(setShipperZipServiceable(false));
  dispatch(requestStarted("CHECK_ZIP"));
  const response = await bolService.checkPickup(zip);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(setShipperZipServiceable(true));
    dispatch(setShipperZipChecked(zip));
    onSuccess();
  } else {
    dispatch(setShipperZipServiceable(false));
    dispatch(setShipperZipChecked(zip));
    const errorObj = response.getFullError() as any;
    if (errorObj?.status === 404) {
      dispatch(requestSucceed());
      onNotFound();
    } else {
      const error = response.getError();
      dispatch(requestFailed(error));
      onFailed(error);
    }
  }
};

export const requestManualReturn = (
  request: ManualReturnRequestModel,
  onSuccess: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("MANUAL_RETURN"));
  const response = await returnService.requestManualReturn(request);
  if (response.ok()) {
    dispatch(requestSucceed());
    onSuccess();
  } else {
    dispatch(requestFailed(response.getError()));
  }
};

export const getReturnRates = (
  request: GetRatesRequestModel,
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("GET_RATES"));
  const response = await requestRatesService.getRates(request);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(storeRatesResult(response.data));
  } else {
    dispatch(requestFailed(response.getError()));
  }
};

export const uploadReferenceDocument = (
  file: any,
  onError: (error: any) => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("UPLOAD_REFERENCE"));
  const fd = new FormData();
  fd.append("file", file);
  const response = await returnService.uploadReferenceDocument(fd); 
  if (response.ok()) {
    dispatch(storeReferenceDocumentFilename(file.path));
    dispatch(storeReferenceDocumentId(response.data.id));
    dispatch(requestSucceed());
  } else {
    onError(response.getError());
  }
};

export const shareReturn = (
  probill: number,
  email: string,
  onSuccess: () => void,
  onFailed: (error: string) => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("SHARE_RETURN"));
  const response = await returnService.shareReturn(probill, email);
  if (response.ok()) {
    dispatch(requestSucceed());
    onSuccess();
  } else {
    const error = response.getError();
    dispatch(requestFailed(error));
    onFailed(`${error}`);
  }
};

export const getMillsList = (): AppThunk => async (dispatch) => {
  dispatch(requestStarted("GET_MILLS"));
  const response = await returnService.getMillsList();
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(storeMills(response.data));
  } else {
    dispatch(requestFailed(response.getError ? response.getError() : "Error"));
  }
};

const returnReducer = returnSlice.reducer;
export default returnReducer;
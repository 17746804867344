import React, { memo } from "react";
import Switch from "react-switch";
import { cn } from "../../services/common/className";
import "./xgs-toggle.scss";

export interface XGSToggleProps {
  label?: string;
  checked: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
}

const XGSToggle: React.FC<XGSToggleProps> = memo(({
  checked,
  disabled,
  label,
  onChange
}) => {
  return (
    <div className="xgs-toggle">
      <Switch
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        width={32}
        height={20}
        offColor="#BDBDBD"
        onColor="#2F80ED"
        uncheckedIcon={<></>}
        checkedIcon={<></>}
      />
      <span
        className={cn("xgs-toggle__label")({disabled}) + " no-select"}
        onClick={() => !disabled && onChange(!checked)}
      >
        {label}
      </span>
    </div>
  );
});

export default XGSToggle;

import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import InviteManagementService from "../../app/data/invite-management/inviteManagementService";
import { InviteSendModel } from "../../app/data/invite-management/models";
import { initialInviteManagementState } from "./InviteManagementState";

const inviteManagementService = InviteManagementService.getInstance();

export const inviteManagementSlice = createSlice({
  name: "inviteManagement",
  initialState: initialInviteManagementState,
  reducers: {
    request_was_started: (state) => {
      state.request_was_started = true;
      state.request_was_succeed = false;
      state.request_was_failed = false;
      state.request_fail_reason = null;
    },
    request_was_succeed: (state) => {
      state.request_was_succeed = true;
      state.request_was_started = false;
      state.request_was_failed = false;
    },
    request_was_failed: (state, { payload }) => {
      state.request_was_failed = true;
      state.request_was_succeed = false;
      state.request_was_started = false;
      state.request_fail_reason = payload;
    },
    resetErrors: (state) => {
      state.request_was_failed = false;
      state.request_fail_reason = null;
    }
  }
});

export const {
  request_was_started,
  request_was_succeed,
  request_was_failed,
  resetErrors
} = inviteManagementSlice.actions;

export const inviteManagementSelector = (state: IState) => state.inviteManagement;

export const sendInvite = (
  request: InviteSendModel,
  employee: boolean | undefined,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(request_was_started());
  const response = employee
    ? await inviteManagementService.sendEmployeeInvite(request)
    : await inviteManagementService.sendUserInvite(request);
  if (response.ok()) {
    dispatch(request_was_succeed());
    onSuccess && onSuccess();
  } else {
    response.getError && dispatch(request_was_failed(response.getError()));
  }
};

export const resendInvite = (
  email: string,
  onSuccess?: (arg: string) => void
): AppThunk => async (dispatch) => {
  dispatch(request_was_started());
  const response = await inviteManagementService.resendInvite(email);
  if (response.ok()) {
    dispatch(request_was_succeed());
    onSuccess && onSuccess(response.data.link || "");
  } else {
    response.getError && dispatch(request_was_failed(response.getError()));
  }
};

export const cancelInvite = (
  email: string,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(request_was_started());
  const response = await inviteManagementService.cancelInvite(email);
  if (response.ok()) {
    dispatch(request_was_succeed());
    onSuccess && onSuccess();
  } else {
    response.getError && dispatch(request_was_failed(response.getError()));
  }
};

const inviteManagementReducer = inviteManagementSlice.reducer;
export default inviteManagementReducer;

import React, { memo } from "react";
import { useField } from "formik";
import LabeledInput, {
  CommonLabeledInputProps,
  CommonLabeledInputElementProps,
} from "../../molecules/labeled-inputs/labeledInput";
import XGSDate, { XGSDateProps } from "../../xgs-date/xgs-date/xgsDate";

export interface LabeledDateInputProps
extends CommonLabeledInputElementProps,
        CommonLabeledInputProps,
        XGSDateProps {
  className?: string;
  placeholder?: string;
}

const LabeledDateInput: React.FC<LabeledDateInputProps> = memo(({
  label,
  required,
  labelMode,
  requiredAsteriskDisabled,
  error,
  className,
  ...props
}) => {
  const [fields, meta] = useField(props as any);

  const isFailed = (): boolean => {
    return (meta?.touched && meta?.error) || error ? true : false;
  };

  return (
    <LabeledInput
      label={label}
      labelMode={labelMode}
      required={required}
      requiredAsteriskDisabled={requiredAsteriskDisabled}
      isFailed={isFailed}
      error={meta?.error || error}
      className={`xgs-labeled-date-range-input ${className}`}
    >
      <XGSDate
        isFailed={isFailed}
        {...fields}
        {...props}
        className={`${isFailed() ? "not-valid" : ""}`}
      />
    </LabeledInput>
  );
});

export default LabeledDateInput;

import { AssignLaneRequest, LinehaulLaneRequest, LinehaulLanesResponse, LinehaulMileageResponse, LinehaulTripException } from './models';
import api, { ApiResponse } from '../api';
import dateFormats from '../common/dateFormats';
import moment from 'moment';

class LinehaulMileageService {
    private static instance: LinehaulMileageService;
    private source: any;
    private constructor() {}

    public static getInstance(): LinehaulMileageService {
        if (!LinehaulMileageService.instance) {
            LinehaulMileageService.instance = new LinehaulMileageService();
        }
        return LinehaulMileageService.instance;
    }

    public async getLinehaulMileage(fromDate?: string, toDate?: string, originTerminalNumber?: number, destinationTerminalNumber?: number, isReviewed?: boolean | null, isIncludeAllResults?: boolean,pageNumber?: number): Promise<ApiResponse<LinehaulMileageResponse>> {
        return await api.post(`/lmi/trips/list`, {
            noOfEnteriesPerPage: 100,
            pageNumber,
            isIncludeAllResults,
            filters: {
                isReviewed,
                originTerminalNumber,
                destinationTerminalNumber,
                dispatchedDate: {
                    startDate: fromDate ? moment(fromDate).format(dateFormats.isoDate) : null,
                    endDate: toDate ? moment(toDate).format(dateFormats.isoDate) : null
                }
            }
        });
    }

    public async getLanes(origin?: number, desetination?: number, pageNumber?: number): Promise<ApiResponse<LinehaulLanesResponse>> {
        return await api.post(`/lmi/lanes/list`, {
            noOfEnteriesPerPage: 100,
            pageNumber: pageNumber || 1,
            filters: {
                originTerminalNumber: origin,
                destinationTerminalNumber: desetination
            }
        });
    }

    public async createLane(request: LinehaulLaneRequest): Promise<ApiResponse<any>> {
        return await api.post(`/lmi/lanes/lane`, request);
    }

    public async editLane(laneId: string, request: LinehaulLaneRequest): Promise<ApiResponse<any>> {
        return await api.put(`/lmi/lanes/lane/${laneId}`, request)
    }

    public async deleteLane(laneId: string): Promise<ApiResponse<any>> {
        return await api.delete(`/lmi/lanes/lane/${laneId}`)
    }


    public async assignLane(request: AssignLaneRequest): Promise<ApiResponse<any>> {
        return await api.post(`/lmi/lanes/lane/assign`, request)
    }

    public async getTripActivity(tripId: string): Promise<ApiResponse<any>> {
        return await api.get(`/lmi/trips/${tripId}/activity`);
    }

    public async getLaneActivity(laneId: string): Promise<ApiResponse<any>> {
        return await api.get(`/lmi/lanes/lane/${laneId}/activity`);
    }

    public async createTripException(exception: LinehaulTripException): Promise<ApiResponse<any>> {
        if (exception.type === "REIMBURSEMENT") {
            delete exception.duration;
        } else {
            delete exception.amount
        }
        return await api.post(`/lmi/trips/${exception.tripId}/exceptions`, exception);
    }

    public async reviewTrip(tripId: string): Promise<ApiResponse<any>> {
        return await api.post(`/lmi/trips/${tripId}/review`);
    }

    public async unReviewTrip(tripId: string): Promise<ApiResponse<any>> {
        return await api.delete(`/lmi/trips/${tripId}/review`);
    }
    
    public async assignDriver(tripId: string, driverId: string): Promise<ApiResponse<any>> {
        return await api.post(`/lmi/trips/trip/assign/driver`, {
            driverId,
            tripId
        });
    }

    public async deleteTripException(tripId: string, exceptionId: string): Promise<ApiResponse<any>> {
        return await api.delete(`/lmi/trips/${tripId}/exceptions/${exceptionId}`);
    }
}

export default LinehaulMileageService;

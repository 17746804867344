import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import { toast } from "react-toastify";
import mixpanel from "mixpanel-browser";
import useQuery from "../../hooks/useQuery";
import ContentContainer from "../../templates/content-container/contentContainer";
import XGSFormTextarea from "../../ui-components/form/textarea/xgsFormTextarea";
import { XGSSelectOption } from "../../ui-components/xgs-select/xgsSelect";
import LabeledSelectInput from "../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import { LabelModes } from "../../ui-components/molecules/labeled-inputs/labeledInput";
import Button, { ButtonThemes } from "../../ui-components/button/button";
import XGSIcon from "../../ui-components/icon/xgsIcon";
import XGSIcons from "../../ui-components/icon/xgsIcons";
import XGSErrorMessage from "../../ui-components/error-message/errorMessage";
import { FeedbackModel, FeedbackSchema } from "../../app/data/feedback/models";
import FeedbackState from "../../slices/feedback/FeedbackState";
import {
  feedbackSelector,
  getFeedbackSubjects,
  resetFeedbackErrors,
  resetFeedbackState,
  submitFeedback
} from "../../slices/feedback/feedbackSlice";
import "./feedback.scss";

let initialValues: FeedbackModel = {
  subject: "",
  message: ""
};

const FeedbackForm: React.FC<{}> = (props) => {
  const feedbackState: FeedbackState = useSelector(feedbackSelector);
  const dispatch = useDispatch();
  const feedbackFormRef = useRef<any>(null);
  const [subjectFormValue, setSubjectFormValue] = useState<XGSSelectOption | null>();
  const subjectParam = useQuery().get("subject");

  const subjects = useCallback((subjectsArr = feedbackState.subjects) => {
    let options: XGSSelectOption[] = [];
    if (subjectsArr && subjectsArr.length > 0) {
      for (let i = 0; i < subjectsArr.length; i++) {        
        options.push({
          label: subjectsArr[i].label,
          value: subjectsArr[i].type
        });
      }
    }
    return options;
  }, [feedbackState.subjects]);

  const onSubmitFeedback = (data: FeedbackModel) => {
    dispatch(submitFeedback(data, () => {
      mixpanel.track("Feedback was submitted");
    }));
  };

  const resetFeedback = () => {
    dispatch(resetFeedbackState());
    dispatch(getFeedbackSubjects());
    setSubjectFormValue(null);
  };

  useEffect(() => {
    if (feedbackState.subjects && feedbackState.subjects.length > 0) return;
    dispatch(getFeedbackSubjects((subjectsArr) => {
      if (!subjectParam || !subjectsArr) return;
      const subjectObj = subjects(subjectsArr).find((subject: any) => subject.value === subjectParam.toUpperCase());
      if (!subjectObj) return;
      feedbackFormRef.current?.setFieldValue("subject", subjectObj.value);
      setSubjectFormValue(subjectObj);
    }));
  }, [feedbackState.subjects, subjectParam, subjects, dispatch]);

  useEffect(() => {
    if (!feedbackState.requestFailed) return;
    toast.error(feedbackState.requestError || "Error", { autoClose: 4000 });
    dispatch(resetFeedbackErrors());
  }, [feedbackState.requestFailed, feedbackState.requestError, dispatch]);

  return (
    <ContentContainer title="Feedback Center">
      <div className="xgs-feedback">
        {!(feedbackState.requestSucceed && feedbackState.requestCreator === "SUBMIT") && (
          <>
            <div className="xgs-feedback__description">To provide feedback or request a new feature, please use the form below.</div>
            <div className="xgs-feedback__form">
              <Formik
                onSubmit={onSubmitFeedback}
                initialValues={initialValues}
                validationSchema={FeedbackSchema}
                innerRef={feedbackFormRef}
                enableReinitialize
              >
                {(props: FormikProps<FeedbackModel>) => (
                  <Form>
                    <LabeledSelectInput
                      name="subject"
                      label="Subject:"
                      placeholder="Select..."
                      isSearchable={false}
                      labelMode={LabelModes.column}
                      value={subjectFormValue}
                      onValueChange={(v) => {
                        feedbackFormRef.current?.setFieldValue("subject", v?.value);
                        setSubjectFormValue(v);
                      }}
                      options={subjects()}
                      required={true}
                      formik={true}
                      className="xgs-feedback__form__dropdown"
                      isLoading={feedbackState.requestStarted && feedbackState.requestCreator === "GET_SUBJECTS"}
                    />
                    <XGSFormTextarea
                      name="message"
                      label="Message:"
                      required={true}
                      counter={500}
                      className="xgs-feedback__form__textarea"
                    />
                    <div className="xgs-feedback__notes">
                      By submitting this form, you agree that Xpress Global Systems, LLC. may use your feedback, suggestions, or ideas in any way, 
                      including in future modifications of the XGS Customer Portal, other products or services, advertising, or 
                      marketing materials in perpetuity and without royalties.
                    </div>
                    {feedbackState.requestFailed && (
                      <XGSErrorMessage>
                        {feedbackState.requestError || "Error! Try again later or contact XGS support."}
                      </XGSErrorMessage>
                    )}
                    <Button
                      theme={ButtonThemes.blue}
                      type="submit"
                      className="xgs-feedback__submit-button"
                      spinner={feedbackState.requestStarted}
                      disabled={!props.isValid || !props.values.subject}
                    >
                      Submit
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </>
        )}
        {feedbackState.requestSucceed && feedbackState.requestCreator === "SUBMIT" && (
          <div className="xgs-feedback__success">
            <div className="xgs-feedback__success__message">
              <div className="xgs-feedback__success__message__icon">
                <XGSIcon
                  icon={XGSIcons.faCheck}
                  size="2x"
                />
              </div>
              <div className="xgs-feedback__success__message__text">                
                Thank you for your feedback.<br />
                A member of our team may contact you with follow-up questions or to obtain additional information on your submission.
              </div>
            </div>
            <Button
              theme={ButtonThemes.blue}
              type="button"
              className="xgs-feedback__submit-another-button"
              onClick={() => { resetFeedback(); }}
            >
              Submit another feedback
            </Button>
          </div>
        )}
      </div>
    </ContentContainer>
  );
};

export default FeedbackForm;

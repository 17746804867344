import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { groupBy } from "lodash";
import { AppThunk } from "../../app/store";
import PickupService, { REQUEST_ID } from "../../app/data/pickup/pickupService";
import { PickupStatus } from "../../app/data/common/route";
import {
  PickupAssignRequestModel,
  PickupRescheduleModel,
  PickupsGroupModel,
  PickupsLogFilterModel,
  PickupsResponseContentModel,
  PickupUpdateResponseModel
} from "../../app/data/pickup/models";
import { initialPickupAssignmentState } from "./PickupAssignmentState";
import { convertToSubPickup, getPickupById, isGroup, isSubPickup } from "../../services/pickups";

const pickupService = PickupService.getInstance();

const createPickupGroupItem = (item: PickupsResponseContentModel) => {
  return {
    id: item.pickup.groupId,
    shipper: {
      address: item.shipper.address,
    },
    pickup: {
      date: item.pickup.date,
      driverId: item.pickup.driverId,
    }
  }
}

const groupById = (data: PickupsResponseContentModel[]) => {
  const groupedItems = groupBy(data.filter(item => item.pickup.groupId), item => item.pickup.groupId);
  const usedGroups = new Set();
  const groupedData = data.reduce((result: any[], item: any) => {
    if (!item.pickup.groupId || groupedItems[item.pickup.groupId].length <= 1) return [...result, { ...item, pickup: { ...item.pickup, single: true } }];
    if (usedGroups.has(item.pickup.groupId)) return result;
    usedGroups.add(item.pickup.groupId);
    return [
      ...result,
      {
        ...createPickupGroupItem(item),
        pickups: groupedItems[item.pickup.groupId].map(convertToSubPickup)
      }]
  }, []);
  return groupedData;
};

const updatePickupFields = (pickup: PickupsResponseContentModel, newPickup: PickupUpdateResponseModel, username: string): PickupsResponseContentModel => (
  {
    ...pickup,
    status: newPickup.pickupStatus,
    pickup: {
      ...pickup.pickup,
      driverId: newPickup.driverId,
      groupId: newPickup.groupId,
      pickupStatus: newPickup.pickupStatus,
    },
    pickupFullData: {
      ...pickup.pickupFullData,
      pickupRequest: {
        ...pickup.pickupFullData.pickupRequest,
        pickupStatus: newPickup.pickupStatus,
        driverId: newPickup.driverId,
        manifestNumber: newPickup.manifestNumber,
        dateUpdated: moment().format("MM/DD/YYYY hh:mm:ss"),
        userUpdated: username,
      },
    }
  }
);

export const pickupAssignmentSlice = createSlice({
  name: "pickupAssignment",
  initialState: initialPickupAssignmentState,
  reducers: {
    resetPickupAssignmentState: (state) => initialPickupAssignmentState,
    requestStarted: (state, { payload }) => {
      if (payload === "GET_PICKUPS_PORTION" || payload === "GET_DRIVERS_PORTION" || payload === "GET_PICKUPS_LOGS_PORTION") {
        state.fetchPortionStarted = true;
      } else {
        state.requestStarted = true;
      }
      state.requestSucceed = false;
      state.requestFailed = false;
      state.requestCreator = payload;
      state.requestError = "";
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.fetchPortionStarted = false;
      state.requestSucceed = true;
      state.requestFailed = false;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestSucceed = false;
      state.requestFailed = true;
      state.requestFailStatus = payload.status;
      state.requestError = payload.error;
    },
    setPickups: (state, { payload }) => {
      state.pickups = groupById(payload.content);
      state.pickupsRequest = payload.scroll;
      state.pickupsFetchedAll = payload.scroll.idsMap?.hasNext !== "true";
    },
    addPickups: (state, { payload }) => {
      state.pickups = [...state.pickups, ...groupById(payload.content)];
      state.pickupsRequest = payload.scroll;
      state.pickupsFetchedAll = payload.scroll.idsMap?.hasNext !== "true";
    },
    updatePickup: (state, { payload }) => {
      // update locally to show changes immediately
      const pickup = getPickupById(state.pickups, payload.pickupId);

      if (!isSubPickup(pickup)) {
        let index = state.pickups.findIndex(obj => !isGroup(obj) && obj.pickup.pickupId === payload.pickupId);
        const groupId = payload.groupId || pickup.pickup.groupId;

        state.pickups[index] = updatePickupFields(pickup, {
          ...payload,
          groupId,
          pickupStatus: payload.status,
        }, payload.username);
      } else {
        const groupIndex = state.pickups.findIndex(obj => isGroup(obj) && obj.id === pickup.pickup.groupId);
        const pickups = [...(state.pickups[groupIndex] as PickupsGroupModel).pickups];
        const index = pickups.findIndex(obj => obj.pickup.pickupId === payload.pickupId);
        const groupId = payload.groupId || pickups[index].pickup.groupId;

        pickups[index] = updatePickupFields(
          pickup,
          {
            ...payload,
            groupId,
            pickupStatus: payload.status,
          },
          payload.username,
        );

        state.pickups.splice(groupIndex, 1, ...groupById(pickups));
        state.createdGroupId = "";
      }
    },
    updatePickupsGroups: (state, { payload }) => {
      const index = payload.groupId
      ? state.pickups.findIndex(obj => isGroup(obj) && obj.id === payload.groupId)
      : state.pickups.findIndex(obj => !isGroup(obj) && obj.pickup.pickupId === payload.data[0].id);

      const pickups: PickupsResponseContentModel[] = payload.groupId
      ? (state.pickups[index] as PickupsGroupModel).pickups
      : [state.pickups[index]];

      const updatedPickups = pickups.map((pickup) => {
        const newPickup = payload.data.find((item: PickupUpdateResponseModel) => item.id === pickup.pickup.pickupId);
        return newPickup ? updatePickupFields(pickup, newPickup, payload.username) : pickup;
      });

      const newGroupId = payload.data.find((item: PickupUpdateResponseModel) => item.groupId !== payload.groupId).groupId || "";
      const newGroupIndex = state.pickups.findIndex(obj => (isGroup(obj) && obj.id === newGroupId) || (!isGroup(obj) && obj.pickup.groupId === newGroupId));

      if (newGroupIndex < 0 || newGroupIndex === index) {
        // single pickup or the whole group was changed and keeps its position in table
        state.pickups.splice(index, 1, ...groupById(updatedPickups));
      } else {
        // single pickup or part of the group was changed and should be attached to existing group

        // remove changed pickups from their group or remove outdated single pickup from its position
        state.pickups.splice(index, 1, ...groupById(updatedPickups.filter(pickup => pickup.pickup.groupId === payload.groupId)));

        // index can change after removing items
        const updateIndex = state.pickups.findIndex(obj => (isGroup(obj) && obj.id === newGroupId) || (!isGroup(obj) && obj.pickup.groupId === newGroupId));

        if (isGroup(state.pickups[updateIndex])) {
          // add changed pickups into existing group
          (state.pickups[updateIndex] as PickupsGroupModel).pickups = [
          ...(state.pickups[updateIndex] as PickupsGroupModel).pickups,
          ...updatedPickups.filter((pickup) => pickup.pickup.groupId === newGroupId).map(convertToSubPickup),
        ]
        } else {
          // combine changed pickups with existing single pickup into a new group
          state.pickups.splice(updateIndex, 1, ...groupById([
            state.pickups[updateIndex],
            ...updatedPickups.filter(pickup => pickup.pickup.groupId === newGroupId),
          ]));
        }
      }

      state.createdGroupId = newGroupId;
    },
    setDrivers: (state, { payload }) => {
      if (!payload) return;
      state.drivers = payload;
    },
    setDriver: (state, { payload }) => {
      if (!payload) return;
      state.drivers = [{
        ...payload,
        driverId: payload.driverId
      }];
    },
    clearDrivers: (state) => {
      state.drivers = [];
    },
    setPickupLog: (state, { payload }) => {
      state.pickupLog = payload;
    },
    addPickupLog: (state, { payload }) => {
      state.pickupLog = [...state.pickupLog, ...payload];
    },
    clearPickupLog: (state) => {
      state.pickupLog = [];
    },
    setPickupsLogRequest: (state, { payload }) => {
      state.pickupsLogRequest = {
        page: payload.page,
        last: payload.last
      }
    }
  }
});

export const {
  resetPickupAssignmentState,
  requestStarted,
  requestSucceed,
  requestFailed,
  setPickups,
  addPickups,
  updatePickup,
  updatePickupsGroups,
  setDrivers,
  setDriver,
  clearDrivers,
  setPickupLog,
  addPickupLog,
  clearPickupLog,
  setPickupsLogRequest
} = pickupAssignmentSlice.actions;

export const pickupAssignmentSelector = (state: IState) => {
  return state.pickupAssignment;
};

export const getPickups = (
  terminal: string,
  statuses: PickupStatus[],
  expiredOnly: boolean,
  pickupNumber: string,
  lastIds?: string
): AppThunk => async (dispatch) => {
  dispatch(requestStarted(lastIds ? "GET_PICKUPS_PORTION" : "GET_PICKUPS"));
  const response = await pickupService.getPickups(terminal, statuses, expiredOnly, pickupNumber, lastIds && lastIds);
  if (response.ok()) {
    dispatch(lastIds ? addPickups(response.data) : setPickups(response.data));
    dispatch(requestSucceed());
  } else {
    dispatch(requestFailed({
      status: response.status,
      error: response.getError ? response.getError() : "Error"
    }));
  }
};

export const getDrivers = (
  zip: string,
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("GET_DRIVERS"));
  pickupService.cancelRequest(REQUEST_ID.GET_DRIVER);
  const response = await pickupService.getDrivers(zip);

  if (response.isCancel?.()) return;

  if (response.ok()) {
    dispatch(setDrivers(response.data));
    dispatch(requestSucceed());
  } else {
    dispatch(requestFailed({
      status: response.status,
      error: response.getError ? response.getError() : "Error"
    }));
  }
};

export const getDriverById = (
  id: number,
  onSuccess: (name: string) => void,
  onlyCallback?: boolean
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("GET_DRIVER"));
  pickupService.cancelRequest(REQUEST_ID.GET_DRIVERS);
  const response = await pickupService.getDriver(id);

  if (response.isCancel?.()) return;

  if (response.ok()) {
    !onlyCallback && dispatch(setDriver(response.data ? response.data : null));
    dispatch(requestSucceed());
    onSuccess(response.data ? response.data?.driverName : (onlyCallback ? "" : "N/A"));
  } else {
    dispatch(requestFailed({
      status: response.status,
      error: response.getError ? response.getError() : "Error"
    }));
  }
};

export const resetDrivers = ():AppThunk => async (dispatch)  => {
  pickupService.cancelRequest(REQUEST_ID.GET_DRIVER);
  pickupService.cancelRequest(REQUEST_ID.GET_DRIVERS);
  dispatch(requestSucceed());
  dispatch(clearDrivers());
}

export const changePickups = (
  request: PickupAssignRequestModel[],
  username: string,
  groupId: string,
  onSuccess: () => void,
  onFail: (error: string) => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("CHANGE_PICKUP_ASSIGNMENT"));
  const response = await pickupService.changePickups(request);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(updatePickupsGroups({ data: response.data, groupId, username }));
    onSuccess();
  } else {
    onFail(response.getError ? `${response.getError()}` : "Error");
    dispatch(requestFailed({
      status: response.status,
      error: response.getError ? response.getError() : "Error"
    }));
  }
};

export const getPickupLog = (
  pickupId: string
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("GET_PICKUP_LOG"));
  const response = await pickupService.getPickupLog(pickupId);
  if (response.ok()) {
    dispatch(setPickupLog(response.data));
    dispatch(requestSucceed());
  } else {
    dispatch(requestFailed({
      status: response.status,
      error: response.getError ? response.getError() : "Error"
    }));
  }
};

export const getPickupsLog = (
  filter: PickupsLogFilterModel,
  page?: number
): AppThunk => async (dispatch) => {
  dispatch(requestStarted(page ? "GET_PICKUPS_LOGS_PORTION" : "GET_PICKUPS_LOG"));
  const response = await pickupService.getPickupsLog(filter, page && page);
  if (response.ok()) {
    dispatch(page ? addPickupLog(response.data?.content) : setPickupLog(response.data?.content));
    dispatch(setPickupsLogRequest({
      page: response.data?.pageable.pageNumber,
      last: response.data?.last
    }));
    dispatch(requestSucceed());
  } else {
    dispatch(requestFailed({
      status: response.status,
      error: response.getError ? response.getError() : "Error"
    }));
  }
};

export const cancelPickup = (
  pickupId: string | null,
  notes: string,
  onSuccess: () => void,
  onFail: (error: string) => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("CANCEL_PICKUP"));
  const response = await pickupService.cancelPickup({
    pickupId,
    notes
  });
  if (response.ok()) {
    dispatch(requestSucceed());
    onSuccess();
  } else {
    onFail(response.getError ? `${response.getError()}` : "Error");
    dispatch(requestFailed({
      status: response.status,
      error: response.getError ? response.getError() : "Error"
    }));
  }
};

export const reschedulePickup = (
  pickupId: string,
  request: PickupRescheduleModel,
  onSuccess: () => void,
  onFail: (error: string) => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("RESCHEDULE_PICKUP"));
  const response = await pickupService.reschedulePickup(pickupId, request);

  if (response.ok()) {
    dispatch(requestSucceed());
    onSuccess(response.data.newPickupId, response.data.newPickupNumber);
  } else {
    onFail(response.getError ? `${response.getError()}` : "Error");
    dispatch(requestFailed({
      status: response.status,
      error: response.getError ? response.getError() : "Error"
    }));
  }
};

export const completePickup = (
  pickupId: string,
  onSuccess: () => void,
  onFail: (error: string) => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("COMPLETE_PICKUP"));
  const response = await pickupService.completePickup(pickupId);
  if (response.ok()) {
    dispatch(requestSucceed());
    onSuccess();
  } else {
    onFail(response.getError ? `${response.getError()}` : "Error");
    dispatch(requestFailed({
      status: response.status,
      error: response.getError ? response.getError() : "Error"
    }));
  }
};

const pickupAssignmentReducer = pickupAssignmentSlice.reducer;
export default pickupAssignmentReducer;

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Table from "../../../ui-components/table/table";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import {
  getPickups,
  pickupAssignmentSelector
} from "../../../slices/pickup/pickupAssignmentSlice";
import { PickupsGroupModel, PickupsResponseContentModel } from "../../../app/data/pickup/models";
import Tag from "../../../ui-components/molecules/tag/tag";
import { TagColor } from "../../../app/data/common/tagColor";
import { getPickupGroupStatus, isGroup, isSubPickup, getStatusColor } from "../../../services/pickups";
import { PickupStatus } from "../../../app/data/common/route";

export interface PickupListProps {
  onPickupSelect: (id: string[]) => void;
  onGroupSelect: (id: string) => void;
  onShowPickupDetails: (id: string) => void;
  selectedPickupIds: string[];
  selectedPickupGroupId: string;
  expiredOnly: boolean;
  showCompleted: boolean;
  statuses: PickupStatus[];
  terminal: number | null;
  pickupNumber: string;
};

const PickupList: React.FC<PickupListProps> = (props) => {
  const dispatch = useDispatch();
  const pickupAssignmentState = useSelector(pickupAssignmentSelector);

  const isExpired = (date: string, closeTime: string, ) => {
    if (!closeTime) return true;
    if (moment(date).startOf("day").isSame(moment().startOf("day"))) {
      return moment().isAfter(moment(closeTime, "hh:mm:ss"));
    } else {
      return moment().isAfter(moment(date), "day");
    }
  };
  
  const columns = [
    {
      width: 52,
      minWidth: 52,
      Header: "",
      id: "selectedRow",
      Cell: ({ row }: any) => (
        row.depth === 0 && (
            <div className="text-center">
            <input
              type="radio"
              name="selectedOrderRow"
              value="selected"
              checked={isGroup(row.original)
              ? row.original.id === props.selectedPickupGroupId
              : !!props.selectedPickupIds.find(id => row.original.pickup.pickupId === id)
            }
              onChange={(e) => { }}
            />
          </div>
      ))
    },
    {
      width: 52,
      minWidth: 52,
      Header: "",
      id: "selectedSubRow",
      Cell: ({ row }: any) => {
        if (row.depth === 0) return null;
        const group = pickupAssignmentState.pickups.find(pickup => isGroup(pickup) && pickup.id === row.original.pickup.groupId);
        const status = getPickupGroupStatus((group as PickupsGroupModel)?.pickups);
        return (status === PickupStatus.ASSIGNED || status === PickupStatus.UNASSIGNED || status === PickupStatus.MISSED) && row.original.status !== PickupStatus.CANCELED
        && (
          <div className="text-center">
            <input
              type="checkbox"
              name="selectedOrderSubRow"
              value="selected"
              checked={!!props.selectedPickupIds.find(id => row.original.pickup?.pickupId === id)}
              onChange={(e) => { }}
            />
          </div>
        )}
    },
    {
      width: 110,
      minWidth: 100,
      Header: "Pickup #",
      accessor: "pickup.pickupNumber",
      Cell: ({ row }: any) => (
        row.original.pickups ? (
          <span className="xgs-table__group-label">
            Group
            <Tag mods={{ color: TagColor.PURPLE }} mix="xgs-table__group-label__tag">

              {row.original.pickups.length}</Tag>
          </span>
        ) : (<span
          className="xgs-table-link-cell"
          onClick={(e) => {
            e.stopPropagation();
            props.onShowPickupDetails(row.original.pickup?.pickupId);
          }}
        >
          {row.original.pickup?.pickupNumber}
        </span>)
      )
    },
    {
      width: 140,
      minWidth: 140,
      Header: "Time of Availability",
      accessor: "pickup.date",
      Cell: ({ row }: any) => {
        const date = row.original.pickup?.date;

        const readyTime = row.original.pickups
        ? moment.min(row.original.pickups
          .filter((pickup: PickupsResponseContentModel) => pickup.status !== PickupStatus.CANCELED)
          .map((pickup: PickupsResponseContentModel) => moment(pickup.pickup.readyTime, "hh:mm:ss"))
        )
        : row.original.pickup?.readyTime;

        const closeTime = row.original.pickups
        ? moment.max(row.original.pickups
          .filter((pickup: PickupsResponseContentModel) => pickup.status !== PickupStatus.CANCELED)
          .map((pickup: PickupsResponseContentModel) => moment(pickup.pickup.closeTime, "hh:mm:ss"))
        )
        : row.original.pickup?.closeTime;

        return (
        <div className={isExpired(date, closeTime) && (row.original.status !== PickupStatus.COMPLETED) ? "xgs-pickup-assignment__orders__time-expired" : ""}>
          <div>{moment(readyTime, "hh:mm:ss").format("h:mm A")} - {moment(closeTime, "hh:mm:ss").format("h:mm A")}</div>
          <div>{date ? date.toUiDateFormat() : ""}</div>
        </div>
      )}
    },
    {
      width: 150,
      minWidth: 140,
      Header: "Customer",
      accessor: "shipper.name"
    },
    {
      width: 210,
      minWidth: 210,
      Header: "Pickup Address",
      accessor: "shipper.address.address1",
      Cell: ({ row }: any) => (
        <span>
          {row.original.shipper?.address.address1 ? `${row.original.shipper?.address.address1}, ` : ""}{row.original.shipper?.address.city ? `${row.original.shipper?.address.city}, ` : ""}{row.original.shipper?.address.state ? `${row.original.shipper?.address.state}, ` : ""}{row.original.shipper?.address.postalCode}
        </span>
      )
    },
    {
      width: 76,
      minWidth: 70,
      Header: (
        <div className="text-center">
          Items
        </div>
      ),
      accessor: "items.count",
      Cell: (cellProps: any) => {
        const value = cellProps.row.original.pickups
        ? cellProps.row.original.pickups.filter((pickup: PickupsResponseContentModel) => pickup.status !== PickupStatus.CANCELED)
        .reduce((result: number, pickup: PickupsResponseContentModel) => (result + pickup.items.count), 0)
        : cellProps.value;

        return (
        <div className="text-center">
          { value }
        </div>
      )
    }},
    {
      width: 76,
      minWidth: 70,
      Header: (
        <div className="text-center">
          Weight
        </div>
      ),
      accessor: "items.weight",
      Cell: (cellProps: any) => {
        const value = cellProps.row.original.pickups
        ? cellProps.row.original.pickups.filter((pickup: PickupsResponseContentModel) => pickup.status !== PickupStatus.CANCELED)
        .reduce((result: number, pickup: PickupsResponseContentModel) => (result + pickup.items.weight), 0)
        : cellProps.value;

        return (
        <div className="text-center">
          { value }
        </div>
      )}
    },
    {
      width: 120,
      minWidth: 120,
      Header: "Status",
      accessor: "status",
      Cell: (cellProps: any) => (!cellProps.row.original.pickups && (
        <Tag mods={{color: getStatusColor(cellProps.value), uppercase: true}}>
          { cellProps.value }
        </Tag>
      ))
    },
  ];

  const onGroupClick = (row: PickupsGroupModel) => {
    props.onGroupSelect(row.id);
    props.onPickupSelect(row.pickups.filter(pickup => pickup.status !== PickupStatus.CANCELED).map((pickup) => pickup.pickup.pickupId));
  }

  const onSubPickupClick = (row: PickupsResponseContentModel) => {
    const group = pickupAssignmentState.pickups.find(pickup => (isGroup(pickup) && pickup.id === row.pickup.groupId));
    const groupStatus = getPickupGroupStatus((group as PickupsGroupModel).pickups);

    if (groupStatus === PickupStatus.COMPLETED) {
      onGroupClick(group as PickupsGroupModel);
      return;
    };

    if (row.status === PickupStatus.CANCELED) {
      props.onGroupSelect(row.pickup.groupId);
      return;
    }

    if (row.pickup.groupId && props.selectedPickupGroupId !== row.pickup.groupId) {
      props.onGroupSelect(row.pickup.groupId);
      props.onPickupSelect([row.pickup.pickupId]);
    } else {
      if (props.selectedPickupIds.find(id => id === row.pickup.pickupId)) {
        props.onPickupSelect(props.selectedPickupIds.filter(id => row.pickup.pickupId !== id));
      } else {
        const ids = [...props.selectedPickupIds, row.pickup.pickupId]
        props.onPickupSelect(ids);
      }
    }
  };

  const onSinglePickupClick = (row: PickupsResponseContentModel) => {
    props.onPickupSelect([row?.pickup.pickupId]);
    props.onGroupSelect("");
  };

  const onRowClick = (row: PickupsResponseContentModel | PickupsGroupModel) => {
    if (!row) return;

    if (isGroup(row)) {
      onGroupClick(row);
    } else {
      if (isSubPickup(row)) {
        onSubPickupClick(row);
      } else {
        onSinglePickupClick(row);
      }
    }
  }

  const selectedRowIndex = props.selectedPickupGroupId
    ? pickupAssignmentState.pickups.findIndex(pickup => isGroup(pickup) && pickup.id === props.selectedPickupGroupId)
    : pickupAssignmentState.pickups.findIndex(pickup => !isGroup(pickup) && pickup.pickup.pickupId === props.selectedPickupIds[0]);

  const getSelectedSubRowsIndexes = (selectedRowIndex: number) => {
    const group = pickupAssignmentState.pickups[selectedRowIndex];

    return (group as PickupsGroupModel)?.pickups?.reduce(
      (result: number[], pickup, index) => (props.selectedPickupIds.find(id => id === pickup.pickup.pickupId) ? [...result, index] : result),
      []
    )
  };

  const selectedSubRowsIndexes = props.selectedPickupGroupId
    ? getSelectedSubRowsIndexes(selectedRowIndex) : []

  const onListInfiniteScroll = () => {
    if (pickupAssignmentState.pickupsFetchedAll) return;
    dispatch(getPickups(props.terminal || null, props.statuses, props.expiredOnly, props.pickupNumber, pickupAssignmentState.pickupsRequest?.lastIds));
  };

  useEffect(() => {
    dispatch(getPickups(props.terminal, props.statuses, props.expiredOnly, props.pickupNumber));
  }, [props.terminal, props.statuses, props.expiredOnly, props.pickupNumber, dispatch]);

  return (
    <div className="xgs-pickup-assignment__orders">
      {(pickupAssignmentState.requestFailed && pickupAssignmentState.requestFailStatus && pickupAssignmentState.requestCreator === "GET_PICKUPS") && (
        <XGSErrorMessage>{pickupAssignmentState.requestError}</XGSErrorMessage>
      )}
      {!(pickupAssignmentState.requestFailed && pickupAssignmentState.requestFailStatus && pickupAssignmentState.requestCreator === "GET_PICKUPS") && (
        <Table
          isLoading={pickupAssignmentState.requestStarted && pickupAssignmentState.requestCreator === "GET_PICKUPS"}
          keepTableOnLoading
          columns={columns}
          data={pickupAssignmentState.pickups}
          cursorPointer
          onRowClicked={onRowClick}
          rowHeight={56}
          minTableHeight={240}
          noResultsText="There are no pickups"
          infiniteScroll
          infiniteScrollLoading={pickupAssignmentState.fetchPortionStarted && pickupAssignmentState.requestCreator === "GET_PICKUPS_PORTION"}
          infiniteScrollHasNext={!pickupAssignmentState.pickupsFetchedAll}
          onInfiniteScroll={onListInfiniteScroll}
          highlightRow={selectedRowIndex}
          highlightSubRows={selectedSubRowsIndexes}
          responsive
          subRowsField="pickups"
        />
      )}
    </div>
  );
};

export default PickupList;

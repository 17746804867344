import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../../app/route/RoutesConfig";
import { pickupSelector, resetPickupState } from "../../../../slices/pickup/pickupSlice";
import { StepProps } from "../../../../app/data/common/models";
import { formatAddress } from "../../../../services/common/address";
import Button, { ButtonSizes, ButtonThemes } from "../../../../ui-components/button/button";
import XGSIcon from "../../../../ui-components/icon/xgsIcon";
import XGSRegularIcons from "../../../../ui-components/icon/xgsRegularIcons";
import { copyTextToClipboard } from "../../../../hooks/utils";

import "../../../../sass/blocks.scss";

const PickupSuccessStep: React.FC<StepProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const pickupState = useSelector(pickupSelector);

  const onComplete = () => {    
    dispatch(resetPickupState());
    history.push(Routes.pickup.management);
  };

  return (
    <div className="xgs-pickup__success-step">
      <div className="xgs-pickup__content-block">
        <div className="xgs-pickup__details-item">
          <div className="xgs-pickup__step-header">Success!</div>
          <div>New pickup has been created</div>
        </div>
        <div className="xgs-pickup__details-item xgs-pickup__details-item--flex">
          <span className="xgs-pickup__pickup-number">{pickupState.pickupNumber}</span>
          <Button theme={ButtonThemes.purple} size={ButtonSizes.auto}>
            <XGSIcon
              icon={XGSRegularIcons.faCopy}
              title="Copy to clipboard"
              onClick={() => { copyTextToClipboard(pickupState.pickupNumber); }}
            />
          </Button>
        </div>
        <div className="xgs-pickup__details-item">
          <div className="xgs-pickup__details-item-label">Time of Availability:</div>
          <div>{`${pickupState.pickupReadyTime} - ${pickupState.pickupCloseTime} ${pickupState.pickupDate}`}</div>
        </div>
        <div className="xgs-pickup__details-item">
          <div className="xgs-pickup__details-item-label">Pickup Address</div>
          <div>{formatAddress(pickupState.shipper.address)}</div>
        </div>
        <div className="xgs-pickup__details-item">
          <div className="xgs-pickup__details-item-label">Customer</div>
          <div>{pickupState.shipper.name}</div>
        </div>
      </div>
      <div className="xgs-pickup__buttons">
        <Button
          type="button"
          theme={ButtonThemes.blue}
          className="xgs-bol__nav-button"
          onClick={onComplete}
        >
          Go to Assign
        </Button>
      </div>
    </div>
  );
};

export default PickupSuccessStep;

import React, { ReactElement, useState } from 'react'
import XGSIcon from '../../ui-components/icon/xgsIcon'
import XGSIcons from '../../ui-components/icon/xgsIcons'
import { cn } from '../../services/common/className';

import "./collapsible.scss";

export interface CollapsibleProps {
  header: ReactElement;
  mix?: string | string[];
}

const Collapsible: React.FC<CollapsibleProps> = ({ header, children, mix }) => {
  const [open, setOpen] = useState(false)  

  return (
    <li className={cn("collapsible")({ open }, mix)} >
      <div className="collapsible__header">
        <div className="collapsible__header-content">
          {header}
        </div>

        <span
          className="collapsible__button"
          onClick={() => { setOpen(state => !state) }}
        >
          <XGSIcon icon={XGSIcons.faChevronDown} size={'lg'} className="collapsible__button-icon" />
        </span>
      </div>

      <div className="collapsible__content">
        {children}
      </div>
    </li >
  )
}

export default Collapsible
import { IState } from "../..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../../app/store";
import CustomersService from "../../../app/data/customers/customersService";
import { initialTeamShippersState } from "./TeamShippersState";

const customersService = CustomersService.getInstance();

export const teamShippersSlice = createSlice({
  name: "teamShippers",
  initialState: initialTeamShippersState,
  reducers: {
    shippers_fetch_was_started: (state, { payload }) => {
      if (payload) {
        state.shippers_fetchPortion_was_started = true;
      } else {
        state.shippers_fetch_was_started = true;
      }
      state.shippers_fetch_was_succeed = false;
      state.shippers_fetch_was_failed = false;
      state.shippers_fetch_fail_reason = null;
      state.shippersFetchedAll = false;
    },
    shippers_fetch_was_succeed: (state) => {
      state.shippers_fetch_was_succeed = true;
      state.shippers_fetchPortion_was_started = false;
      state.shippers_fetch_was_failed = false;
      state.shippers_fetch_was_started = false;
    },
    set_shippers: (state, { payload }) => {
      state.shippers = payload.content;
      state.shippersRequest = (payload.content.length < 50) ? null : payload.scroll;
      state.shippersFetchedAll = !!(payload.content.length < 50);
    },
    add_shippers: (state, { payload }) => {
      state.shippers = [...state.shippers, ...payload.content];
      state.shippersRequest = (payload.content.length < 50) ? null : payload.scroll;
      state.shippersFetchedAll = !!(payload.content.length < 50);
    },
    shippers_fetch_was_failed: (state, { payload }) => {
      state.shippers_fetch_was_failed = true;
      state.shippers_fetch_was_succeed = false;
      state.shippers_fetch_fail_reason = payload;
      state.shippers_fetch_was_started = false;
    },
    remove_shipper_locally: (state, { payload }) => {
      const i = state.shippers.findIndex((shipper: any) => shipper.id === payload);
      if (i === -1) return;
      state.shippers.splice(i, 1);
    },
    request_was_started: (state) => {
      state.request_was_started = true;
      state.request_was_succeed = false;
      state.request_was_failed = false;
      state.request_fail_reason = null;
    },
    request_was_succeed: (state) => {
      state.request_was_succeed = true;
      state.request_was_started = false;
      state.request_was_failed = false;
    },
    request_was_failed: (state, { payload }) => {
      state.request_was_failed = true;
      state.request_was_succeed = false;
      state.request_was_started = false;
      state.request_fail_reason = payload;
    },
    add_was_failed: (state, { payload }) => {
      state.request_was_failed = true;
      state.request_was_succeed = false;
      state.request_was_started = false;
      state.request_fail_reason = payload.text;
      state.request_fail_code = payload.code;
    },    
    resetErrors: (state) => {
      state.request_was_failed = false;
      state.request_fail_reason = null;
      state.request_fail_code = null;
    }
  }
});

export const {
  shippers_fetch_was_started,
  shippers_fetch_was_succeed,
  set_shippers,
  add_shippers,
  shippers_fetch_was_failed,
  remove_shipper_locally,
  request_was_started,
  request_was_succeed,
  request_was_failed,
  add_was_failed,
  resetErrors
} = teamShippersSlice.actions;

export const teamShippersSelector = (state: IState) => state.teamShippers;

export const getShippers = (
  teamId: string,
  lastIds?: string
): AppThunk => async (dispatch) => {
  dispatch(shippers_fetch_was_started(!!lastIds));
  const response = await customersService.getExtraShippers(teamId, lastIds && lastIds);
  if (response.ok()) {
    dispatch(shippers_fetch_was_succeed());
    dispatch(lastIds ? add_shippers(response.data) : set_shippers(response.data));
  } else {
    response.getError && dispatch(shippers_fetch_was_failed(response.getError()));
  }
};

export const addShippers = (
  teamId: string,
  accounts: number[],
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(request_was_started());
  const response = await customersService.addShippers(teamId, accounts);
  if (response.ok()) {
    dispatch(request_was_succeed());
    onSuccess && onSuccess();
  } else {
    response.getError && dispatch(add_was_failed({
      text: response.getError(),
      code: response.status
    }));
  }
};

export const removeShipperFromTeam = (
  teamId: string,
  accountId: string,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(request_was_started());
  const response = await customersService.removeShipperFromTeam(teamId, accountId);
  if (response.ok()) {
    dispatch(request_was_succeed());
    dispatch(remove_shipper_locally(accountId));
    onSuccess && onSuccess();
  } else {
    response.getError && dispatch(request_was_failed(response.getError()));
  }
};

const teamShippersReducer = teamShippersSlice.reducer;
export default teamShippersReducer;

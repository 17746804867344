const buildURI = (csv: string) => {
  const type = 'text/csv';
  const blob = new Blob(['', csv], { type });
  const dataURI = `data:${type};charset=utf-8,${csv}`;

  const URL = window.URL;

  return (typeof URL.createObjectURL === 'undefined')
    ? dataURI
    : URL.createObjectURL(blob);
};

export const downloadCSV = async (data: string, filename: string) => {
  const link = document.createElement("a");
  link.href = buildURI(data);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import { toast } from "react-toastify";

import XGSFormTextarea from "../../ui-components/form/textarea/xgsFormTextarea";
import Button, { ButtonThemes } from "../../ui-components/button/button";
import { EmployeeFeedbackModel, EmployeeFeedbackSchema } from "../../app/data/feedback/models";
import FeedbackState from "../../slices/feedback/FeedbackState";
import {
  feedbackSelector,
  resetFeedbackState,
  submitJiraFeedback
} from "../../slices/feedback/feedbackSlice";
import XGSPopup, { PopupTheme } from "../../ui-components/xgs-popup/xgsPopup";
import { cn } from "../../services/common/className";
import Rating from "../../ui-components/rating/rating";
import { WizardCheck } from "../../ui-components/xgs-wizard/wizardCheck";

import "./feedback.scss";
import "./../../ui-components/xgs-wizard/wizard.scss";

export interface FeedbackProps {  
  mix?: string;
}

let initialValues: EmployeeFeedbackModel = {
  rating: "0",
  positive: "",
  negative: "",
  missing: "",
};

const Feedback: React.FC<FeedbackProps> = (props) => {
  const feedbackState: FeedbackState = useSelector(feedbackSelector);
  const dispatch = useDispatch();

  const [isOpened, setIsOpened] = useState(false);

  const onSubmitFeedback = (data: EmployeeFeedbackModel) => {
    dispatch(submitJiraFeedback(
      {...data, page: window.location.href},
      () => { setTimeout(closeFeedback, 2000); },
      (error) => { toast.error(error) },
    ));
  };

  const closeFeedback = () => { 
    setIsOpened(false);
    dispatch(resetFeedbackState()); 
  };

  return (
    <div className={cn("feedback")(null, [props.mix])}>
      {!isOpened && <div className="feedback__button" onClick={() => setIsOpened(true)}>Feedback</div>}

      <XGSPopup
        opened={isOpened}
        onClose={closeFeedback}
        title=""
        className="xgs-modal"
        hideCloseButton={true}
        theme={PopupTheme.NEW}
      >
        {!(feedbackState.requestSucceed && feedbackState.requestCreator === "SUBMIT") && (
        <>
          <div className="feedback__title">Feedback</div>
          <div className="feedback__subtitle">Please, describe your experience with this interface and rate it.</div>
         
            <Formik
              onSubmit={onSubmitFeedback}
              initialValues={initialValues}
              validationSchema={EmployeeFeedbackSchema}
              enableReinitialize
            >
              {(props: FormikProps<EmployeeFeedbackModel>) => (
                <Form className="feedback__form">
                  <Rating
                    value={props.values.rating}
                    onChange={props.handleChange}
                  />

                  <XGSFormTextarea
                    name="positive"
                    label="What are we doing right?"
                    counter={500}
                    required
                  />

                  <XGSFormTextarea
                    name="negative"
                    label="What should we stop doing?"
                    counter={500}
                    required
                  />

                  <XGSFormTextarea
                    name="missing"
                    label="What is missing from this page?"
                    counter={500}
                    required
                  />

                  <div className="feedback__submit-buttons">
                    <Button
                      theme={ButtonThemes.gray}
                      type="button"
                      className=""
                      onClick={closeFeedback}
                    >
                      Cancel
                    </Button>

                    <Button
                      theme={ButtonThemes.blue}
                      type="submit"
                      className=""
                      spinner={feedbackState.requestStarted}
                      disabled={!props.isValid || !props.dirty}
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </>
      )}

      {feedbackState.requestSucceed && feedbackState.requestCreator === "SUBMIT" && (
          <div className="feedback__success">
            <WizardCheck/>

            <div className="feedback__title">Thanks for feedback!</div>

            <Button
              theme={ButtonThemes.gray}
              type="button"
              className=""
              onClick={closeFeedback}
            >
              Close
            </Button>
          </div>
      )}
      </XGSPopup>      
    </div>
  );
};

export default Feedback;
